import React, { useEffect, useState } from "react";
import { Col, Descriptions, Input, Menu, Row, Table, Tooltip } from "antd";
import HeadingBack from "../component/HeadingBack";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import SvgIcon from "../const/SvgIcon";
import { allapiAction } from "../../Redux/common/action";
import moment from "moment";
import ImageUploading from "react-images-uploading";

import { toast } from "react-toastify";
import { AsyncPaginate } from "react-select-async-paginate";
import { URL } from "../../Redux/common/url";
import { Button, Dropdown, Space, Modal } from "antd";
import axios from "axios";
import { ExportToExcel } from "../component/ExportToExcel";
import {
  DeleteOutlined,
  EditOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import ExportToExcelNew from "../component/ExportToExcelNew/ExportToExcelNew";
function User() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const pagev = searchParams.get("page");
  const searchData = searchParams.get("search");
  const statusData2 = searchParams.get("status");

  const page = pagev == null ? 1 : pagev;
  const search = searchData == null ? "" : searchData;
  const statusfinal = statusData2 == null ? "" : statusData2;

  const users_list = useSelector((state) =>
    state?.allapi?.users_list ? state?.allapi?.users_list : {}
  );
  const get_seller_profile = useSelector((state) =>
    state?.allapi?.get_seller_profile ? state?.allapi?.get_seller_profile : {}
  );

  const [userDetailsEdit, setUserDetailsEdit] = useState({});
  const [userKycEdit, setUserKycEdit] = useState({});
  const [images, setImages] = useState([]);
  const [aadharFront, setaadharFront] = useState([]);
  const [aadharBack, setaadharBack] = useState([]);
  const [bankpassbook, setbankpassbook] = useState([]);
  const maxNumber = 1;
  // console.log(userDetailsEdit);
  console.log(userKycEdit);

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    // console.log(imageList, addUpdateIndex)
    setImages(imageList);
  };
  const onChangeaadharFront = (imageList, addUpdateIndex) => {
    // data for submit
    // console.log(imageList, addUpdateIndex)
    setaadharFront(imageList);
  };
  const onChangeaadharBack = (imageList, addUpdateIndex) => {
    // data for submit
    // console.log(imageList, addUpdateIndex)
    setaadharBack(imageList);
  };
  const onChangebankpassbook = (imageList, addUpdateIndex) => {
    // data for submit
    // console.log(imageList, addUpdateIndex)
    setbankpassbook(imageList);
  };
  console.log(userDetailsEdit);
  const hendleToprofileEdit = (e) => {
    const { name, value } = e?.target;

    setUserDetailsEdit({ ...userDetailsEdit, [name]: value });
  };
  const hendleToprofileEditKyc = (e) => {
    const { name, value } = e?.target;

    setUserKycEdit({ ...userKycEdit, [name]: value });
  };
  useEffect(() => {
    setUserDetailsEdit({
      _id: get_seller_profile?._id,
      fullName: get_seller_profile?.fullName
        ? get_seller_profile?.fullName
        : "",
      guardian: get_seller_profile?.guardian
        ? get_seller_profile?.guardian
        : "",
      father_name: get_seller_profile?.father_name
        ? get_seller_profile?.father_name
        : "",
      email: get_seller_profile?.email ? get_seller_profile?.email : "",
      phone: get_seller_profile?.phone ? get_seller_profile?.phone : "",
      gender: get_seller_profile?.gender ? get_seller_profile?.gender : "",
      city: get_seller_profile?.city ? get_seller_profile?.city : "",
      state: get_seller_profile?.state ? get_seller_profile?.state : "",
      country: get_seller_profile?.country ? get_seller_profile?.country : "",
      zip: get_seller_profile?.zip ? get_seller_profile?.zip : "",
      marital: get_seller_profile?.marital ? get_seller_profile?.marital : "",
      professional: get_seller_profile?.professional
        ? get_seller_profile?.professional
        : "",
      address1: get_seller_profile?.address1
        ? get_seller_profile?.address1
        : "",
      address2: get_seller_profile?.address2
        ? get_seller_profile?.address2
        : "",
    });

    setUserKycEdit({
      pan_card: get_seller_profile?.kyc_id?.pan_card
        ? get_seller_profile?.kyc_id?.pan_card
        : "",
      bank_name: get_seller_profile?.kyc_id?.bank_name
        ? get_seller_profile?.kyc_id?.bank_name
        : "",
      branch_name: get_seller_profile?.kyc_id?.branch_name
        ? get_seller_profile?.kyc_id?.branch_name
        : "",
      aadhar_card: get_seller_profile?.kyc_id?.aadhar_card
        ? get_seller_profile?.kyc_id?.aadhar_card
        : "",
      ifsc: get_seller_profile?.kyc_id?.ifsc
        ? get_seller_profile?.kyc_id?.ifsc
        : "",
      acc_no: get_seller_profile?.kyc_id?.acc_no
        ? get_seller_profile?.kyc_id?.acc_no
        : "",
      // updatedAt: get_seller_profile?.kyc_id?.updatedAt
      //   ? get_seller_profile?.kyc_id?.updatedAt
      //   : "",
      _id: get_seller_profile?.kyc_id?._id,
    });

    if (get_seller_profile?.kyc_id?.pancardImage) {
      setImages([
        {
          data_url: URL.API_BASE_URL + get_seller_profile?.kyc_id?.pancardImage,
        },
      ]);
    } else {
      setImages([]);
    }
    if (get_seller_profile?.kyc_id?.aadharfrontImage) {
      setaadharFront([
        {
          data_url:
            URL.API_BASE_URL + get_seller_profile?.kyc_id?.aadharfrontImage,
        },
      ]);
    } else {
      setaadharFront([]);
    }
    if (get_seller_profile?.kyc_id?.aadharbacktImage) {
      setaadharBack([
        {
          data_url:
            URL.API_BASE_URL + get_seller_profile?.kyc_id?.aadharbacktImage,
        },
      ]);
    } else {
      setaadharBack([]);
    }
    if (get_seller_profile?.kyc_id?.bankpassbook) {
      setbankpassbook([
        {
          data_url: URL.API_BASE_URL + get_seller_profile?.kyc_id?.bankpassbook,
        },
      ]);
    } else {
      setbankpassbook([]);
    }
  }, [get_seller_profile]);

  const [pages, setPage] = useState(1);

  const listdata = users_list?.docs ? users_list?.docs : [];

  useEffect(() => {
    // searchUserListStatus
    // getSellerListStatus
    hendletogetall();

    return () => {};
  }, [location]);

  const hendletogetall = () => {
    if (search) {
      if (statusfinal) {
        dispatch(
          allapiAction.searchUserListStatus({
            page: page,
            key: search,
            status: statusfinal,
          })
        );
      } else {
        dispatch(allapiAction.getUserListseacrch({ page: page, key: search }));
      }
    } else {
      if (statusfinal) {
        dispatch(
          allapiAction.getSellerListStatus({ page: page, status: statusfinal })
        );
      } else {
        // dispatch(allapiAction.getUserListseacrch({ page: page, key: search }));
        dispatch(allapiAction.getuserList(page));
      }
    }
  };

  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userId, setuserId] = useState("");

  const [statusData, setstatusData] = useState({});
  const [userData, setuserData] = useState({});
  const [pointtype, setpointtype] = useState("");

  const [statusDataBox, setstatusDataBox] = useState(false);
  const [statusDataBoxupdateRank, setstatusDataBoxupdateRank] = useState(false);
  const [changePasswords, setchangePasswords] = useState(false);
  const [statusUser, setstatusUser] = useState(false);
  const [statusLicence, setstatusLicence] = useState(false);
  const [statusLicenceadd, setstatusLicenceadd] = useState(false);
  const [statusEdit, setstatusEdit] = useState(false);
  const [statusEditKyc, setstatusEditKyc] = useState(false);
  const [statusKycAp, setstatusEditAp] = useState(false);

  const [kycStatus, setuserkycStatus] = useState({});
  const [changePassworddata, setchangePassworddata] = useState("");
  console.log(userData);

  const hendleTochangekyc = (e) => {
    const { name, value } = e.target;

    setuserkycStatus({ ...kycStatus, [name]: value });
  };

  const hendleToSubmetchange = async () => {
    const response = await dispatch(
      allapiAction.passwordChangeAdmin({
        _id: userData?._id,
        newPassword: changePassworddata,

        pageNumber: page,
      })
    );

    setchangePasswords(false);
    setchangePassworddata("");

    if (response?.success) {
      hendletogetall();
    }
  };

  const hendleToHideKyc = () => {
    setstatusEditKyc(false);
  };
  const hendleToViewM = (e, type) => {
    setstatusDataBox(true);
    setuserData(e);

    setpointtype(type);
  };
  const hendlechangepassword = (e, type) => {
    setchangePasswords(true);
    setuserData(e);
  };
  const hendleToupdaterank = (e, type) => {
    setstatusDataBoxupdateRank(true);
    setuserData(e);
    setstatusData(e);
    setpointtype(type);
  };
  const hendleToViewUser = async (e) => {
    setstatusUser(true);
    setuserData(e);
    const response = await dispatch(
      allapiAction.getsellerprofile({ id: e?._id })
    );
  };
  const [managerKey, setManagerKey] = useState(0);
  const hendleToViewLicence = async (e) => {
    setstatusLicence(true);
    setuserData(e);
    setManagerKey((prevKey) => prevKey + 1);
    const response = await dispatch(
      allapiAction.getsellerprofile({ id: e?._id })
    );
  };
  const hendleToViewLicenceadd = async (e) => {
    setstatusLicenceadd(true);
    setuserData(e);
    setManagerKey((prevKey) => prevKey + 1);
  };
  const hendleToKycStatus = (e) => {
    setstatusEditAp(true);

    console.log(e);
    if (!e?.kycStatus) {
      e.kycStatus = "Unapproved";
    }
    setuserkycStatus(e);
    // dispatch(allapiAction.getsellerprofile({ id: e?._id }));
  };
  const hendleToViewEdit = async (e) => {
    setstatusEdit(true);
    setuserData(e);
    const response = await dispatch(
      allapiAction.getsellerprofile({ id: e?._id })
    );
  };
  const hendleToViewEditKyc = async (e) => {
    setstatusEditKyc(true);
    setuserData(e);
    const response = await dispatch(
      allapiAction.getsellerprofile({ id: e?._id })
    );
  };

  const hendleTochangedata = (e) => {
    const { name, value } = e.target;

    setstatusData({ ...statusData, [name]: value });
  };

  const edituserKycByadmin = () => {
    // edituserKycByadmin
    const data = new FormData();

    if (images[0]?.file) {
      // roledata?.role_name
      data.append("pancardImage", images[0]?.file);
    }
    if (aadharFront[0]?.file) {
      // roledata?.role_name
      data.append("aadharfrontImage", aadharFront[0]?.file);
    }
    if (aadharBack[0]?.file) {
      // roledata?.role_name
      data.append("aadharbacktImage", aadharBack[0]?.file);
    }
    if (bankpassbook[0]?.file) {
      // roledata?.role_name
      data.append("bankpassbook", bankpassbook[0]?.file);
    }
    data.append("_id", userKycEdit?._id);

    if (userKycEdit?.acc_no) {
      data.append("acc_no", userKycEdit?.acc_no);
    }
    if (userKycEdit?.ifsc) {
      data.append("ifsc", userKycEdit?.ifsc);
    }
    if (userKycEdit?.aadhar_card) {
      data.append("aadhar_card", userKycEdit?.aadhar_card);
    }
    if (userKycEdit?.pan_card) {
      data.append("pan_card", userKycEdit?.pan_card);
    }
    if (userKycEdit?.branch_name) {
      data.append("branch_name", userKycEdit?.branch_name);
    }
    if (userKycEdit?.bank_name) {
      data.append("bank_name", userKycEdit?.bank_name);
    }
    dispatch(allapiAction.edituserKycByadmin(data));

    setstatusEditKyc(false);
    setaadharBack([]);
    setaadharFront([]);
    setImages([]);
  };

  const edituserProfileByadmin = async () => {
    const response = await dispatch(
      allapiAction.edituserProfileByadmin({
        _id: userDetailsEdit?._id,
        fullName: userDetailsEdit?.fullName,
        guardian: userDetailsEdit?.guardian,
        father_name: userDetailsEdit?.father_name,
        email: userDetailsEdit?.email,
        phone: userDetailsEdit?.phone,
        gender: userDetailsEdit?.gender,
        city: userDetailsEdit?.city,
        state: userDetailsEdit?.state,
        country: userDetailsEdit?.country,
        zip: userDetailsEdit?.zip,
        marital: userDetailsEdit?.marital,
        professional: userDetailsEdit?.professional,
        address1: userDetailsEdit?.address1,
        address2: userDetailsEdit?.address2,
      })
    );
    if (response?.success) {
      hendletogetall();
      setstatusEdit(false);
    }
  };

  const hendleToKycSubmetUpdate = () => {
    dispatch(
      allapiAction.KycstatusUpdateByAdmin({
        _id: kycStatus?._id,
        kycStatus: kycStatus?.kycStatus,

        pageNumber: page,
      })
    );

    setstatusEditAp(false);
  };
  const hendleToSubmetUpdateRank = async () => {
    const response = await dispatch(
      allapiAction.updaterankbyadmin({
        userIdTo: userData?._id,
        rank: statusData?.status,

        pageNumber: page,
      })
    );
    if (response?.success) {
      hendletogetall();
      setstatusDataBoxupdateRank(false);
      setstatusData({ amount: "" });
    }
  };
  const hendleToSubmetUpdate = async () => {
    if (pointtype == "Add") {
      if (!statusData?.amount) {
        toast.error("please enter amount");
        return;
      }
      if (!statusData?.transationId) {
        toast.error("please enter transation id");
        return;
      }
      const response = await dispatch(
        allapiAction.addFoundByAdmin({
          user_id: userData?._id,
          amount: statusData?.amount,
          transationId: statusData?.transationId,

          pageNumber: page,
        })
      );

      if (response?.success) {
        hendletogetall();
        setstatusDataBox(false);
        setstatusData({ amount: "" });
      }
    } else {
      if (!statusData?.amount) {
        toast.error("please enter amount");
        return;
      }
      const response = await dispatch(
        allapiAction.reducePointFoundByAdmin({
          user_id: userData?._id,
          amount: statusData?.amount,

          pageNumber: page,
        })
      );

      if (response?.success) {
        hendletogetall();
        setstatusDataBox(false);
        setstatusData({ amount: "" });
      }
    }
  };

  const showModal = (e) => {
    console.log(e);
    setuserId(e);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    dispatch(
      allapiAction.deleteUserByadmin({
        _id: userId,
        status: userId,
        // active: 0,
        pageNumber: page,
      })
    );
    setuserId("");
    setIsModalOpen(false);
    return () => {};
  };

  const hendleToChangeStatus = (id, status) => {
    dispatch(
      allapiAction.user_inactive({
        _id: id,
        status: status,
        // active: 0,
        pageNumber: page,
      })
    );
    // setuserId("");
    // setIsModalOpen(false);
    return () => {};
  };
  const hendleToChangeStatusSuspend = (id, status) => {
    dispatch(
      allapiAction.getUserListActiveSuspend({
        _id: id,
        status: status,
        // active: 0,
        pageNumber: page,
      })
    );
    // setuserId("");
    // setIsModalOpen(false);
    return () => {};
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [lisshowcount, setlisscount] = useState("");
  const [lisshowcountadd, setlisscountadd] = useState("");

  const [userselete, setuserselete] = useState(null);

  const loadOptionsPalyer = async (search, loadedOptions, page) => {
    console.log(page);
    const response = await fetch(
      URL.API_BASE_URL +
        `/publicApi/getuserforlinsense?search=${search}&page=${page?.page}&user_id=${get_seller_profile?.referId}`
    );
    const responseJSON = await response.json();

    const options = responseJSON?.results?.map((data) => {
      data.value = data?.id;
      data.label = data?.referId + " - " + data?.fullName;

      return data;
    });

    return {
      options: options,
      hasMore: responseJSON.has_more,
      additional: {
        page: page + 1,
      },
    };
  };

  const hendletoAssLicenceadd = async () => {
    // addlicence

    if (!lisshowcountadd) {
      toast.error("please select license count");
    } else {
      const response = await dispatch(
        allapiAction.addlicensebyadminOldUser({
          // userIdFrom: get_seller_profile?._id,
          userId: userData?._id,

          licenseCount: lisshowcountadd,
        })
      );
      if (response?.success) {
        setstatusLicenceadd(false);
        hendletogetall();
      }
    }
  };
  const hendletoAssLicence = () => {
    // addlicence

    const userLicense =
      lisshowcount == 1
        ? get_seller_profile?.Userchaindata?.license1
        : lisshowcount == 2
        ? get_seller_profile?.Userchaindata?.license2
        : lisshowcount == 3
        ? get_seller_profile?.Userchaindata?.license3
        : get_seller_profile?.Userchaindata?.license4;

    if (!lisshowcount) {
      toast.error("please select license");
    } else if (!userLicense) {
      toast.error("already used");
    } else {
      dispatch(
        allapiAction.addlicence({
          userIdFrom: get_seller_profile?._id,
          userIdTo: userselete?._id,
          licence: userLicense,
          licenceno: lisshowcount,
        })
      );
      setstatusLicence(false);
    }
  };
  const itemsasdas = [
    {
      key: "1",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.antgroup.com"
        >
          1st menu item
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.aliyun.com"
        >
          2nd menu item
        </a>
      ),
    },
    {
      key: "3",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.luohanacademy.com"
        >
          3rd menu item
        </a>
      ),
    },
  ];

  const menu = (
    <Menu>
      <Menu.Item key="1">Option 1</Menu.Item>
      <Menu.Item key="2">Option 2</Menu.Item>
      <Menu.Item key="3">Option 3</Menu.Item>
    </Menu>
  );

  const columns = [
    {
      title: "S.No.",
      dataIndex: "sno",
      key: "sno",
      className: "table_first_col",
      render: (value, item, index) => (page - 1) * 10 + index + 1,
    },
    {
      title: "User Id",
      dataIndex: "referId",
      key: "referId",
      className: "table_action_col",
      render: (value, item, index) => {
        const relp = item?.hash_password.split("/").join("slesetest");

        // const urlDecod = relp.split("ltsWRd09eDk27C67ksrtl");
        const finalurl = item?.email + "ltsWRd09eDk27C67ksrtl" + relp;
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer coustomNevc"
              // onClick={() => navigate(`/user-details/${item?.referId}`)}
            >
              <a
                target={"_blank"}
                href={
                  "https://rilindia.biz/63bbf79290327404282cf563rd3/" + finalurl
                }
              >
                {item?.referId}
              </a>
            </span>
          </>
        );
      },
    },

    {
      title: "Name",
      dataIndex: "fullName",
      key: "fullName",
    },
    {
      title: "Upline Id",
      dataIndex: "referBy",
      key: "referBy",
    },
    {
      title: "Number",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Password",
      dataIndex: "passwordPlan",
      key: "passwordPlan",
    },
    {
      title: "Wallet",
      dataIndex: "balance",
      key: "balance",
    },
    // {
    //   title: "Wallet",
    //   dataIndex: "Wallet",
    //   key: "Wallet",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>

    //         <span
    //           className="cursor-pointer"
    //           // onClick={() => navigate(`/users/contact/${item?.id}`)}
    //         >
    //           {item?.walletId?.closeBal}
    //         </span>
    //       </>
    //     );
    //   },
    // },
    // {
    //   title: "Reffer Code",
    //   dataIndex: "referCode",
    //   key: "referCode",
    // },
    {
      title: "Join Date",
      dataIndex: "Join Date",
      key: "Join Date",
      // className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer"
              // onClick={() => navigate(`/users/contact/${item?.id}`)}
            >
              {moment(item?.createdAt).format(" DD/MM/YYYY hh:mm A")}
            </span>
          </>
        );
      },
    },
    // {
    //   title: "Last Update",
    //   dataIndex: "Join Date",
    //   key: "Join Date",
    //   // className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         {/* <Tooltip title="Contact"> */}
    //         <span
    //           className="cursor-pointer"
    //           // onClick={() => navigate(`/users/contact/${item?.id}`)}
    //         >
    //           {moment(item?.updatedAt).format(" DD/MM/YYYY hh:mm A")}
    //         </span>
    //       </>
    //     );
    //   },
    // },
    {
      title: "Rank",
      dataIndex: "status",
      key: "status",
      // className: "table_action_col",
      render: (value, item, index) => {
        const userRank =
          item?.status == "0"
            ? "free"
            : item?.status == "1"
            ? "FRANCHISE"
            : item?.status == "2"
            ? "BARON"
            : item?.status == "3"
            ? "COUNT"
            : item?.status == "4"
            ? "MARQUISE"
            : item?.status == "5"
            ? "DUKE"
            : "ARCH DUKE";
        return (
          <>
            <span
              className="cursor-pointer"
              // onClick={() => navigate(`/users/contact/${item?.id}`)}
            >
              {userRank}
            </span>
            {/* <Tooltip title={item?.status}>
              <button
                className={
                  item?.status == "Active"
                    ? "success-button ml-3"
                    : "delete-button ml-3"
                }
                // onClick={() =>
                //   hendleToChangeStatus(
                //     item?._id,
                //     item?.status == "Active" ? "Inactive" : "Active"
                //   )
                // }
              >
                {userRank}
              </button>
            </Tooltip> */}
          </>
        );
      },
    },
    {
      title: "Ekyc",
      dataIndex: "kycStatus",
      key: "kycStatus",
      // className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}

            <span
              className={
                item?.kycStatus == "Approved"
                  ? "cursor-pointer coustomtrue"
                  : "cursor-pointer coustomfalse"
              }
              onClick={() => hendleToKycStatus(item)}
              // onClick={() => navigate(`/user-details/${item?.referId}`)}
            >
              {item?.kycStatus ? item?.kycStatus : "Unapproved"}
            </span>
          </>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "user_status",
      key: "user_status",
      // className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            <Tooltip title={item?.user_status}>
              <button
                className={
                  item?.user_status == "Active"
                    ? "success-button ml-3"
                    : "delete-button2 ml-3"
                }
                onClick={() =>
                  hendleToChangeStatus(
                    item?._id,
                    item?.user_status == "Active" ? "Inactive" : "Active"
                  )
                }
              >
                {item?.user_status}
              </button>
            </Tooltip>
          </>
        );
      },
    },
    {
      title: "Suspend Status",
      dataIndex: "user_status",
      key: "user_status",
      // className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            <Tooltip
              title={
                item?.login_status == "0"
                  ? "Active"
                  : item?.login_status == "1"
                  ? "Inctive"
                  : "Suspend"
              }
            >
              <button
                className={
                  item?.login_status == "0"
                    ? "success-button ml-3"
                    : "delete-button2 ml-3"
                }
                onClick={() =>
                  hendleToChangeStatusSuspend(
                    item?._id,
                    item?.login_status == "0" ? "2" : "0"
                  )
                }
              >
                {item?.login_status == "0"
                  ? "Active"
                  : item?.login_status == "1"
                  ? "Inctive"
                  : "Suspend"}
              </button>
            </Tooltip>
          </>
        );
      },
    },
    // {
    //   title: "Student Mobile",
    //   dataIndex: "student_mobile",
    //   key: "student_mobile",
    // },
    // {
    //   title: "Parent Name",
    //   dataIndex: "parent_name",
    //   key: "parent_name",
    // },
    // {
    //   title: "Parent Mobile",
    //   dataIndex: "parent_mobile",
    //   key: "parent_mobile",
    // },
    // {
    //   title: "Address",
    //   dataIndex: "address",
    //   key: "address",
    // },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item key="1">
                    {" "}
                    <Tooltip title="View">
                      <span
                        className="cursor-pointer admin-button2"
                        onClick={() => hendleToViewUser(item)}
                      >
                        <SvgIcon.Visibility2 className="scale-icon1" />
                        {/* View */}
                      </span>
                    </Tooltip>
                    <Tooltip title="delete">
                      <span
                        className="cursor-pointer admin-button2 ml-3"
                        onClick={() => showModal(item?._id)}
                      >
                        <SvgIcon.deletebtn2 className="scale-icon edit-icon" />
                        {/* Delete */}
                      </span>
                    </Tooltip>
                    <Tooltip title="Edit">
                      <span
                        className="cursor-pointer admin-button2  ml-3"
                        onClick={() => hendleToViewEdit(item)}
                      >
                        <SvgIcon.Pencil2 className="scale-icon edit-icon" />
                        {/* Edit */}
                      </span>
                    </Tooltip>
                  </Menu.Item>
                  {/* <Menu.Item key="2">
                    {" "}
                    <Tooltip title="delete">
                      <span
                        className="cursor-pointer ml-3"
                        onClick={() => showModal(item?._id)}
                      >
                        <SvgIcon.deletebtn className="scale-icon edit-icon" />
                        Delete
                    
                      </span>
                    </Tooltip>
                  </Menu.Item> */}
                  {/* <Menu.Item key="3">
                    {" "}
                    <Tooltip title="Edit">
                      <span
                        className="cursor-pointer ml-3"
                        onClick={() => hendleToViewEdit(item)}
                      >
                        <SvgIcon.Pencil className="scale-icon edit-icon" />
                        Edit
                      </span>
                    </Tooltip>
                  </Menu.Item> */}
                  <Menu.Item key="4">
                    <Tooltip title="Edit Kyc" placement="leftTop">
                      <button
                        className="admin-button ml-3 mt-3"
                        onClick={() => hendleToViewEditKyc(item)}
                      >
                        Edit Kyc
                      </button>
                    </Tooltip>
                  </Menu.Item>
                  <Menu.Item key="5">
                    {" "}
                    <Tooltip title="Add Point" placement="leftTop">
                      <button
                        className="admin-button ml-3  mt-3"
                        onClick={() => hendleToViewM(item, "Add")}
                      >
                        Add Point
                      </button>
                    </Tooltip>
                  </Menu.Item>
                  <Menu.Item key="6">
                    <Tooltip title="Reduce Point" placement="leftTop">
                      <button
                        className="admin-button ml-3  mt-3"
                        onClick={() => hendleToViewM(item, "Reduce")}
                      >
                        Reduce Point
                      </button>
                    </Tooltip>
                  </Menu.Item>
                  <Menu.Item key="7">
                    <Tooltip title="Update Rank" placement="leftTop">
                      <button
                        className="admin-button ml-3  mt-3"
                        onClick={() => hendleToupdaterank(item, "Reduce")}
                      >
                        Update Rank
                      </button>
                    </Tooltip>
                  </Menu.Item>
                  <Menu.Item key="8">
                    {" "}
                    <Tooltip title="Change Password" placement="leftTop">
                      <button
                        className="admin-button ml-3  mt-3"
                        onClick={() => hendlechangepassword(item, "Reduce")}
                      >
                        Change Password
                      </button>
                    </Tooltip>
                  </Menu.Item>
                  {item?.status > 1 && (
                    <Menu.Item key="9">
                      {" "}
                      <Tooltip title="Add license" placement="leftTop">
                        <button
                          className="admin-button ml-3  mt-3"
                          onClick={() => hendleToViewLicenceadd(item)}
                        >
                          Add license
                        </button>
                      </Tooltip>
                    </Menu.Item>
                  )}
                  {item?.userlicence == "1" ? (
                    <Menu.Item key="10">
                      <Tooltip title="license assign" placement="leftTop">
                        <button
                          className="admin-button ml-3 mt-3"
                          onClick={() => hendleToViewLicence(item)}
                        >
                          License Assign
                        </button>
                      </Tooltip>
                    </Menu.Item>
                  ) : item?.addlicenseAdmin == "1" ? (
                    <Menu.Item key="10">
                      <Tooltip title="license assign" placement="leftTop">
                        <button
                          className="admin-button ml-3 mt-3"
                          onClick={() => hendleToViewLicence(item)}
                        >
                          License Assign
                        </button>
                      </Tooltip>
                    </Menu.Item>
                  ) : (
                    ""
                  )}
                </Menu>
              }
              placement="bottomRight"
            >
              {/* <Button >
                Actions
                
              </Button> */}
              <Tooltip title="Actions">
                <button
                  className="admin-button1 ml-3 mt-3"
                  // onClick={() => hendleToViewLicence(item)}
                >
                  Actions
                </button>
              </Tooltip>
            </Dropdown>
            {/* <Dropdown
              menu={{
                itemsasdas,
              }}
              placement="bottomRight"
            >
              <Button>bottomRight</Button>
            </Dropdown> */}
            {/* <Tooltip title="View">
              <span
                className="cursor-pointer"
                onClick={() => hendleToViewUser(item)}
              >
                <SvgIcon.Visibility className="scale-icon edit-icon" />
              </span>
            </Tooltip>
            <Tooltip title="delete">
              <span
                className="cursor-pointer ml-3"
                onClick={() => showModal(item?._id)}
              >
                <SvgIcon.deletebtn className="scale-icon edit-icon" />

                Delete
              </span>
            </Tooltip>
            <Tooltip title="Edit">
              <span
                className="cursor-pointer ml-3"
                onClick={() => hendleToViewEdit(item)}
              >
                <SvgIcon.Pencil className="scale-icon edit-icon" />
                Edit
              </span>
            </Tooltip> */}
            {/* {item?.userlicence == "1" && (
              <Tooltip title="license assign">
                <button
                  className="admin-button ml-3 mt-3"
                  onClick={() => hendleToViewLicence(item)}
                >
                  License Assign
                </button>
              </Tooltip>
            )} */}

            {/* <Tooltip title="Edit Kyc">
              <button
                className="admin-button ml-3 mt-3"
                onClick={() => hendleToViewEditKyc(item)}
              >
                Edit Kyc
              </button>
            </Tooltip>

            <Tooltip title="Add Point">
              <button
                className="admin-button ml-3  mt-3"
                onClick={() => hendleToViewM(item, "Add")}
              >
                Add Point
              </button>
            </Tooltip> */}
            {/* <Tooltip title="Reduce Point">
              <button
                className="admin-button ml-3  mt-3"
                onClick={() => hendleToViewM(item, "Reduce")}
              >
                Reduce Point
              </button>
            </Tooltip>
            <Tooltip title="Update Rank">
              <button
                className="admin-button ml-3  mt-3"
                onClick={() => hendleToupdaterank(item, "Reduce")}
              >
                Update Rank
              </button>
            </Tooltip>
            <Tooltip title="Change Password">
              <button
                className="admin-button ml-3  mt-3"
                onClick={() => hendlechangepassword(item, "Reduce")}
              >
                Change Password
              </button>
            </Tooltip> */}
          </>
        );
      },
    },
  ];

  const [searchs, setsearch] = useState("");
  const handleChange = (e) => {
    setsearch(e);
    setPage(1);

    if (statusfinal) {
      navigate("/users?page=" + 1 + "&search=" + e + "&status=" + statusfinal);
    } else {
      navigate("/users?page=" + 1 + "&search=" + e);
    }
    // if (e) {
    //   dispatch(allapiAction.getuserListSearch({ page: 1, search: e }));
    //   return () => {};
    // } else {
    //   dispatch(allapiAction.getuserList(1));

    //   return () => {};
    // }
    // getuserListSearch
    // &user_id=${data?.user_id}&search=${data?.user_id}
  };

  const handleChangeStatus = (e) => {
    // setsearch(e);
    setPage(1);
    navigate("/users?page=" + 1 + "&search=" + search + "&status=" + e);
    // if (e) {
    //   dispatch(allapiAction.getuserListSearch({ page: 1, search: e }));
    //   return () => {};
    // } else {
    //   dispatch(allapiAction.getuserList(1));

    //   return () => {};
    // }
    // getuserListSearch
    // &user_id=${data?.user_id}&search=${data?.user_id}
  };

  const hendlePahegi = (e) => {
    setPage(e);

    console.log(location);

    if (statusfinal) {
      navigate(
        "/users?page=" + e + "&search=" + search + "&status=" + statusfinal
      );
    } else {
      navigate("/users?page=" + e + "&search=" + search);
    }

    // if (search) {
    //   dispatch(allapiAction.getuserListSearch({ page: e, search: search }));
    //   return () => {};
    // } else {
    //   dispatch(allapiAction.getuserList(e));

    //   return () => {};
    // }
  };

  const hendleeeeeuserincomeList = async () => {
    let data = JSON.stringify({
      month: 8,
      year: 2024,
      id: "RIL594113",
      status: "4",
    });

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "http://localhost:5200/userincome?date=08-2024&fcid=RIL677023",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYzOTZlNmVjYzA4MGY3ODIzNTRjMzRlNyIsInJvbGVfbmFtZSI6IkFkbWluIiwiZW1haWwiOiJhZG1pbkBnbWFpbC5jb20iLCJmdWxsTmFtZSI6ImFkbWluIiwiaWF0IjoxNzI2NDg1MzA5LCJleHAiOjE3MjY3NDQ1MDl9.wi7NV64Kaz7Z1uhyxlfiZVt9HkOPOrVDxB4J-gUHcPw",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response.data?.data);
        console.log(response.data?.data?.data);
        ExportToExcel(response.data?.data, "RIL677023-duke");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const hendleeeeeinactiveuser = async () => {
    let data = JSON.stringify({
      month: 3,
      year: 2024,
      id: "RIL594113",
      status: "4",
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "http://localhost:5200/publicApi/unapprvedUserList",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYzOTZlNmVjYzA4MGY3ODIzNTRjMzRlNyIsInJvbGVfbmFtZSI6IkFkbWluIiwiZW1haWwiOiJhZG1pbkBnbWFpbC5jb20iLCJmdWxsTmFtZSI6ImFkbWluIiwiaWF0IjoxNzIzMjkyODAyLCJleHAiOjE3MjM1NTIwMDJ9.kpk_thQngpUcDgyIZq0xcdzcJTmrl9KLZusGEpbltX0",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response.data?.data);
        console.log(response.data?.data?.data);
        ExportToExcel(response.data?.data, "Suspend");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const useridbybussnissgpg = async () => {
    let data = JSON.stringify({
      month: 5,
      year: 2024,
      id: "RIL6062041",
      status: "5",
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "http://localhost:5200/adminApi/useridbybussnissgpg",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYzOTZlNmVjYzA4MGY3ODIzNTRjMzRlNyIsInJvbGVfbmFtZSI6IkFkbWluIiwiZW1haWwiOiJhZG1pbkBnbWFpbC5jb20iLCJmdWxsTmFtZSI6ImFkbWluIiwiaWF0IjoxNzE4NjQwMzM0LCJleHAiOjE3MTg4OTk1MzR9.phDO-N-mhHN6yY_2fCfKwUSZEAQLjYtTRmXl2NmJNKU",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response.data?.data);
        console.log(response.data?.data?.data);
        ExportToExcel(response.data?.data, "RIL6062041-down-gpg");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const mayMonthregisterthismmm = async () => {
    let data = JSON.stringify({
      month: 5,
      year: 2024,
      id: "RIL64326165",
      status: "5",
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "http://localhost:5200/adminApi/mayMonthregisterthismmm",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYzOTZlNmVjYzA4MGY3ODIzNTRjMzRlNyIsInJvbGVfbmFtZSI6IkFkbWluIiwiZW1haWwiOiJhZG1pbkBnbWFpbC5jb20iLCJmdWxsTmFtZSI6ImFkbWluIiwiaWF0IjoxNzE4OTg2NTE0LCJleHAiOjE3MTkyNDU3MTR9.LRMy1UPp3rlzE4R2YUS5GNP0EixH1QISzW7S3ddCBTo",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response.data?.data);
        console.log(response.data?.data?.data);
        ExportToExcel(response.data?.data, "RIL64326165-sale");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const useridbybussniss = async () => {
    let data = JSON.stringify({
      month: 5,
      year: 2024,
      id: "RIL54883751",
      status: "5",
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "http://localhost:5200/adminApi/useridbybussniss",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYzOTZlNmVjYzA4MGY3ODIzNTRjMzRlNyIsInJvbGVfbmFtZSI6IkFkbWluIiwiZW1haWwiOiJhZG1pbkBnbWFpbC5jb20iLCJmdWxsTmFtZSI6ImFkbWluIiwiaWF0IjoxNzE4MjkyMTQ2LCJleHAiOjE3MTg1NTEzNDZ9.Vtnz1NttzETaHRUFoFEcClJX7eyYrI4P3P-upnFP-X4",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response.data?.data);
        console.log(response.data?.data?.data);
        ExportToExcel(response.data?.data, "RIL54883751-buss");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const hendleeeee = async () => {
    let data = JSON.stringify({
      month: 8,
      year: 2024,
      id: "RIL594113",
      status: "5",
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "http://localhost:5200/adminApi/usermonthreport",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYzOTZlNmVjYzA4MGY3ODIzNTRjMzRlNyIsInJvbGVfbmFtZSI6IkFkbWluIiwiZW1haWwiOiJhZG1pbkBnbWFpbC5jb20iLCJmdWxsTmFtZSI6ImFkbWluIiwiaWF0IjoxNzI2NDg1MzA5LCJleHAiOjE3MjY3NDQ1MDl9.wi7NV64Kaz7Z1uhyxlfiZVt9HkOPOrVDxB4J-gUHcPw",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response.data?.data);
        console.log(response.data?.data?.data);
        ExportToExcel(response.data?.data, "duke");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const hendleeeeecoustomId = async () => {
    let data = JSON.stringify({
      month: 8,
      year: 2024,
      id: "RIL594113",
      status: "5",
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "http://localhost:5200/adminApi/usermonthreportbyCostomId",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYzOTZlNmVjYzA4MGY3ODIzNTRjMzRlNyIsInJvbGVfbmFtZSI6IkFkbWluIiwiZW1haWwiOiJhZG1pbkBnbWFpbC5jb20iLCJmdWxsTmFtZSI6ImFkbWluIiwiaWF0IjoxNzI2NDg1MzA5LCJleHAiOjE3MjY3NDQ1MDl9.wi7NV64Kaz7Z1uhyxlfiZVt9HkOPOrVDxB4J-gUHcPw",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response.data?.data);
        console.log(response.data?.data?.data);
        ExportToExcel(response.data?.data, "someusers-8");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const hendleeeeeallincome = async () => {
    let data = JSON.stringify({
      month: 5,
      year: 2024,
      id: "RIL594113",
      status: "4",
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "http://localhost:5200/adminApi/usermonthsellincome",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYzOTZlNmVjYzA4MGY3ODIzNTRjMzRlNyIsInJvbGVfbmFtZSI6IkFkbWluIiwiZW1haWwiOiJhZG1pbkBnbWFpbC5jb20iLCJmdWxsTmFtZSI6ImFkbWluIiwiaWF0IjoxNzIzMjkyODAyLCJleHAiOjE3MjM1NTIwMDJ9.kpk_thQngpUcDgyIZq0xcdzcJTmrl9KLZusGEpbltX0",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response.data?.data);
        console.log(response.data?.data?.data);
        ExportToExcel(response.data?.data, "May_All_income_list_with_userid");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const hendleeeeeallincomewithac = async () => {
    let data = JSON.stringify({
      month: 5,
      year: 2024,
      id: "RIL594113",
      status: "4",
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "http://localhost:5200/adminApi/usermonthsellincomeWithAc",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYzOTZlNmVjYzA4MGY3ODIzNTRjMzRlNyIsInJvbGVfbmFtZSI6IkFkbWluIiwiZW1haWwiOiJhZG1pbkBnbWFpbC5jb20iLCJmdWxsTmFtZSI6ImFkbWluIiwiaWF0IjoxNzIzMjkyODAyLCJleHAiOjE3MjM1NTIwMDJ9.kpk_thQngpUcDgyIZq0xcdzcJTmrl9KLZusGEpbltX0",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response.data?.data);
        console.log(response.data?.data?.data);
        ExportToExcel(response.data?.data, "May Final income list ");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const hendleeeeeallincomewithOutac = async () => {
    let data = JSON.stringify({
      month: 5,
      year: 2024,
      id: "RIL594113",
      status: "4",
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "http://localhost:5200/adminApi/usermonthsellincomeWithOutAc",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYzOTZlNmVjYzA4MGY3ODIzNTRjMzRlNyIsInJvbGVfbmFtZSI6IkFkbWluIiwiZW1haWwiOiJhZG1pbkBnbWFpbC5jb20iLCJmdWxsTmFtZSI6ImFkbWluIiwiaWF0IjoxNzIzMjkyODAyLCJleHAiOjE3MjM1NTIwMDJ9.kpk_thQngpUcDgyIZq0xcdzcJTmrl9KLZusGEpbltX0",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response.data?.data);
        console.log(response.data?.data?.data);
        ExportToExcel(response.data?.data, "Amount_but_not_acc_no_nor_ifsc");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      {/* <button
        onClick={() => {
          hendleeeeeinactiveuser();
        }}
      >
        inactiveUserList
      </button> */}
      {/* <button
        onClick={() => {
          useridbybussnissgpg();
        }}
      >
        useridbybussnissgpg
      </button> */}
      {/* <button
        onClick={() => {
          mayMonthregisterthismmm();
        }}
      >
        mayMonthregisterthismmm
      </button> */}
      {/* <button
        onClick={() => {
          useridbybussniss();
        }}
      >
        useridbybussniss
      </button> */}
      {/*  
      
      <button
        onClick={() => {
          hendleeeeeallincome();
        }}
      >
        Allincome
      </button>
      <button
        onClick={() => {
          hendleeeeeallincomewithac();
        }}
      >
        with acc
      </button>
      <button
        onClick={() => {
          hendleeeeeallincomewithOutac();
        }}
      >
        without acc
      </button> */}

      {/* <button
        onClick={() => {
          hendleeeee();
        }}
      >
        duke
      </button> */}
      {/* <button
        onClick={() => {
          hendleeeeecoustomId();
        }}
      >
        coustomId
      </button> */}
      
     {/* duke income list  */}
      {/* <button
        onClick={() => {
          hendleeeeeuserincomeList();
        }}
      >
        user buss and income
      </button> */}
      <Modal
        title="delete User"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Are you sure delete</p>
      </Modal>

      {/* change password */}
      <Modal
        title={"Change Password"}
        centered
        open={changePasswords}
        onOk={() => hendleToSubmetchange(false)}
        onCancel={() => setchangePasswords(false)}
        width={500}
      >
        <div>
          <Row>
            <Col span={24}>New password</Col>
            <Col span={24}>
              <Input
                name="password"
                placeholder="enter password"
                value={changePassworddata}
                onChange={(e) => {
                  setchangePassworddata(e?.target?.value);
                }}
              />
              {/* <select
                className="form-control"
                name="status"
                onChange={(e) => hendleTochangedata(e)}
                value={statusData?.status}
              >
                <option value={"0"}>Fc</option>
                <option value={"1"}>Fr</option>
                <option value={"2"}>Br</option>
                <option value={"3"}>Ct</option>
                <option value={"4"}>Mq</option>
                <option value={"5"}>Duqe</option>
              </select> */}
            </Col>
          </Row>
        </div>
      </Modal>

      {/* rank update */}
      <Modal
        title={"Rank update"}
        centered
        open={statusDataBoxupdateRank}
        onOk={() => hendleToSubmetUpdateRank(false)}
        onCancel={() => setstatusDataBoxupdateRank(false)}
        width={500}
      >
        <div>
          <Row>
            <Col span={24}>Rank</Col>
            <Col span={24}>
              {/* <Input
                name="amount"
                placeholder="enter amount"
                value={statusData?.amount}
                onChange={(e) => {
                  hendleTochangedata(e);
                }}
              /> */}
              <select
                className="form-control"
                name="status"
                onChange={(e) => hendleTochangedata(e)}
                value={statusData?.status}
              >
                <option value={"0"}>Fc</option>
                <option value={"1"}>Fr</option>
                <option value={"2"}>Br</option>
                <option value={"3"}>Ct</option>
                <option value={"4"}>Mq</option>
                <option value={"5"}>Duqe</option>
              </select>
            </Col>
          </Row>
        </div>
      </Modal>

      {/* add fund */}
      <Modal
        title={pointtype + " Amount"}
        centered
        open={statusDataBox}
        onOk={() => hendleToSubmetUpdate(false)}
        onCancel={() => setstatusDataBox(false)}
        width={500}
      >
        <div>
          <Row>
            <Col span={24}>Amount</Col>
            <Col span={24}>
              <Input
                name="amount"
                placeholder="enter amount"
                value={statusData?.amount}
                onChange={(e) => {
                  hendleTochangedata(e);
                }}
              />
            </Col>
            {pointtype == "Add" && <Col span={24}>Transation Id</Col>}
            {pointtype == "Add" && (
              <Col span={24}>
                <Input
                  name="transationId"
                  placeholder="enter amount"
                  value={statusData?.transationId}
                  onChange={(e) => {
                    hendleTochangedata(e);
                  }}
                />
              </Col>
            )}
          </Row>
        </div>
      </Modal>

      {/* kyc status */}
      <Modal
        title="Kyc"
        centered
        open={statusKycAp}
        onOk={() => hendleToKycSubmetUpdate(false)}
        onCancel={() => setstatusEditAp(false)}
        width={500}
      >
        <div>
          <Row>
            <Col span={24}>Kyc</Col>
            <Col span={24}>
              <select
                name="kycStatus"
                className="form-control"
                onChange={(e) => {
                  hendleTochangekyc(e);
                }}
                value={kycStatus?.kycStatus}
              >
                {/* <option>Select </option> */}
                <option value={"Approved"}>Approved</option>
                <option value={"Unapproved"}>Unapproved</option>
                {/* <option value={"W/O"}>W/O</option> */}
              </select>
              {/* <Input
                name="amount"
                placeholder="enter amount"
                value={statusData?.amount}
                onChange={(e) => {
                  hendleTochangedata(e);
                }}
              /> */}
            </Col>
          </Row>
        </div>
      </Modal>

      {/* details  */}
      <Modal
        title="User Details"
        centered
        open={statusUser}
        onOk={() => setstatusUser(false)}
        onCancel={() => setstatusUser(false)}
        width={800}
      >
        <div>
          <div className="mb-10 d-flex">
            <div className="col-12">
              {/* get_seller_profile */}
              <h3>Details </h3>
              <div className="row">
                <div className="col-md-6">
                  <Descriptions bordered column={1}>
                    <Descriptions.Item label="Full Name">
                      {get_seller_profile.fullName}
                    </Descriptions.Item>
                    <Descriptions.Item label="Email">
                      {get_seller_profile.email}
                    </Descriptions.Item>
                    <Descriptions.Item label="Phone">
                      {get_seller_profile.phone}
                    </Descriptions.Item>
                    <Descriptions.Item label="Role">
                      {get_seller_profile.role_name}
                    </Descriptions.Item>
                    <Descriptions.Item label="Status">
                      {get_seller_profile.user_status}
                    </Descriptions.Item>
                    <Descriptions.Item label="KYC Status">
                      {get_seller_profile.kycStatus}
                    </Descriptions.Item>
                    <Descriptions.Item label="Joined At">
                      {get_seller_profile.createdAt &&
                        moment(get_seller_profile.createdAt).format(
                          "MMM DD, yyyy hh:mm:ss A"
                        )}
                    </Descriptions.Item>
                  </Descriptions>
                </div>
                <div className="col-md-6">
                  <Descriptions bordered column={1}>
                    <Descriptions.Item label="User ID">
                      {get_seller_profile?.referId}
                    </Descriptions.Item>
                    <Descriptions.Item label="Sponser Id">
                      {get_seller_profile?.referBy}
                    </Descriptions.Item>
                    <Descriptions.Item label="Gender">
                      {get_seller_profile?.gender
                        ? get_seller_profile?.gender
                        : "--"}
                    </Descriptions.Item>
                    <Descriptions.Item label="Guardian">
                      {get_seller_profile?.guardian
                        ? get_seller_profile?.guardian
                        : "--"}
                    </Descriptions.Item>
                    <Descriptions.Item label="Marital">
                      {get_seller_profile?.marital
                        ? get_seller_profile?.marital
                        : "--"}
                    </Descriptions.Item>
                    <Descriptions.Item label="Father name">
                      {get_seller_profile?.father_name
                        ? get_seller_profile?.father_name
                        : "--"}
                    </Descriptions.Item>
                    <Descriptions.Item label="Professional">
                      {get_seller_profile?.professional
                        ? get_seller_profile?.professional
                        : "--"}
                    </Descriptions.Item>
                  </Descriptions>
                </div>
              </div>
              {/* <div className="row">
                <div className="col-2">
                  <h6>Name</h6>
                </div>
                <div className="col-4">
                  {" "}
                  <p>{get_seller_profile?.fullName}</p>
                </div>
                <div className="col-2">
                  <h6>Email</h6>
                </div>
                <div className="col-4">
                  {" "}
                  <p>{get_seller_profile?.email}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-2">
                  <h6>Phone</h6>
                </div>
                <div className="col-4">
                  {" "}
                  <p>{get_seller_profile?.phone}</p>
                </div>
                <div className="col-2">
                  <h6>User ID</h6>
                </div>
                <div className="col-4">
                  {" "}
                  <p>
                    {get_seller_profile?.referId
                      ? get_seller_profile?.referId
                      : "--"}
                  </p>
                </div>
                <div className="col-2">
                  <h6>Sponser Id</h6>
                </div>
                <div className="col-4">
                  {" "}
                  <p>
                    {get_seller_profile?.referBy
                      ? get_seller_profile?.referBy
                      : "--"}
                  </p>
                </div>
                <div className="col-2">
                  <h6>Gender</h6>
                </div>
                <div className="col-4">
                  {" "}
                  <p>
                    {get_seller_profile?.gender
                      ? get_seller_profile?.gender
                      : "--"}
                  </p>
                </div>
                <div className="col-2">
                  <h6>Guardian</h6>
                </div>
                <div className="col-4">
                  {" "}
                  <p>
                    {get_seller_profile?.guardian
                      ? get_seller_profile?.guardian
                      : "--"}
                  </p>
                </div>
                <div className="col-2">
                  <h6>Father name</h6>
                </div>
                <div className="col-4">
                  {" "}
                  <p>
                    {get_seller_profile?.father_name
                      ? get_seller_profile?.father_name
                      : "--"}
                  </p>
                </div>
                <div className="col-2">
                  <h6>Marital</h6>
                </div>
                <div className="col-4">
                  {" "}
                  <p>
                    {get_seller_profile?.marital
                      ? get_seller_profile?.marital
                      : "--"}
                  </p>
                </div>
                <div className="col-2">
                  <h6>KYC Status</h6>
                </div>
                <div className="col-4">
                  {" "}
                  <p>
                    {get_seller_profile?.kycStatus
                      ? get_seller_profile?.kycStatus
                      : "--"}
                  </p>
                </div>
                <div className="col-2">
                  <h6>Professional</h6>
                </div>
                <div className="col-4">
                  {" "}
                  <p>
                    {get_seller_profile?.professional
                      ? get_seller_profile?.professional
                      : "--"}
                  </p>
                </div>
              </div> */}
              <h3>Address </h3>
              <Descriptions bordered column={1}>
                <Descriptions.Item label="City">
                  {get_seller_profile?.city ? get_seller_profile?.city : "--"}
                </Descriptions.Item>
                <Descriptions.Item label="State">
                  {get_seller_profile?.state ? get_seller_profile?.state : "--"}
                </Descriptions.Item>
                <Descriptions.Item label="Address">
                  {get_seller_profile?.address1
                    ? get_seller_profile?.address1
                    : "--"}
                </Descriptions.Item>
              </Descriptions>
              {/* <div className="row">
                <div className="col-2">
                  <h6>City</h6>
                </div>
                <div className="col-4">
                  {" "}
                  <p>
                    {get_seller_profile?.city ? get_seller_profile?.city : "--"}
                  </p>
                </div>
                <div className="col-2">
                  <h6>State</h6>
                </div>
                <div className="col-4">
                  {" "}
                  <p>
                    {get_seller_profile?.state
                      ? get_seller_profile?.state
                      : "--"}
                  </p>
                </div>
                <div className="col-2">
                  <h6>Address</h6>
                </div>
                <div className="col-4">
                  {" "}
                  <p>
                    {get_seller_profile?.address1
                      ? get_seller_profile?.address1
                      : "--"}
                  </p>
                </div>
              </div> */}
              {/* <div className="row">
                <div className="col-2">
                  <h6>Balance</h6>
                </div>
                <div className="col-4">
                  {" "}
                  <p>{get_seller_profile?.balance}</p>
                </div>
              </div> */}

              {/* <div className="row">
                <div className="col-3">
                  <h6>User Id</h6>
                </div>
                <div className="col-9">
                  {" "}
                  <p>{get_seller_profile?.referId}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-3">
                  <h6>Upline Id</h6>
                </div>
                <div className="col-9">
                  {" "}
                  <p>{get_seller_profile?.referBy}</p>
                </div>
              </div> */}

              {/* <h6>Email</h6>
                      <p>{get_seller_profile?.email}</p>
                      <h6>Role</h6>
                      <p>{get_seller_profile?.role_name}</p> */}
              <h3>Rank </h3>
              <Descriptions bordered column={1}>
                <Descriptions.Item label="Fr date">
                  {get_seller_profile?.Userchaindata?.fr_date
                    ? moment(get_seller_profile?.Userchaindata?.fr_date).format(
                        "MMM DD, yyyy hh:mm:ss A"
                      )
                    : "--"}
                </Descriptions.Item>
                <Descriptions.Item label="Br date">
                  {get_seller_profile?.Userchaindata?.br_date
                    ? moment(get_seller_profile?.Userchaindata?.br_date).format(
                        "MMM DD, yyyy hh:mm:ss A"
                      )
                    : "--"}
                </Descriptions.Item>
                <Descriptions.Item label="Ct date">
                  {get_seller_profile?.Userchaindata?.ct_date
                    ? moment(get_seller_profile?.Userchaindata?.ct_date).format(
                        "MMM DD, yyyy hh:mm:ss A"
                      )
                    : "--"}
                </Descriptions.Item>
                <Descriptions.Item label="Mq date">
                  {get_seller_profile?.Userchaindata?.mq_date
                    ? moment(get_seller_profile?.Userchaindata?.mq_date).format(
                        "MMM DD, yyyy hh:mm:ss A"
                      )
                    : "--"}
                </Descriptions.Item>
                <Descriptions.Item label="Duke date">
                  {get_seller_profile?.Userchaindata?.duke_date
                    ? moment(
                        get_seller_profile?.Userchaindata?.duke_date
                      ).format("MMM DD, yyyy hh:mm:ss A")
                    : "--"}
                </Descriptions.Item>
              </Descriptions>
              <div className="row mb-5">
                {/* <div className='col-4'>
                        {' '}
                        <img
                          src={URL.API_BASE_URL + get_seller_profile?.buseniss_id?.logo}
                          style={{maxHeight: '200px', width: 'auto'}}
                          alt=''
                        />
                        
                      </div> */}
                {/* <div className="col-8">
                  <div className="row">
                    <div className="col-3">
                      <h6>fr date</h6>
                    </div>
                    <div className="col-9">
                      {" "}
                      <p>
                        {" "}
                        {get_seller_profile?.Userchaindata?.fr_date
                          ? moment(
                              get_seller_profile?.Userchaindata?.fr_date
                            ).format("MMM DD, yyyy hh:mm:ss A")
                          : "--"}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-3">
                      <h6>br date</h6>
                    </div>
                    <div className="col-9">
                      {" "}
                      <p>
                        {get_seller_profile?.Userchaindata?.br_date
                          ? moment(
                              get_seller_profile?.Userchaindata?.br_date
                            ).format("MMM DD, yyyy hh:mm:ss A")
                          : "--"}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-3">
                      <h6>ct date</h6>
                    </div>
                    <div className="col-9">
                      {" "}
                      <p>
                        {get_seller_profile?.Userchaindata?.ct_date
                          ? moment(
                              get_seller_profile?.Userchaindata?.ct_date
                            ).format("MMM DD, yyyy hh:mm:ss A")
                          : "--"}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-3">
                      <h6>mq date</h6>
                    </div>
                    <div className="col-9">
                      {" "}
                      <p>
                        {get_seller_profile?.Userchaindata?.mq_date
                          ? moment(
                              get_seller_profile?.Userchaindata?.mq_date
                            ).format("MMM DD, yyyy hh:mm:ss A")
                          : "--"}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-3">
                      <h6>duke date</h6>
                    </div>
                    <div className="col-9">
                      {" "}
                      <p>
                        {get_seller_profile?.Userchaindata?.duke_date
                          ? moment(
                              get_seller_profile?.Userchaindata?.duke_date
                            ).format("MMM DD, yyyy hh:mm:ss A")
                          : "--"}
                      </p>
                    </div>
                  </div>
                </div> */}
              </div>
              <h3>Rank Up down</h3>

              <div className="rankrole">
                <table>
                  <tr>
                    {/* <th>Company</th> */}
                    <th>Rank name</th>
                    <th>Rank Up date</th>
                    <th>Rank Down date</th>
                    <th>Description</th>
                  </tr>

                  {get_seller_profile?.RankdownUpdata &&
                    get_seller_profile?.RankdownUpdata?.map((data, i) => {
                      const userRank =
                        data?.rankName == "0"
                          ? "free"
                          : data?.rankName == "1"
                          ? "FRANCHISE"
                          : data?.rankName == "2"
                          ? "BARON"
                          : data?.rankName == "3"
                          ? "COUNT"
                          : data?.rankName == "4"
                          ? "MARQUISE"
                          : data?.rankName == "5"
                          ? "DUKE"
                          : "ARCH DUKE";
                      return (
                        <tr>
                          <td>{userRank}</td>
                          <td>
                            {data?.rankupDate &&
                              moment(data?.rankupDate).format(
                                "MMM DD, yyyy hh:mm:ss A"
                              )}
                          </td>
                          <td>
                            {data?.rankdownDate &&
                              moment(data?.rankdownDate).format(
                                "MMM DD, yyyy hh:mm:ss A"
                              )}
                          </td>
                          <td>{data?.description}</td>
                        </tr>
                      );
                    })}
                </table>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* as licence */}
      <Modal
        title="User licence"
        centered
        open={statusLicence}
        onOk={() => hendletoAssLicence(false)}
        onCancel={() => setstatusLicence(false)}
        width={600}
      >
        <div>
          <div className="row mb-5">
            <div className="col-12">
              <Descriptions bordered column={1}>
                <Descriptions.Item label="license1">
                  {get_seller_profile?.Userchaindata?.license1}
                </Descriptions.Item>
                <Descriptions.Item label="license2">
                  {get_seller_profile?.Userchaindata?.license2}
                </Descriptions.Item>
                <Descriptions.Item label="license3">
                  {get_seller_profile?.Userchaindata?.license3}
                </Descriptions.Item>
                <Descriptions.Item label="license4">
                  {get_seller_profile?.Userchaindata?.license4}
                </Descriptions.Item>
                {/* <Descriptions.Item label="State">
                  {get_seller_profile?.state ? get_seller_profile?.state : "--"}
                </Descriptions.Item> */}
                <Descriptions.Item label="Fr date">
                  {get_seller_profile?.Userchaindata?.fr_date
                    ? moment(get_seller_profile?.Userchaindata?.fr_date).format(
                        "MMM DD, yyyy hh:mm:ss A"
                      )
                    : "--"}
                </Descriptions.Item>
                <Descriptions.Item label="Br date">
                  {get_seller_profile?.Userchaindata?.br_date
                    ? moment(get_seller_profile?.Userchaindata?.br_date).format(
                        "MMM DD, yyyy hh:mm:ss A"
                      )
                    : "--"}
                </Descriptions.Item>
                <Descriptions.Item label="Ct date">
                  {get_seller_profile?.Userchaindata?.ct_date
                    ? moment(get_seller_profile?.Userchaindata?.ct_date).format(
                        "MMM DD, yyyy hh:mm:ss A"
                      )
                    : "--"}
                </Descriptions.Item>
                <Descriptions.Item label="Mq date">
                  {get_seller_profile?.Userchaindata?.mq_date
                    ? moment(get_seller_profile?.Userchaindata?.mq_date).format(
                        "MMM DD, yyyy hh:mm:ss A"
                      )
                    : "--"}
                </Descriptions.Item>
                <Descriptions.Item label="Duke date">
                  {get_seller_profile?.Userchaindata?.duke_date
                    ? moment(
                        get_seller_profile?.Userchaindata?.duke_date
                      ).format("MMM DD, yyyy hh:mm:ss A")
                    : "--"}
                </Descriptions.Item>
              </Descriptions>
            </div>
            {/* <div className="col-8">
              <div className="d-flex">
                <div className="col-3">
                  <h6>license1 </h6>
                </div>
                <div className="col-9">
                  {" "}
                  <p> {get_seller_profile?.Userchaindata?.license1}</p>
                </div>
              </div>
              <div className="d-flex">
                <div className="col-3">
                  <h6>license2 </h6>
                </div>
                <div className="col-9">
                  {" "}
                  <p> {get_seller_profile?.Userchaindata?.license2}</p>
                </div>
              </div>
              <div className="d-flex">
                <div className="col-3">
                  <h6>license3 </h6>
                </div>
                <div className="col-9">
                  {" "}
                  <p> {get_seller_profile?.Userchaindata?.license3}</p>
                </div>
              </div>
              <div className="d-flex">
                <div className="col-3">
                  <h6>license4 </h6>
                </div>
                <div className="col-9">
                  {" "}
                  <p> {get_seller_profile?.Userchaindata?.license4}</p>
                </div>
              </div>
              <div className="d-flex">
                <div className="col-3">
                  <h6>br date</h6>
                </div>
                <div className="col-9">
                  {" "}
                  <p>
                    {get_seller_profile?.Userchaindata?.br_date &&
                      moment(get_seller_profile?.Userchaindata?.br_date).format(
                        "MMM DD, yyyy hh:mm:ss A"
                      )}
                  </p>
                </div>
              </div>
              <div className="d-flex">
                <div className="col-3">
                  <h6>ct date</h6>
                </div>
                <div className="col-9">
                  {" "}
                  <p>
                    {get_seller_profile?.Userchaindata?.ct_date &&
                      moment(get_seller_profile?.Userchaindata?.ct_date).format(
                        "MMM DD, yyyy hh:mm:ss A"
                      )}
                  </p>
                </div>
              </div>
              <div className="d-flex">
                <div className="col-3">
                  <h6>mq date</h6>
                </div>
                <div className="col-9">
                  {" "}
                  <p>
                    {get_seller_profile?.Userchaindata?.mq_date &&
                      moment(get_seller_profile?.Userchaindata?.mq_date).format(
                        "MMM DD, yyyy hh:mm:ss A"
                      )}
                  </p>
                </div>
              </div>
              <div className="d-flex">
                <div className="col-3">
                  <h6>duke date</h6>
                </div>
                <div className="col-9">
                  {" "}
                  <p>
                    {get_seller_profile?.Userchaindata?.duke_date &&
                      moment(
                        get_seller_profile?.Userchaindata?.duke_date
                      ).format("MMM DD, yyyy hh:mm:ss A")}
                  </p>
                </div>
              </div>
            </div> */}
          </div>

          <div className="">
            <div className="fv-row mb-7">
              <label className="fs-6 fw-semibold form-label mb-2">
                <span className="required">Select License</span>
                <i
                  className="fas fa-exclamation-circle ms-2 fs-7"
                  data-bs-toggle="popover"
                  data-bs-trigger="hover"
                  data-bs-html="true"
                  data-bs-content="Permission names is required to be unique."
                ></i>
              </label>

              <select
                value={lisshowcount}
                onChange={(e) => {
                  setlisscount(e.target.value);
                }}
                className="form-control bg-transparent"
              >
                <option value="">select license</option>
                <option value="1"> license 1</option>
                <option value="2"> license 2</option>
                <option value="3"> license 3</option>
                <option value="4"> license 4</option>
              </select>
            </div>
          </div>
          <div className="">
            <div className="fv-row mb-7">
              <label className="fs-6 fw-semibold form-label mb-2">
                <span className="required">Select User</span>
                <i
                  className="fas fa-exclamation-circle ms-2 fs-7"
                  data-bs-toggle="popover"
                  data-bs-trigger="hover"
                  data-bs-html="true"
                  data-bs-content="Permission names is required to be unique."
                ></i>
              </label>
              <AsyncPaginate
                key={managerKey}
                value={userselete}
                loadOptions={loadOptionsPalyer}
                onChange={setuserselete}
                additional={{
                  page: 1,
                }}
              />
            </div>
          </div>
        </div>
      </Modal>

      {/* add licance by admin for user */}
      <Modal
        title="Add licence"
        centered
        open={statusLicenceadd}
        onOk={() => hendletoAssLicenceadd(false)}
        onCancel={() => setstatusLicenceadd(false)}
        width={600}
      >
        <div>
          <div className="row mb-5"></div>

          <div className="">
            <div className="fv-row mb-7">
              <label className="fs-6 fw-semibold form-label mb-2">
                <span className="required">Select License Count</span>
                <i
                  className="fas fa-exclamation-circle ms-2 fs-7"
                  data-bs-toggle="popover"
                  data-bs-trigger="hover"
                  data-bs-html="true"
                  data-bs-content="Permission names is required to be unique."
                ></i>
              </label>

              <select
                value={lisshowcountadd}
                onChange={(e) => {
                  setlisscountadd(e.target.value);
                }}
                className="form-control bg-transparent"
              >
                <option value="">select license</option>
                <option value="0"> 0</option>
                <option value="1"> 1</option>
                <option value="2"> 2</option>
                <option value="3"> 3</option>
                <option value="4"> 4</option>
              </select>
            </div>
          </div>
        </div>
      </Modal>

      {/* edit  */}
      <Modal
        title="Edit Profile"
        centered
        open={statusEdit}
        onOk={() => edituserProfileByadmin(false)}
        onCancel={() => setstatusEdit(false)}
        width={800}
      >
        <div>
          <div className="d-flex mb-5">
            <div className="col-12">
              <div className="row">
                <div className="col-12 col-md-4">
                  <div className="p-5">
                    <label>Full Name </label>
                    <input
                      name="fullName"
                      value={userDetailsEdit?.fullName}
                      className="form-control"
                      onChange={(e) => {
                        hendleToprofileEdit(e);
                      }}
                    />
                  </div>
                </div>
                {/* <div className="col-12 col-md-4">
                  <div className="p-5">
                    <label>Full Name </label>
                    <input
                      name="fullName"
                      value={userDetailsEdit?.fullName}
                      className="form-control"
                    />
                  </div>
                </div> */}
                <div className="col-12 col-md-4">
                  <div className="p-5">
                    <label> </label>
                    {/* <input
                      name="fullName"
                      value={userDetailsEdit?.guardian}
                      className="form-control"
                    /> */}
                    <select
                      name="guardian"
                      className="form-control"
                      onChange={(e) => {
                        hendleToprofileEdit(e);
                      }}
                      value={userDetailsEdit?.guardian}
                    >
                      <option>Select </option>
                      <option value={"S/O"}>S/O</option>
                      <option value={"D/O"}>D/O</option>
                      <option value={"W/O"}>W/O</option>
                    </select>
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="p-5">
                    {userDetailsEdit?.guardian == "W/O" ? (
                      <label>Husband Name </label>
                    ) : (
                      <label>Father Name </label>
                    )}
                    {/* <label>Father Name </label> */}
                    <input
                      name="father_name"
                      onChange={(e) => {
                        hendleToprofileEdit(e);
                      }}
                      value={userDetailsEdit?.father_name}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="p-5">
                    <label>Email</label>
                    <input
                      name="email"
                      onChange={(e) => {
                        hendleToprofileEdit(e);
                      }}
                      value={userDetailsEdit?.email}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="p-5">
                    <label>Phone</label>
                    <input
                      name="phone"
                      onChange={(e) => {
                        hendleToprofileEdit(e);
                      }}
                      value={userDetailsEdit?.phone}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="p-5">
                    <label> Gender </label>
                    {/* <input
                      name="fullName"
                      value={userDetailsEdit?.guardian}
                      className="form-control"
                    /> */}
                    <select
                      className="form-control"
                      name="gender"
                      onChange={(e) => {
                        hendleToprofileEdit(e);
                      }}
                      value={userDetailsEdit?.gender}
                    >
                      <option>Select </option>
                      <option value={"male"}>Male</option>
                      <option value={"female"}>Female</option>
                    </select>
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="p-5">
                    <label>City</label>
                    <input
                      name="city"
                      onChange={(e) => {
                        hendleToprofileEdit(e);
                      }}
                      value={userDetailsEdit?.city}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="p-5">
                    <label>State</label>
                    <input
                      name="state"
                      onChange={(e) => {
                        hendleToprofileEdit(e);
                      }}
                      value={userDetailsEdit?.state}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="p-5">
                    <label>Country</label>
                    <input
                      name="country"
                      onChange={(e) => {
                        hendleToprofileEdit(e);
                      }}
                      value={userDetailsEdit?.country}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="p-5">
                    <label>Pic Code</label>
                    <input
                      name="zip"
                      onChange={(e) => {
                        hendleToprofileEdit(e);
                      }}
                      value={userDetailsEdit?.zip}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="p-5">
                    <label> Marital Status</label>
                    {/* <input
                      name="fullName"
                      value={userDetailsEdit?.guardian}
                      className="form-control"
                    /> */}
                    <select
                      className="form-control"
                      name="marital"
                      onChange={(e) => {
                        hendleToprofileEdit(e);
                      }}
                      value={userDetailsEdit?.marital}
                    >
                      <option>Select </option>
                      <option value={"SINGLE"}>SINGLE</option>
                      <option value={"MARRIED"}>MARRIED</option>
                      {/* <option value={"DIVORCED"}>DIVORCED</option>
                      <option value={"OTHER"}>OTHER</option> */}
                    </select>
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="p-5">
                    <label>Profession</label>
                    <input
                      name="professional"
                      onChange={(e) => {
                        hendleToprofileEdit(e);
                      }}
                      value={userDetailsEdit?.professional}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="p-5">
                    <label>Address-1</label>
                    <input
                      name="address1"
                      onChange={(e) => {
                        hendleToprofileEdit(e);
                      }}
                      value={userDetailsEdit?.address1}
                      className="form-control"
                    />
                  </div>
                </div>
                {/* <div className="col-12 col-md-4">
                  <div className="p-5">
                    <label>Address-2</label>
                    <input
                      name="address2"
                      onChange={(e) => {
                        hendleToprofileEdit(e);
                      }}
                      value={userDetailsEdit?.address2}
                      className="form-control"
                    />
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* edit kyc  */}
      <Modal
        title="Edit KYC"
        centered
        open={statusEditKyc}
        onOk={() => edituserKycByadmin(false)}
        onCancel={() => hendleToHideKyc(false)}
        width={800}
      >
        <div>
          <div className="d-flex mb-5">
            <div className="col-12">
              <label>
                <h6>Bank Details</h6>
              </label>
              <div className="row">
                <div className="col-12 col-md-4">
                  <div className="p-5">
                    <label>Bank Name</label>
                    <input
                      name="bank_name"
                      onChange={(e) => {
                        hendleToprofileEditKyc(e);
                      }}
                      value={userKycEdit?.bank_name}
                      className="form-control"
                    />
                  </div>
                </div>
                {/* {userKycEdit?.updatedAt}
                {moment(userKycEdit?.updatedAt).format(" DD/MM/YYYY hh:mm A")} */}
                <div className="col-12 col-md-4">
                  <div className="p-5">
                    <label>Branch Name</label>
                    <input
                      name="branch_name"
                      onChange={(e) => {
                        hendleToprofileEditKyc(e);
                      }}
                      value={userKycEdit?.branch_name}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="p-5">
                    <label>Acc No</label>
                    <input
                      name="acc_no"
                      onChange={(e) => {
                        hendleToprofileEditKyc(e);
                      }}
                      value={userKycEdit?.acc_no}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="p-5">
                    <label>Ifsc</label>
                    <input
                      name="ifsc"
                      onChange={(e) => {
                        hendleToprofileEditKyc(e);
                      }}
                      value={userKycEdit?.ifsc}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="p-5">
                    <label>Bank passbook</label>
                    <ImageUploading
                      // multiple
                      value={bankpassbook}
                      onChange={onChangebankpassbook}
                      maxNumber={maxNumber}
                      dataURLKey="data_url"
                      // acceptType={['jpg']}
                    >
                      {({
                        imageList,
                        onImageUpload,
                        onImageRemoveAll,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps,
                      }) => (
                        // write your building UI
                        <div className="upload__image-wrapper">
                          {/* <button
                        style={isDragging ? { color: "red" } : {}}
                        onClick={onImageUpload}
                        {...dragProps}
                        className="btn btn-secondary"
                      >
                        Choose feature image
                      </button> */}
                          {imageList?.length == 0 && (
                            <div className="ant-upload ant-upload-select">
                              <span className="ant-upload">
                                <button
                                  style={{ border: 0, background: "none" }}
                                  type="button"
                                  onClick={onImageUpload}
                                  {...dragProps}
                                >
                                  <UploadOutlined />
                                  <div style={{ marginTop: 8 }}>
                                    Upload Feature
                                  </div>
                                </button>
                              </span>
                            </div>
                          )}
                          &nbsp;
                          {/* <button
                        className="btn btn-secondary"
                        onClick={onImageRemoveAll}
                      >
                        Remove all images
                      </button> */}
                          {imageList.map((image, index) => (
                            <div key={index} className="image-item">
                              <div className="image-item__btn-wrapper uploadimgcon">
                                <img src={image.data_url} alt="" width="100" />
                                <button
                                  className="btn btn-info uploadimgcon1"
                                  onClick={() => onImageUpdate(index)}
                                >
                                  <EditOutlined />
                                </button>
                                <button
                                  className="btn btn-info uploadimgcon2"
                                  onClick={() => onImageRemove(index)}
                                >
                                  <DeleteOutlined />
                                </button>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </ImageUploading>
                  </div>
                </div>
              </div>
              <hr />
              <label>
                <h6>Doucument</h6>{" "}
              </label>
              <div className="row">
                <div className="col-12 col-md-4">
                  <div className="p-5">
                    <label>Aadhar Card No</label>
                    <input
                      name="aadhar_card"
                      onChange={(e) => {
                        hendleToprofileEditKyc(e);
                      }}
                      value={userKycEdit?.aadhar_card}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="p-5">
                    <label>Aadhar Front</label>
                    <ImageUploading
                      // multiple
                      value={aadharFront}
                      onChange={onChangeaadharFront}
                      maxNumber={maxNumber}
                      dataURLKey="data_url"
                      // acceptType={['jpg']}
                    >
                      {({
                        imageList,
                        onImageUpload,
                        onImageRemoveAll,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps,
                      }) => (
                        // write your building UI
                        <div className="upload__image-wrapper">
                          {/* <button
                        style={isDragging ? { color: "red" } : {}}
                        onClick={onImageUpload}
                        {...dragProps}
                        className="btn btn-secondary"
                      >
                        Choose feature image
                      </button> */}
                          {imageList?.length == 0 && (
                            <div className="ant-upload ant-upload-select">
                              <span className="ant-upload">
                                <button
                                  style={{ border: 0, background: "none" }}
                                  type="button"
                                  onClick={onImageUpload}
                                  {...dragProps}
                                >
                                  <UploadOutlined />
                                  <div style={{ marginTop: 8 }}>
                                    Upload Feature
                                  </div>
                                </button>
                              </span>
                            </div>
                          )}
                          &nbsp;
                          {/* <button
                        className="btn btn-secondary"
                        onClick={onImageRemoveAll}
                      >
                        Remove all images
                      </button> */}
                          {imageList.map((image, index) => (
                            <div key={index} className="image-item">
                              <div className="image-item__btn-wrapper uploadimgcon">
                                <img src={image.data_url} alt="" width="100" />
                                <button
                                  className="btn btn-info uploadimgcon1"
                                  onClick={() => onImageUpdate(index)}
                                >
                                  <EditOutlined />
                                </button>
                                <button
                                  className="btn btn-info uploadimgcon2"
                                  onClick={() => onImageRemove(index)}
                                >
                                  <DeleteOutlined />
                                </button>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </ImageUploading>
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="p-5">
                    <label>Aaddhar back</label>
                    <ImageUploading
                      // multiple
                      value={aadharBack}
                      onChange={onChangeaadharBack}
                      maxNumber={maxNumber}
                      dataURLKey="data_url"
                      // acceptType={['jpg']}
                    >
                      {({
                        imageList,
                        onImageUpload,
                        onImageRemoveAll,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps,
                      }) => (
                        // write your building UI
                        <div className="upload__image-wrapper">
                          {/* <button
                        style={isDragging ? { color: "red" } : {}}
                        onClick={onImageUpload}
                        {...dragProps}
                        className="btn btn-secondary"
                      >
                        Choose feature image
                      </button> */}
                          {imageList?.length == 0 && (
                            <div className="ant-upload ant-upload-select">
                              <span className="ant-upload">
                                <button
                                  style={{ border: 0, background: "none" }}
                                  type="button"
                                  onClick={onImageUpload}
                                  {...dragProps}
                                >
                                  <UploadOutlined />
                                  <div style={{ marginTop: 8 }}>
                                    Upload Feature
                                  </div>
                                </button>
                              </span>
                            </div>
                          )}
                          &nbsp;
                          {/* <button
                        className="btn btn-secondary"
                        onClick={onImageRemoveAll}
                      >
                        Remove all images
                      </button> */}
                          {imageList.map((image, index) => (
                            <div key={index} className="image-item">
                              <div className="image-item__btn-wrapper uploadimgcon">
                                <img src={image.data_url} alt="" width="100" />
                                <button
                                  className="btn btn-info uploadimgcon1"
                                  onClick={() => onImageUpdate(index)}
                                >
                                  <EditOutlined />
                                </button>
                                <button
                                  className="btn btn-info uploadimgcon2"
                                  onClick={() => onImageRemove(index)}
                                >
                                  <DeleteOutlined />
                                </button>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </ImageUploading>
                  </div>
                </div>
              </div>
              {/* <label>Bank Details</label> */}
              <div className="row">
                <div className="col-12 col-md-4">
                  <div className="p-5">
                    <label>Pan Card No</label>
                    <input
                      name="pan_card"
                      onChange={(e) => {
                        hendleToprofileEditKyc(e);
                      }}
                      value={userKycEdit?.pan_card}
                      className="form-control"
                    />
                  </div>
                </div>

                <div className="col-12 col-md-4">
                  <div className="p-5">
                    <label>Pan Card</label>
                    <ImageUploading
                      // multiple
                      value={images}
                      onChange={onChange}
                      maxNumber={maxNumber}
                      dataURLKey="data_url"
                      // acceptType={['jpg']}
                    >
                      {({
                        imageList,
                        onImageUpload,
                        onImageRemoveAll,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps,
                      }) => (
                        // write your building UI
                        <div className="upload__image-wrapper">
                          {/* <button
                        style={isDragging ? { color: "red" } : {}}
                        onClick={onImageUpload}
                        {...dragProps}
                        className="btn btn-secondary"
                      >
                        Choose feature image
                      </button> */}
                          {imageList?.length == 0 && (
                            <div className="ant-upload ant-upload-select">
                              <span className="ant-upload">
                                <button
                                  style={{ border: 0, background: "none" }}
                                  type="button"
                                  onClick={onImageUpload}
                                  {...dragProps}
                                >
                                  <UploadOutlined />
                                  <div style={{ marginTop: 8 }}>
                                    Upload Feature
                                  </div>
                                </button>
                              </span>
                            </div>
                          )}
                          &nbsp;
                          {/* <button
                        className="btn btn-secondary"
                        onClick={onImageRemoveAll}
                      >
                        Remove all images
                      </button> */}
                          {imageList.map((image, index) => (
                            <div key={index} className="image-item">
                              <div className="image-item__btn-wrapper uploadimgcon">
                                <img src={image.data_url} alt="" width="100" />
                                <button
                                  className="btn btn-info uploadimgcon1"
                                  onClick={() => onImageUpdate(index)}
                                >
                                  <EditOutlined />
                                </button>
                                <button
                                  className="btn btn-info uploadimgcon2"
                                  onClick={() => onImageRemove(index)}
                                >
                                  <DeleteOutlined />
                                </button>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </ImageUploading>
                  </div>
                </div>

                {/* <div className="col-12 col-md-3">
                  <div className="p-5">
                    <label>Bank passbook</label>
                    <ImageUploading
                      // multiple
                      value={bankpassbook}
                      onChange={onChangebankpassbook}
                      maxNumber={maxNumber}
                      dataURLKey="data_url"
                      // acceptType={['jpg']}
                    >
                      {({
                        imageList,
                        onImageUpload,
                        onImageRemoveAll,
                        onImageUpdate,
                        onImageRemove,
                        isDragging,
                        dragProps,
                      }) => (
                        // write your building UI
                        <div className="upload__image-wrapper">
                          <button
                            style={isDragging ? { color: "red" } : {}}
                            onClick={onImageUpload}
                            {...dragProps}
                            className="btn btn-secondary"
                          >
                            Choose Bank passbook image
                          </button>
                          &nbsp;
                          <button
                            className="btn btn-secondary"
                            onClick={onImageRemoveAll}
                          >
                            Remove images
                          </button>
                          {imageList.map((image, index) => (
                            <div key={index} className="image-item">
                              <img src={image.data_url} alt="" width="100" />
                              <div className="image-item__btn-wrapper">
                                <button
                                  className="btn btn-secondary"
                                  onClick={() => onImageUpdate(index)}
                                >
                                  Update
                                </button>
                                <button
                                  className="btn btn-secondary"
                                  onClick={() => onImageRemove(index)}
                                >
                                  Remove
                                </button>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </ImageUploading>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <div className="flex">
        <div>
          <HeadingBack
            title={"Users"}
            subHeading={`Total ${users_list?.totalDocs || 0} Users`}
          />
        </div>
        <Col md={5} sm={5} xs={12} className="pl-5  ml-5">
          <Input
            type="text"
            autoComplete="new-off"
            className="form-control"
            onChange={(e) => handleChange(e.target.value)}
            placeholder="Search..."
            // value={values?.TestCode}
          />
          {/* <AsyncPaginate
            // key={managerKey}
            value={seleceteManger}
            loadOptions={loadOptionsgetManager}
            onChange={setseleceteManger}
            additional={{
              page: 1,
            }}
          /> */}
        </Col>
        <Col md={5} sm={5} xs={12} className="pl-5  ml-5">
          {/* <Input
            type="text"
            autoComplete="new-off"
            className="form-control"
            onChange={(e) => handleChangeStatus(e.target.value)}
            placeholder="Search..."
            // value={values?.TestCode}
          /> */}

          <select
            className="form-control"
            onChange={(e) => handleChangeStatus(e.target.value)}
            value={statusfinal}
          >
            {/* <option value={""}>Select Rank</option> */}
            <option value={""}>All</option>
            <option value={"0"}>Fc</option>
            <option value={"1"}>Fr</option>
            <option value={"2"}>Br</option>
            <option value={"3"}>Ct</option>
            <option value={"4"}>Mq</option>
            <option value={"5"}>Duke</option>
          </select>
          {/* <AsyncPaginate
            // key={managerKey}
            value={seleceteManger}
            loadOptions={loadOptionsgetManager}
            onChange={setseleceteManger}
            additional={{
              page: 1,
            }}
          /> */}
        </Col>

        {/* <div className="flex1 flex justify-content-end align-items-center">
          <Tooltip title="Add">
            <span className="jop_btn" onClick={() => navigate("/user/add")}>
              Add
              <SvgIcon.Add width={40} height={40} className="cursor-pointer" />
            </span>
          </Tooltip>
        </div> */}
      </div>
      {/* <ExportToExcelNew /> */}
      <div className="tableResponsive mt-10">
        <Table
          className={"table antd_table"}
          dataSource={listdata}
          columns={columns}
          pagination={{
            current: parseInt(page),
            pageSize: 10,
            onChange(current, pageSize) {
              hendlePahegi(current);
            },
            hideOnSinglePage: true,
            total: users_list?.totalDocs ? users_list?.totalDocs : 0,
          }}
        />
      </div>
    </div>
  );
}

export default User;

let year = new Date()?.getFullYear();
export const SHIFT_TIME_CONST = 19;
export const NO_PERMISSION_MESSAGE =
  "You don't have permission to access this.Please contact your administrator.";
export const MONTH_DATA = [
  { label: "Jan", value: 1, year: year },
  { label: "Feb", value: 2, year: year },
  { label: "Mar", value: 3, year: year },
  { label: "Apr", value: 4, year: year },
  { label: "May", value: 5, year: year },
  { label: "Jun", value: 6, year: year },
  { label: "Jul", value: 7, year: year },
  { label: "Aug", value: 8, year: year },
  { label: "Sep", value: 9, year: year },
  { label: "Oct", value: 10, year: year },
  { label: "Nov", value: 11, year: year },
  { label: "Dec", value: 12, year: year },
];
export const OPEN_KEY_DATA2 = [
  { openKey: "faq-management", path: ["faqs", "faq-category"] },
  { openKey: "user-management", path: ["users", "roles"] },
];
export const OPEN_KEY_DATA = [
  { openKey: [], selected: ["dashboard"], path: "" },
  { openKey: [], selected: ["dashboard"], path: "dashboard" },
  { openKey: [], selected: ["users"], path: "users" },
  { openKey: [], selected: ["sale"], path: "sale" },
  { openKey: [], selected: ["transaction"], path: "transaction" },
  { openKey: [], selected: ["setting"], path: "setting" },
  { openKey: [], selected: ["wallet"], path: "wallet" },
  { openKey: [], selected: ["ticket"], path: "ticket" },
  { openKey: [], selected: ["sale-return"], path: "sale-return" },
  { openKey: [], selected: ["withdrawal-request"], path: "withdrawal-request" },
  { openKey: [], selected: ["kyc-pending"], path: "kyc-pending" },
  { openKey: [], selected: ["mq-chalenge"], path: "mq-chalenge" },
  { openKey: [], selected: ["gpg-by-admin"], path: "gpg-by-admin" },
  { openKey: [], selected: ["duqe-chalenge"], path: "duqe-chalenge" },
  { openKey: [], selected: ["month-reports"], path: "month-reports" },
  { openKey: [], selected: ["settings"], path: "settings" },
  { openKey: [], selected: ["faq"], path: "faq" },
  { openKey: [], selected: ["blog"], path: "blog" },
  { openKey: [], selected: ["enquiry"], path: "enquiry" },
  { openKey: [], selected: ["testmanagement"], path: "test-management" },
  { openKey: [], selected: ["ticket-management"], path: "ticket-management" },
  { openKey: [], selected: ["slider"], path: "slider" },
  {
    openKey: [],
    selected: ["commission-distribution"],
    path: "commission-distribution",
  },
  { openKey: ["category"], selected: ["category"], path: "category" },
  { openKey: ["category"], selected: ["sub-category"], path: "sub-category" },
  {
    openKey: ["category"],
    selected: ["sub-sub-category"],
    path: "sub-sub-category",
  },
  { openKey: ["category"], selected: ["product"], path: "product" },
  { openKey: ["category"], selected: ["product-stock"], path: "product-stock" },
  { openKey: ["gpg-ct"], selected: ["gpg-ct"], path: "gpg-ct" },
  { openKey: ["gpg-ct"], selected: ["gpg-mq"], path: "gpg-mq" },
  { openKey: ["gpg-ct"], selected: ["gpg-duqe"], path: "gpg-duqe" },

  { openKey: ["user-management"], selected: ["users"], path: "users" },
  { openKey: ["user-management"], selected: ["users"], path: "user-list" },
  { openKey: ["user-management"], selected: ["users"], path: "user-add" },
  { openKey: ["user-management"], selected: ["users"], path: "user-edit" },
  { openKey: ["user-management"], selected: ["roles"], path: "roles" },
  { openKey: ["user-management"], selected: ["roles"], path: "role-list" },
  { openKey: ["user-management"], selected: ["roles"], path: "role-add" },
  { openKey: ["user-management"], selected: ["roles"], path: "role-edit" },
  {
    openKey: ["user-management"],
    selected: ["roles"],
    path: "role-user-permission",
  },
  { openKey: ["faq-management"], selected: ["faqs"], path: "faqs" },
  { openKey: ["faq-management"], selected: ["faqs"], path: "faq-list" },
  { openKey: ["faq-management"], selected: ["faqs"], path: "faq-add" },
  { openKey: ["faq-management"], selected: ["faqs"], path: "faq-edit" },
  {
    openKey: ["faq-management"],
    selected: ["faq-category"],
    path: "faq-category",
  },
  {
    openKey: ["faq-management"],
    selected: ["faq-category"],
    path: "faq-category-list",
  },
  {
    openKey: ["faq-management"],
    selected: ["faq-category"],
    path: "faq-category-add",
  },
  {
    openKey: ["faq-management"],
    selected: ["faq-category"],
    path: "faq-category-edit",
  },
  {
    openKey: ["testmanagement"],
    selected: ["testmanagement"],
    path: "test-management-edit",
  },
  {
    openKey: ["testmanagement"],
    selected: ["testmanagement"],
    path: "test-management-add",
  },
  {
    openKey: ["department"],
    selected: ["department"],
    path: "department-edit",
  },
  { openKey: ["department"], selected: ["department"], path: "department-add" },
  { openKey: [], selected: ["department"], path: "department" },
  {
    openKey: ["job-applications"],
    selected: ["job-applications"],
    path: "job-applications-edit",
  },
  {
    openKey: ["job-applications"],
    selected: ["job-applications"],
    path: "job-applications-add",
  },
  { openKey: [], selected: ["job-applications"], path: "job-applications" },
  { openKey: ["job-post"], selected: ["job-post"], path: "jobs-edit" },
  { openKey: ["job-post"], selected: ["job-post"], path: "jobs-add" },
  { openKey: [], selected: ["jobs"], path: "job-post" },
  { openKey: ["seo"], selected: ["seo"], path: "seo-management-add" },
  { openKey: ["seo"], selected: ["seo"], path: "seo-management-edit" },
  { openKey: [], selected: ["seo"], path: "seo-management" },
  { openKey: [], selected: ["investor Category"], path: "investor-category" },
  {
    openKey: ["investor Category"],
    selected: ["investor Category"],
    path: "investor-category-add",
  },
  {
    openKey: ["investor Category"],
    selected: ["investor Category"],
    path: "investor-category-edit",
  },
  {
    openKey: [],
    selected: ["investor Category"],
    path: "investor-subcategory",
  },
  {
    openKey: ["investor Category"],
    selected: ["investor Category"],
    path: "investor-subcategory-add",
  },
  {
    openKey: ["investor Category"],
    selected: ["investor Category"],
    path: "investor-subcategory-edit",
  },
  { openKey: [], selected: ["financials"], path: "investor-financials" },
  {
    openKey: ["investor Category"],
    selected: ["financials"],
    path: "investor-financials-add",
  },
  {
    openKey: ["investor Category"],
    selected: ["financials"],
    path: "investor-financials-edit",
  },
  { openKey: [], selected: ["investor"], path: "investor-information" },
  {
    openKey: ["investor"],
    selected: ["investor"],
    path: "investor-information-add",
  },
  {
    openKey: ["investor"],
    selected: ["investor"],
    path: "investor-information-edit",
  },
  {
    openKey: [],
    selected: ["corporate-governance"],
    path: "investor-corporate-governance",
  },
  {
    openKey: ["corporate-governance"],
    selected: ["corporate-governance"],
    path: "investor-corporate-governance-add",
  },
  {
    openKey: ["corporate-governance"],
    selected: ["corporate-governance"],
    path: "/investor-corporate-governance-edit",
  },
  { openKey: [], selected: ["ticket-management"], path: "ticket-management" },
  {
    openKey: ["ticket-management"],
    selected: ["ticket-management"],
    path: "ticket-management-add",
  },
  {
    openKey: ["ticket-management"],
    selected: ["ticket-management"],
    path: "ticket-management-edit",
  },
  { openKey: [], selected: ["page-banner"], path: "page-banner" },
  { openKey: [], selected: ["page-banner"], path: "page-banner-add" },
  { openKey: [], selected: ["page-banner"], path: "page-banner-edit" },
];

import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { LogoutOutlined } from "@ant-design/icons";
import { DownOutlined, SmileOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu, Space, Tooltip } from "antd";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import SvgIcon from "../../const/SvgIcon";
import { DeleteToken } from "../../utils";
import IMAGES from "../../const/Image";
const TopBar = ({ collapsed, setCollapsed }) => {
  const navigate = useNavigate();
  const handleLogout = () => {
    DeleteToken();
    navigate("/login", { replace: true });
  };
  const items = [
    {
      key: "1",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.antgroup.com"
        >
          1st menu item
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.aliyun.com"
        >
          2nd menu item (disabled)
        </a>
      ),
      icon: <SmileOutlined />,
      disabled: true,
    },
    {
      key: "3",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.luohanacademy.com"
        >
          3rd menu item (disabled)
        </a>
      ),
      disabled: true,
    },
    {
      key: "4",
      danger: true,
      label: "a danger item",
    },
  ];
  return (
    <div className="flex justify-content-between align-items-center">
      <div>
        {React.createElement(
          collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
          {
            className: "trigger topheadericon",
            onClick: () => setCollapsed(!collapsed),
          }
        )}
      </div>
      <div className="mr-20 topheadericon2">
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="1">
                {" "}
                <span
                  className="cursor-pointer "
                  // onClick={() => hendleToViewUser(item)}
                >
                 
                  <span className="logout-btn2">
                 
                    <Link to={"/profile"}>Profile</Link>
               
                  </span>
                  
                </span>
              </Menu.Item>
              <Menu.Item key="2">
                {" "}
                <span
                  className="cursor-pointer "
                  // onClick={() => hendleToViewUser(item)}
                >
                  <span className="logout-btn2" onClick={() => handleLogout()}>
                    Logout
                    <LogoutOutlined />
                  </span>
                  {/* <SvgIcon.Visibility className="scale-icon edit-icon" /> */}
                  {/* View */}
                </span>
              </Menu.Item>
            </Menu>
          }
          placement="bottomRight"
        >
          <button
            className="profilebtntopbar  ml-3  "
            // onClick={() => hendleToViewLicence(item)}
          >
            <img
              src={IMAGES.LogoShort}
              className="cursor-pointer"
              alt=""
              style={{
                width: "45px",
                borderRadius: "50%",
                marginRight: "10px",
              }}
              onClick={() => navigate("/")}
            />
            Ril India <DownOutlined />
          </button>
        </Dropdown>
      </div>
    </div>
  );
};

export default TopBar;

import { Col, Modal, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { allapiAction } from "../../Redux/common/action";
import HeadingBack from "../component/HeadingBack";
import moment from "moment";
import ReactPaginate from "react-paginate";
import { URL } from "../../Redux/common/url";
import axios from "axios";
import SvgIcon from "../const/SvgIcon";
import { ExportToExcel } from "../component/ExportToExcel";
import { toast } from "react-toastify";
function MonthReports2() {
  const [datesele, setdatesele] = useState(new Date());
  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setpageNo] = useState(1);

  const [showmain, setshowmain] = useState(false);
  const [pass, setpass] = useState("");

  const hendleTosubmitShow = (e) => {
    if (pass == "info@admin") {
      setshowmain(true);
    } else {
      toast.error("Please Enter Correct Password");
    }
  };

  const dispatch = useDispatch();
  const month_report_2 = useSelector((state) =>
    state?.allapi?.month_report_2 ? state?.allapi?.month_report_2 : {}
  );
  const month_report = month_report_2?.docs ? month_report_2?.docs : [];
  useEffect(() => {
    setPageCount(month_report_2?.totalPages);
  }, [month_report_2]);

  const [month11, setmonth11] = useState("");
  const [years11, setyears11] = useState("");

  const hendleTosubmit = (e) => {
    console.log(e?.target?.value);

    // const dateStr = "2023-08-13";
    const date = new Date(e?.target?.value);
    setdatesele(date);
    const year = date.getFullYear(); // 2023
    const month = date.getMonth() + 1;
    console.log(year);
    console.log(month);
    setmonth11(month);
    setyears11(year);
    setpageNo(1);
    dispatch(
      allapiAction.teamCommissionByDateBypagi({ year, month, pageNumber: 1 })
    );
  };
  useEffect(() => {
    const date = new Date();

    const year = date.getFullYear(); // 2023
    const month = date.getMonth() + 1;
    console.log(year);
    console.log(month);
    setmonth11(month);
    setyears11(year);

    dispatch(
      allapiAction.teamCommissionByDateBypagi({ year, month, pageNumber: 1 })
    );
    // teamCommissionByDate
  }, []);

  const [Loder, setLoder] = useState(false);

  const handlePageClick = async (event) => {
    // const newOffset = event.selected % (comments.length / 2);
    // setCurrentPage(newOffset + 1);
    // setItemOffset(newOffset * itemsPerPage);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    const data = event?.selected + 1;

    setpageNo(data);

    // if (!userSearch) {
    const date = datesele;

    const year = date.getFullYear(); // 2023
    const month = date.getMonth() + 1;
    console.log(year);
    console.log(month);
    setLoder(true);
    const finaldata = await dispatch(
      allapiAction.teamCommissionByDateBypagi({ year, month, pageNumber: data })
    );
    setLoder(false);
    console.log(finaldata);
    return () => {};
    // } else {
    //   dispatch(
    //     ShoetoggelAction.searchUser({ pageNumber: data, key: userSearch })
    //   );

    //   return () => {};
    // }
  };
  const [btnshow, setbtnshow] = useState(true);
  const hendleeeeeallincome = async () => {
    let data = JSON.stringify({
      month: month11,
      year: years11,
      id: "",
      status: "4",
    });
    // years11
    // month11
    const token = await localStorage.getItem("admin_token");
    // const config = {
    //   headers: {
    //     Authorization: "Bearer " + token,
    //   },
    // };
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: URL?.API_BASE_URL + "/adminApi/usermonthsellincome",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      data: data,
    };
    setbtnshow(false);
    axios
      .request(config)
      .then((response) => {
        console.log(response.data?.data);
        console.log(response.data?.data?.data);
        setbtnshow(true);
        // ExportToExcel(response.data?.data, "RIL594113");
        if (response.data?.data?.length > 0) {
          ExportToExcel(response.data?.data, "all income");
        } else {
          toast.error("data not found");
        }
      })
      .catch((error) => {
        setbtnshow(true);
        console.log(error);
      });
  };
  const hendleeeeeallincomewithac = async () => {
    let data = JSON.stringify({
      month: month11,
      year: years11,
      id: "",
      status: "4",
    });
    const token = await localStorage.getItem("admin_token");
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: URL?.API_BASE_URL + "/adminApi/usermonthsellincomeWithAc",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      data: data,
    };
    setbtnshow(false);
    axios
      .request(config)
      .then((response) => {
        console.log(response.data?.data);
        console.log(response.data?.data?.data);
        setbtnshow(true);
        if (response.data?.data?.length > 0) {
          ExportToExcel(response.data?.data, "income with account");
        } else {
          toast.error("data not found");
        }
        // ExportToExcel(response.data?.data, "RIL594113");
      })
      .catch((error) => {
        setbtnshow(true);
        console.log(error);
      });
  };
  const hendleeeeeallincomewithOutac = async () => {
    let data = JSON.stringify({
      month: month11,
      year: years11,
      id: "",
      status: "4",
    });
    const token = await localStorage.getItem("admin_token");
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: URL?.API_BASE_URL + "/adminApi/usermonthsellincomeWithOutAc",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      data: data,
    };
    setbtnshow(false);
    axios
      .request(config)
      .then((response) => {
        console.log(response.data?.data);
        console.log(response.data?.data?.data);
        setbtnshow(true);

        if (response.data?.data?.length > 0) {
          ExportToExcel(response.data?.data, "income without account");
        } else {
          toast.error("data not found");
        }
      })
      .catch((error) => {
        setbtnshow(true);
        console.log(error);
      });
  };

  const hendleuserincomeShowbyAdmin = async () => {
    let data = JSON.stringify({
      month: month11,
      year: years11,
      id: "",
      status: "4",
    });
    const token = await localStorage.getItem("admin_token");
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: URL?.API_BASE_URL + "/adminApi/userincomeShowbyAdmin",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      data: data,
    };
    setbtnshow(false);
    axios
      .request(config)
      .then((response) => {
        console.log(response.data?.data);
        console.log(response.data?.data?.data);
        setbtnshow(true);

        if (response.data?.success) {
          toast.success("success");
        }

        // if (response.data?.data?.length > 0) {
        //   // ExportToExcel(response.data?.data, "income without account");
        // } else {
        //   toast.error("data not found");
        // }
      })
      .catch((error) => {
        setbtnshow(true);
        console.log(error);
      });
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleOk = () => {
    hendleuserincomeShowbyAdmin();

    setIsModalOpen(false);
    return () => {};
  };

  return (
    <div>
      <Modal
        title="User Income Show"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>
          Are you sure You have selected the correct month {month11}-{years11}{" "}
        </p>
      </Modal>
      <div className="flex">
        <div>
          <HeadingBack
            title={"Month Reports"}
            // subHeading={`Total ${get_product_list?.totalDocs || 0} Product`}
          />
        </div>
        <Col md={5} sm={5} xs={12} className="pl-5  ml-5">
          <input
            type="month"
            className="form-control"
            onChange={(e) => {
              hendleTosubmit(e);
            }}
          />
          {/* <Input
            type="text"
            autoComplete="new-off"
            className="form-control"
            onChange={(e) => handleChange(e.target.value)}
            placeholder="Search..."
            // value={values?.TestCode}
          /> */}
          {/* <AsyncPaginate
            // key={managerKey}
            value={seleceteManger}
            loadOptions={loadOptionsgetManager}
            onChange={setseleceteManger}
            additional={{
              page: 1,
            }}
          /> */}
        </Col>

        <div className="flex1 flex justify-content-end align-items-center">
          {showmain && btnshow && (
            <Tooltip title="All">
              <span
                className="jop_btn"
                onClick={() => {
                  hendleeeeeallincome();
                  //  navigate("/add-product")
                }}
              >
                All
                {/* <SvgIcon.Add width={40} height={40} className="cursor-pointer" /> */}
              </span>
            </Tooltip>
          )}
          {showmain && btnshow && (
            <Tooltip title=" With ac">
              <span
                className="jop_btn ms-2"
                onClick={() => {
                  hendleeeeeallincomewithac();
                  //  navigate("/add-product")
                }}
              >
                With ac
                {/* <SvgIcon.Add width={40} height={40} className="cursor-pointer" /> */}
              </span>
            </Tooltip>
          )}
          {showmain && btnshow && (
            <Tooltip title="Without Ac">
              <span
                className="jop_btn ms-2"
                onClick={() => {
                  hendleeeeeallincomewithOutac();
                  //  navigate("/add-product")
                }}
              >
                Without Ac
                {/* <SvgIcon.Add width={40} height={40} className="cursor-pointer" /> */}
              </span>
            </Tooltip>
          )}
          {showmain && btnshow && (
            <Tooltip title="Without Ac">
              <span
                className="jop_btn ms-2"
                onClick={() => {
                  setIsModalOpen(true);
                  //  navigate("/add-product")
                }}
              >
                User income Show
                {/* <SvgIcon.Add width={40} height={40} className="cursor-pointer" /> */}
              </span>
            </Tooltip>
          )}
        </div>
      </div>
      {showmain ? (
        <div>
          <table className="table monthReport">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Full Name(SB)---Rank</th>
                <th scope="col">Sponsor Name(SB)---Rank</th>
                <th scope="col">Total Sale</th>
                <th scope="col">Commision</th>
                <th scope="col">Date</th>
                <th scope="col">description</th>
              </tr>
            </thead>

            {Loder ? (
              <tbody>
                <div className="loadingbox" style={{ height: "50vh" }}>
                  <h1>loading...</h1>
                </div>
              </tbody>
            ) : (
              <tbody>
                {month_report &&
                  month_report?.map((data, i) => {
                    return (
                      <tr>
                        <td>{i + 1}</td>
                        <td>{data?.fullName}</td>
                        <td>{data?.sponsorName}</td>
                        <td>{data?.total_sale}</td>
                        <td>{data?.commission}</td>
                        <td>
                          {moment(data?.sale_date).format(
                            "DD MMM  YYYY hh:mm a "
                          )}
                        </td>
                        <td>{data?.description}</td>
                      </tr>
                    );
                  })}

                {/* <tr>
           
              <td>Jacob</td>
              <td>Thornton</td>
              <td>@fat</td>
            </tr> */}
              </tbody>
            )}
          </table>
          <ReactPaginate
            breakLabel="..."
            nextLabel="next"
            className="dataTables_paginate paging_simple_numbers category"
            onPageChange={handlePageClick}
            pageRangeDisplayed={2}
            pageCount={pageCount}
            previousLabel="previous"
            // renderOnZeroPageCount={null}
            forcePage={pageNo - 1}
          />
        </div>
      ) : (
        <div className="mt-30">
          <div style={{ width: "300px", margin: "auto" }}>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Password"
              onChange={(e) => {
                setpass(e?.target?.value);
              }}
            />
            <button
              className="btn btn-primary mt-15"
              onClick={() => {
                hendleTosubmitShow();
              }}
            >
              Submit
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default MonthReports2;

import React, { useEffect, useState } from "react";
import { Col, Input, Row, Table, Tooltip } from "antd";
import HeadingBack from "../component/HeadingBack";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import SvgIcon from "../const/SvgIcon";
import { allapiAction } from "../../Redux/common/action";
import moment from "moment";

import { Button, Modal, Space } from "antd";

function MqChalenge() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const pagev = searchParams.get("page");
  const searchData = searchParams.get("search");

  const page = pagev == null ? 1 : pagev;
  const search = searchData == null ? "" : searchData;

  console.log(searchParams);
  console.log(page);
  console.log(searchData);

  const mq_chelenge = useSelector((state) =>
    state?.allapi?.mq_chelenge ? state?.allapi?.mq_chelenge : {}
  );

  const [pages, setPage] = useState(1);

  const listdata = mq_chelenge?.docs ? mq_chelenge?.docs : [];

  useEffect(() => {
    if (search) {
      dispatch(allapiAction.getmqChalengesearch({ page: page, key: search }));
    } else {
      dispatch(allapiAction.getmqChalenge(page));
    }

    return () => {};
  }, [location]);

  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userId, setuserId] = useState("");

  const [statusData, setstatusData] = useState({});
  const [userData, setuserData] = useState({});
  const [statusDataBox, setstatusDataBox] = useState(false);

  const hendleToViewM = (e) => {
    setstatusDataBox(true);
    setuserData(e);
  };

  const hendleTochangedata = (e) => {
    const { name, value } = e.target;

    setstatusData({ ...statusData, [name]: value });
  };

  const hendleToSubmetUpdate = () => {
    dispatch(
      allapiAction.addFoundByAdmin({
        id: userData?._id,
        amount: statusData?.amount,

        pageNumber: page,
      })
    );

    setstatusDataBox(false);
  };

  const showModal = (e) => {
    console.log(e);
    setuserId(e);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    dispatch(
      allapiAction.user_inactive({
        _id: userId,
        status: userId,
        // active: 0,
        pageNumber: page,
      })
    );
    setuserId("");
    setIsModalOpen(false);
    return () => {};
  };

  const hendleToChangeStatus = (id, status) => {
    dispatch(
      allapiAction.user_inactive({
        _id: id,
        status: status,
        // active: 0,
        pageNumber: page,
      })
    );
    // setuserId("");
    // setIsModalOpen(false);
    return () => {};
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const hendleToStatus = (id, e) => {
    // statusCategory

    dispatch(
      allapiAction.MqchalengeUprove({ id: id, chalengeApproved: e, page: page })
    );
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {};
  };

  const columns = [
    {
      title: "S.No.",
      dataIndex: "sno",
      key: "sno",
      className: "table_first_col",
      render: (value, item, index) => (page - 1) * 10 + index + 1,
    },
    {
      title: "User Id",
      dataIndex: "referId",
      key: "referId",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer coustomNevc"
              // onClick={() => navigate(`/user-details/${item?.referId}`)}
            >
              {item?.userId?.referId}
            </span>
          </>
        );
      },
    },
    {
      title: "User Name",
      dataIndex: "name",
      key: "name",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer coustomNevc"
              // onClick={() => navigate(`/user-details/${item?.referId}`)}
            >
              {item?.userId?.fullName}
            </span>
          </>
        );
      },
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer coustomNevc"
              // onClick={() => navigate(`/user-details/${item?.referId}`)}
            >
              {item?.userId?.phone}
            </span>
          </>
        );
      },
    },
    {
      title: "Sponser Code",
      dataIndex: "Sponser Code",
      key: "Sponser Code",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer coustomNevc"
              // onClick={() => navigate(`/user-details/${item?.referId}`)}
            >
              {item?.userId?.referByuserId?.referId}
            </span>
          </>
        );
      },
    },
    {
      title: "Sponser Name",
      dataIndex: "Sponser Name",
      key: "Sponser Name",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer coustomNevc"
              // onClick={() => navigate(`/user-details/${item?.referId}`)}
            >
              {item?.userId?.referByuserId?.fullName}
            </span>
          </>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      className: "table_action_col",
      render: (value, item, index) => {
        const userRank =
          item?.userId?.status == "0"
            ? "free"
            : item?.userId?.status == "1"
            ? "FRANCHISE"
            : item?.userId?.status == "2"
            ? "BARON"
            : item?.userId?.status == "3"
            ? "COUNT"
            : item?.userId?.status == "4"
            ? "MARQUISE"
            : item?.userId?.status == "4"
            ? "DUKE"
            : "ARCH DUKE";
        return (
          <>
            <span
              className="cursor-pointer"
              // onClick={() => navigate(`/users/contact/${item?.id}`)}
            >
              {userRank}
            </span>
            {/* <Tooltip title={item?.status}>
                <button
                  className={
                    item?.status == "Active"
                      ? "success-button ml-3"
                      : "delete-button ml-3"
                  }
                  // onClick={() =>
                  //   hendleToChangeStatus(
                  //     item?._id,
                  //     item?.status == "Active" ? "Inactive" : "Active"
                  //   )
                  // }
                >
                  {userRank}
                </button>
              </Tooltip> */}
          </>
        );
      },
    },
    {
      title: "Mq Challenge Date",
      dataIndex: "Mq Challenge Date",
      key: "Mq Challenge Date",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer"
              // onClick={() => navigate(`/users/contact/${item?.id}`)}
            >
              {moment(item?.createdAt).format(" DD/MM/YYYY ")}
            </span>
          </>
        );
      },
    },
    {
      title: "Mq Up Date Admin",
      dataIndex: "Mq Up Date Admin",
      key: "Mq Up Date Admin",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer"
              // onClick={() => navigate(`/users/contact/${item?.id}`)}
            >
              {moment(item?.chalengeDateApproved).format(" DD/MM/YYYY ")}
            </span>
          </>
        );
      },
    },
    {
      title: "Challenge start",
      dataIndex: "Day",
      key: "Day",
      className: "table_action_col",
      render: (value, item, index) => {
        const dateString = item?.createdAt;
        const date = new Date(dateString);
        const day = date.getDate();
        console.log(item?.chalengeDateApproved);

        const originalDate2 = moment(item?.chalengeDateApproved, "YYYY-MM-DD");
        const daycheck2 = item?.chalengecycle == 0 ? 0 : item?.chalengecycle;

        // console.log(originalDate2);
        // console.log(daycheck2);
        const futureDate2 =
          daycheck2 > 0
            ? originalDate2.add(daycheck2, "months")
            : originalDate2;

        // console.log(futureDate2);
        var today = new Date();
        var targetDate = new Date(futureDate2);

        const datetimeObj = moment(targetDate);

        const month = datetimeObj.month() + 1; // Moment.js months are 0-indexed
        const year = datetimeObj.year();

        const startDate = new Date(year, month - 1, 1);
        // console.log(startDate);
        // console.log(targetDate);
        // console.log(today);
        // const originalDate24 = moment(startDate, "YYYY-MM-DD");
        // const remaDate = moment(originalDate24.add(4, "months"));
        // var timeDifference = remaDate - today;
        // var timeDifference = today - startDate;

        // // Convert milliseconds to days
        // var daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

        // console.log("Difference in days:", daysDifference);
        // console.log(futureDate2.toDate());
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer coustomNevc"
              // onClick={() => navigate(`/user-details/${item?.referId}`)}
            >
              {moment(startDate).format(" DD/MM/YYYY ")}
              {/* {startDate} */}
            </span>
          </>
        );
      },
    },
    {
      title: "Day",
      dataIndex: "Day",
      key: "Day",
      className: "table_action_col",
      render: (value, item, index) => {
        const dateString = item?.createdAt;
        const date = new Date(dateString);
        const day = date.getDate();
        console.log(item?.chalengeDateApproved);

        const originalDate2 = moment(item?.chalengeDateApproved, "YYYY-MM-DD");
        const daycheck2 = item?.chalengecycle == 0 ? 0 : item?.chalengecycle;

        // console.log(originalDate2);
        // console.log(daycheck2);
        const futureDate2 =
          daycheck2 > 0
            ? originalDate2.add(daycheck2, "months")
            : originalDate2;

        // console.log(futureDate2);
        var today = new Date();
        var targetDate = new Date(futureDate2);

        const datetimeObj = moment(targetDate);

        const month = datetimeObj.month() + 1; // Moment.js months are 0-indexed
        const year = datetimeObj.year();

        const startDate = new Date(year, month - 1, 1);
        // console.log(startDate);
        // console.log(targetDate);
        // console.log(today);
        // const originalDate24 = moment(startDate, "YYYY-MM-DD");
        // const remaDate = moment(originalDate24.add(4, "months"));
        // var timeDifference = remaDate - today;
        var timeDifference = today - startDate;

        // Convert milliseconds to days
        var daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

        console.log("Difference in days:", daysDifference);
        // console.log(futureDate2.toDate());
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer coustomNevc"
              // onClick={() => navigate(`/user-details/${item?.referId}`)}
            >
              {daysDifference}
            </span>
          </>
        );
      },
    },
    {
      title: "Total Bussiness",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer coustomNevc"
              // onClick={() => navigate(`/user-details/${item?.referId}`)}
            >
              {item?.chalengeApproved}
            </span>
          </>
        );
      },
    },
    {
      title: "Joining Date",
      dataIndex: "Joining Date",
      key: "Joining Date",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact"> */}
            <span
              className="cursor-pointer"
              // onClick={() => navigate(`/users/contact/${item?.id}`)}
            >
              {moment(item?.userId?.createdAt).format(" DD/MM/YYYY ")}
            </span>
          </>
        );
      },
    },

    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact">
              <span
                className="cursor-pointer"
                onClick={() => navigate(`/users/contact/${item?.id}`)}
              >
                <SvgIcon.contacts className="scale-icon edit-icon" />
              </span>
            </Tooltip> */}
            {/* <Tooltip title="delete">
              <button
                className="filter-button ml-3"
                onClick={() => showModal(item?.id)}
              >
                Delete
              </button>
            </Tooltip> */}
            <Tooltip title={"Update"}>
              <button
                className={
                  item?.chalengeApproved == "Active"
                    ? "success-button ml-3"
                    : "delete-button ml-3"
                }
                onClick={() =>
                  hendleToStatus(
                    item?._id,
                    item?.chalengeApproved == "Active" ? "Inactive" : "Active"
                  )
                }
              >
                Update
              </button>
            </Tooltip>
          </>
        );
      },
    },
  ];

  const [searchs, setsearch] = useState("");
  const [searchsr, setsearchn] = useState("");
  const handleChange = (e) => {
    setsearch(e);
    setPage(1);
    navigate("/mq-challenge?page=" + 1 + "&search=" + searchsr);
    // if (e) {
    //   dispatch(allapiAction.getuserListSearch({ page: 1, search: e }));
    //   return () => {};
    // } else {
    //   dispatch(allapiAction.getuserList(1));

    //   return () => {};
    // }
    // getuserListSearch
    // &user_id=${data?.user_id}&search=${data?.user_id}
  };

  const hendlePahegi = (e) => {
    setPage(e);

    console.log(location);

    navigate("/mq-challenge?page=" + e + "&search=" + search);

    // if (search) {
    //   dispatch(allapiAction.getuserListSearch({ page: e, search: search }));
    //   return () => {};
    // } else {
    //   dispatch(allapiAction.getuserList(e));

    //   return () => {};
    // }
  };

  return (
    <div>
      <Modal
        title="delete User"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Are you sure delete</p>
      </Modal>

      <Modal
        title="Add Amount"
        centered
        open={statusDataBox}
        onOk={() => hendleToSubmetUpdate(false)}
        onCancel={() => setstatusDataBox(false)}
        width={500}
      >
        <div>
          <Row>
            <Col span={24}>Amount</Col>
            <Col span={24}>
              <Input
                name="amount"
                placeholder="enter amount"
                value={statusData?.amount}
                onChange={(e) => {
                  hendleTochangedata(e);
                }}
              />
            </Col>
          </Row>
        </div>
      </Modal>

      <div className="flex">
        <div>
          <HeadingBack
            title={"Mq Challenge"}
            subHeading={`Total ${mq_chelenge?.totalDocs || 0} Mq Challenge`}
          />
        </div>
        <Col md={5} sm={5} xs={12} className="pl-5  ml-5">
          <Input
            type="text"
            autoComplete="new-off"
            className="form-control"
            onChange={(e) => setsearchn(e.target.value)}
            placeholder="Search..."
            // value={values?.TestCode}
          />

          {/* <AsyncPaginate
            // key={managerKey}
            value={seleceteManger}
            loadOptions={loadOptionsgetManager}
            onChange={setseleceteManger}
            additional={{
              page: 1,
            }}
          /> */}
        </Col>
        <button
          className="filter-button"
          onClick={(e) => {
            handleChange(e);
          }}
        >
          Search
        </button>

        {/* <div className="flex1 flex justify-content-end align-items-center">
          <Tooltip title="Add">
            <span className="jop_btn" onClick={() => navigate("/user/add")}>
              Add
              <SvgIcon.Add width={40} height={40} className="cursor-pointer" />
            </span>
          </Tooltip>
        </div> */}
      </div>

      <div className="tableResponsive mt-10">
        <Table
          className={"table antd_table"}
          dataSource={listdata}
          columns={columns}
          pagination={{
            current: parseInt(page),
            pageSize: 10,
            onChange(current, pageSize) {
              hendlePahegi(current);
            },
            hideOnSinglePage: true,
            total: mq_chelenge?.totalDocs ? mq_chelenge?.totalDocs : 0,
          }}
        />
      </div>
    </div>
  );
}

export default MqChalenge;

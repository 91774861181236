import { combineReducers } from "redux";
// import allUserRegisterReducer from "./../Signup/reducer";
// // import postAssignmetReducer from "./../PostAssignment/reducer";
// import commenreducer from "./../Loading/reducer";
// import productreducer from "./../Product/reducer";
import allapireducer from "./../AllApi/reducer";
// import userAllreducer from "./../Users/reducer";


export default combineReducers({
    // allUserRegister: allUserRegisterReducer,
    // // postAssignment: postAssignmetReducer,
    // commen: commenreducer,
    // product: productreducer,
    allapi: allapireducer,
   
});
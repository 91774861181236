import React, { useEffect, useState } from "react";
import { Layout, Grid } from "antd";
import { Outlet } from "react-router-dom";
import Sidebar from "./Sidebar";
import TopBar from "./TopBar";
const { Header, Content, Footer } = Layout;
// import {  } from "antd";

const { useBreakpoint } = Grid;
const LayoutMain = () => {
  const screens = useBreakpoint();
  const [collapsed, setCollapsed] = useState(false);

  console.log("screens------------------------------", !screens.md);
  console.log("screens------------------------------", screens);

  useEffect(() => {
    setCollapsed(!screens.md);
    return () => {};
  }, [screens]);

  return (
    <>
      <Layout className="layout_css">
        <Sidebar collapsed={collapsed} />
        <Layout className="site-layout">
          <Header className="" style={{ padding: 0 }}>
            <TopBar collapsed={collapsed} setCollapsed={setCollapsed} />
          </Header>
          <Content
            className="site-layout-background"
            style={{
              margin: "6px",
              padding: 10,
              minHeight: 280,
              overflowY: "auto",
            }}
          >
            <Outlet />
          </Content>
          {/* <Footer></Footer> */}
        </Layout>
      </Layout>
    </>
  );
};

export default LayoutMain;

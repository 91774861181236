import React, { useEffect } from "react";
import { Col, Row } from "antd";
import Boxes from "./Boxes";
import { useDispatch, useSelector } from "react-redux";
import { allapiAction } from "../../Redux/common/action";
import axios from "axios";

import { Chart } from "react-google-charts";
import Boxes2 from "./Boxes2";
import UserrTable from "./UserrTable";
import CenteralHealthLine from "./CenteralHealthLineChart/CenteralHealthLine";
import { URL } from "../../Redux/common/url";

function Home() {
  const dispatch = useDispatch();
  const get_category_sale = useSelector((state) =>
    state?.allapi?.get_category_sale ? state?.allapi?.get_category_sale : []
  );
  const top_three = useSelector((state) =>
    state?.allapi?.top_three ? state?.allapi?.top_three : {}
  );
  const get_leader_board_c_m = useSelector((state) =>
    state?.allapi?.get_leader_board_c_m
      ? state?.allapi?.get_leader_board_c_m
      : {}
  );
  useEffect(() => {
    dispatch(allapiAction.dashboard({}));
    dispatch(allapiAction.LeaderBoard({}));
    dispatch(allapiAction.LeaderBoardbyAdmin({}));
    dispatch(allapiAction.categoryWiseSale({}));

    return () => {};
  }, []);

  const data2 = [
    ["Year", "Sales", "Expenses"],
    ["Monday", 20000, 30000],
    ["Tuesday", 35000, 15000],
    ["Wednesday", 38000, 14000],
    ["Thursday", 25000, 35000],
    ["Friday", 34000, 22000],
    ["Saturday", 42000, 34000],
    ["Sunday", 18000, 38000],
  ];
  const data = get_category_sale;

  const options = {
    title: "Company Performance",
    curveType: "function",
    legend: { position: "bottom" },
  };

  return (
    <div>
      <Row gutter={[20, 20]} type="flex">
        <Col lg={18} md={24} sm={24} xs={24}>
          <div className=" p-10">
            {/* <CenteralHealthLine /> */}
            {/* <Boxes /> */}
            <Boxes2 />
            <div className="dashboardBoxShad m-5 mt-10  mb-10">
              <div className="flex justify-content-between">
                <div>
                  <div className="fw-600">Total Sale</div>
                </div>
                <div className="flex justify-content-end gap20 align-items-center">
                  <div className="flex gap30">
                    <div className="relative">
                      <span className="ct_chart_status beforeActive">
                        Category
                      </span>
                    </div>
                    {/* <div className="relative">
                      <span className="ct_chart_status beforeInactive">
                        Inactive
                      </span>
                    </div> */}
                  </div>
                </div>
              </div>
              <Chart
                chartType="Bar"
                width="100%"
                height="400px"
                data={data}
                options={{
                  fontName: "Open Sans",
                  fontSize: 14,
                  color: "#212529",
                  tooltip: { isHtml: true },
                  legend: { position: "none" },
                  colors: ["#9365b8", "#1559a2"],
                  curveType: "function",
                  hAxis: {
                    textStyle: {
                      whiteSpace: "wrap",
                      fontName: "Open Sans",
                      fontSize: 14,
                      color: "#212529",
                    },
                  },
                  chartArea: {
                    left: 70,
                    top: 10,
                    bottom: 40,
                    width: "100%",
                    height: "100%",
                  },
                }}
              />
            </div>
            <div className="dashboardBoxShad   m-5 mt-10  mb-10">
              <div className="dashboardBoxShadvs">
                <div className="flex justify-content-between">
                  <div>
                    <div className="fw-600">Leader Board</div>
                  </div>
                  <div className="flex justify-content-end gap20 align-items-center">
                    <div className="flex gap30">
                      {/* <div className="relative">
                      <span className="ct_chart_status beforeActive">
                        Category
                      </span>
                    </div> */}
                      {/* <div className="relative">
                      <span className="ct_chart_status beforeInactive">
                        Inactive
                      </span>
                    </div> */}
                    </div>
                  </div>
                </div>
                <div class="row mt-20">
                  {top_three?.leaderBoard &&
                    top_three?.leaderBoard?.map((data, i) => {
                      return (
                        <div class="col-lg-12 col-md-12 mb-2 ldbox">
                          <div className="row align-items-center">
                            <div className="col-sm-1 col-3">
                              <div className="profile_ulv">
                                <h6 className="mt-3 ms-3">{i + 1}</h6>
                              </div>
                            </div>
                            <div className="col-sm-11 col-9">
                              <div className="profile_ulv">
                                <div className="row align-items-center">
                                  <div className="col-sm-1 col-3">
                                    <div>
                                      <img
                                        src={
                                          data?.profile_image
                                            ? URL?.API_BASE_URL +
                                              data?.profile_image
                                            : "/media/blank.png"
                                        }
                                        // src="/assets/imgs/people/avatar-2.png"
                                        alt=""
                                        style={{
                                          width: "50px",
                                          height: "50px",
                                          borderRadius: "50%",
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-sm-11 col-8">
                                    <h6>{data?.fullName}</h6>
                                  </div>
                                  {/* <div className="col-2">3</div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>

            {/* mq top 15  */}
            <div className="dashboardBoxShad   m-5 mt-10  mb-10">
              <div className="dashboardBoxShadvs">
                <div className="flex justify-content-between">
                  <div>
                    <div className="fw-600">Mq Leader Board</div>
                  </div>
                  <div className="flex justify-content-end gap20 align-items-center">
                    <div className="flex gap30">
                      {/* <div className="relative">
                      <span className="ct_chart_status beforeActive">
                        Category
                      </span>
                    </div> */}
                      {/* <div className="relative">
                      <span className="ct_chart_status beforeInactive">
                        Inactive
                      </span>
                    </div> */}
                    </div>
                  </div>
                </div>
                <div class="row mt-20">
                  {get_leader_board_c_m?.MarqusesLeader &&
                    get_leader_board_c_m?.MarqusesLeader?.map((data, i) => {
                      return (
                        <div class="col-lg-12 col-md-12 mb-2 ldbox">
                          <div className="row align-items-center">
                            <div className="col-sm-1 col-3">
                              <div className="profile_ulv">
                                <h6 className="mt-3 ms-3">{i + 1}</h6>
                              </div>
                            </div>
                            <div className="col-sm-11 col-9">
                              <div className="profile_ulv">
                                <div className="row align-items-center">
                                  <div className="col-sm-1 col-3">
                                    <div>
                                      <img
                                        src={
                                          data?.profile_image
                                            ? URL?.API_BASE_URL +
                                              data?.profile_image
                                            : "/media/blank.png"
                                        }
                                        // src="/assets/imgs/people/avatar-2.png"
                                        alt=""
                                        style={{
                                          width: "50px",
                                          height: "50px",
                                          borderRadius: "50%",
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-sm-11 col-8">
                                    <h6>
                                      {data?.referId}-{data?.fullName}--
                                      {data?.leaderboardbyadmin}
                                    </h6>
                                  </div>
                                  {/* <div className="col-2">3</div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
            {/* count top 10  */}
            <div className="dashboardBoxShad   m-5 mt-10  mb-10">
              <div className="dashboardBoxShadvs">
                <div className="flex justify-content-between">
                  <div>
                    <div className="fw-600">Count Leader Board</div>
                  </div>
                  <div className="flex justify-content-end gap20 align-items-center">
                    <div className="flex gap30">
                      {/* <div className="relative">
                      <span className="ct_chart_status beforeActive">
                        Category
                      </span>
                    </div> */}
                      {/* <div className="relative">
                      <span className="ct_chart_status beforeInactive">
                        Inactive
                      </span>
                    </div> */}
                    </div>
                  </div>
                </div>
                <div class="row mt-20">
                  {get_leader_board_c_m?.countLeader &&
                    get_leader_board_c_m?.countLeader?.map((data, i) => {
                      return (
                        <div class="col-lg-12 col-md-12 mb-2 ldbox">
                          <div className="row align-items-center">
                            <div className="col-sm-1 col-3">
                              <div className="profile_ulv">
                                <h6 className="mt-3 ms-3">{i + 1}</h6>
                              </div>
                            </div>
                            <div className="col-sm-11 col-9">
                              <div className="profile_ulv">
                                <div className="row align-items-center">
                                  <div className="col-sm-1 col-3">
                                    <div>
                                      <img
                                        src={
                                          data?.profile_image
                                            ? URL?.API_BASE_URL +
                                              data?.profile_image
                                            : "/media/blank.png"
                                        }
                                        // src="/assets/imgs/people/avatar-2.png"
                                        alt=""
                                        style={{
                                          width: "50px",
                                          height: "50px",
                                          borderRadius: "50%",
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-sm-11 col-8">
                                    <h6>
                                      {data?.referId}-{data?.fullName}--
                                      {data?.leaderboardbyadmin}
                                    </h6>
                                  </div>
                                  {/* <div className="col-2">3</div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>

            {/* <div className="dashboardBoxShad  p-10 m-5 mt-10">
              <Row>
                <Col span={24}>
                  <h2 className="p-10">Users</h2>
                </Col>
              </Row>

              <UserrTable />
            </div> */}
          </div>
        </Col>
        <Col lg={6} md={24} sm={24} xs={24}>
          <div>
            <Boxes />
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Home;

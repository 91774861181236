import { Col } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { allapiAction } from "../../Redux/common/action";
import HeadingBack from "../component/HeadingBack";
import moment from "moment";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import { setDefaultLocale } from "react-datepicker";
function Gpgbyadmin() {
  const [datesele, setdatesele] = useState(new Date());
  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setpageNo] = useState(1);
  const [startDate, setStartDate] = useState(new Date());


  const [showmain, setshowmain] = useState(false);
  const [pass, setpass] = useState("");

  const hendleTosubmitShow = (e) => {
    if (pass == "info@admin") {
      setshowmain(true);
    }else{
      toast.error("Please Enter Correct Password");
    }
  };
  const dispatch = useDispatch();
  const month_report_2 = useSelector((state) =>
    state?.allapi?.month_report_2 ? state?.allapi?.month_report_2 : {}
  );
  const month_report = month_report_2?.docs ? month_report_2?.docs : [];
  useEffect(() => {
    setPageCount(month_report_2?.totalPages);
  }, [month_report_2]);

  useEffect(() => {
    const date = new Date();

    const year = date.getFullYear(); // 2023
    const month = date.getMonth() + 1;
    console.log(year);
    console.log(month);

    // dispatch(
    //   allapiAction.teamCommissionByDateBypagi({ year, month, pageNumber: 1 })
    // );
    // teamCommissionByDate
  }, []);
  const [userBuss, setuserBuss] = useState({});

  const hendleChangerole = (e) => {
    const { name, value } = e.target;
    setuserBuss({ ...userBuss, [name]: value });
  };

  const [Loder, setLoder] = useState(false);
  console.log(Loder);
  const handlePageClick = async (event) => {
    // const newOffset = event.selected % (comments.length / 2);
    // setCurrentPage(newOffset + 1);
    // setItemOffset(newOffset * itemsPerPage);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    const data = event?.selected + 1;

    setpageNo(data);

    // if (!userSearch) {
    const date = datesele;

    const year = date.getFullYear(); // 2023
    const month = date.getMonth() + 1;
    console.log(year);
    console.log(month);
    setLoder(true);
    const finaldata = await dispatch(
      allapiAction.commissiondistribution({ year, month })
    );
    setLoder(false);
    console.log(finaldata);
    return () => {};
    // } else {
    //   dispatch(
    //     ShoetoggelAction.searchUser({ pageNumber: data, key: userSearch })
    //   );

    //   return () => {};
    // }
  };

  const [first, setfirst] = useState("");
  const hendleTosubmit = async (e) => {
    if (!userBuss?.amount) {
      toast.error("enter amount");
    } else if (!userBuss?.userid) {
      toast.error("enter user id");
    } else {
      const date = new Date(first);
      setdatesele(date);
      const year = date.getFullYear(); // 2023
      const month = date.getMonth() + 1;
      console.log(year);
      console.log(month);
      setpageNo(1);
      setLoder(true);
      const finaldata = await dispatch(
        allapiAction.gpgByAdmin({
          amount: userBuss?.amount,
          userid: userBuss?.userid,
          gpgdate: startDate,
        })
      );
      //   dispatch(allapiAction.commissiondistribution({ year, month }));
      setLoder(false);
    }
  };
  return (
    <div>
      <div className="flex">
        <div>
          <HeadingBack
            title={"Gpg by admin"}
            // subHeading={`Total ${get_product_list?.totalDocs || 0} Product`}
          />
        </div>
        {/* <Col md={5} sm={5} xs={12} className="pl-5  ml-5">
          <input
            type="month"
            className="form-control"
            onChange={(e) => {
              setfirst(e?.target?.value);
            }}
          />

         
        </Col> */}
      </div>
      {/* <div className="loadingbox">
        {Loder ? (
          "Loadind -----"
        ) : (
          <button
            className="btn btn-primary"
            onClick={(e) => {
              hendleTosubmit(e);
            }}
          >
            Commission Distribution
          </button>
        )}
      </div> */}
   { showmain?  <div style={{ width: "350px", margin: "auto" }}>
        <div className="card mx-auto ">
          {/* {!showOtpBox ? ( */}
          <div className="card-body">
            <div className="mb-3">
              <label className="form-label">Amount</label>
              <input
                name="amount"
                className="form-control"
                placeholder="enter amount"
                type="text"
                value={userBuss?.amount}
                onChange={(e) => {
                  hendleChangerole(e);
                }}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">User id</label>
              <input
                name="userid"
                className="form-control"
                placeholder="enterr user id"
                type="text"
                value={userBuss?.userid}
                onChange={(e) => {
                  hendleChangerole(e);
                }}
              />
            </div>

            <div className="mb-3">
              <label className="form-label">withdrawal start time</label>
              {/* <input
                  name='withdrawalstarttime'
                  className='form-control'
                  placeholder='Enter withdrawalstarttime'
                  type='text'
                  onChange={(e) => {
                    hendleChangerole(e)
                  }}
                /> */}
              <DatePicker
                className="form-control"
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                //   showTimeSelect
                //   showTimeSelectOnly
                timeIntervals={15}
                //   timeCaption="Time"
                dateFormat="dd/MM/yyyy"
              />
            </div>
            <div className="mb-3">
              <button
                className="btn btn-primary"
                onClick={(e) => {
                  hendleTosubmit(e);
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div> :  <div className="mt-30">
          <div style={{ width: "300px", margin: "auto" }}>
            <input
              type="text"
               placeholder="Enter Password"
              className="form-control"
              onChange={(e) => {
                setpass(e?.target?.value);
              }}
            />
            <button
              className="btn btn-primary mt-15"
              onClick={() => {
                hendleTosubmitShow();
              }}
            >
              Submit
            </button>
          </div>
        </div>}
    </div>
  );
}

export default Gpgbyadmin;

import { Col, Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Chart } from "react-google-charts";

const Boxes = () => {
  const navigate = useNavigate();
  const deshboard = useSelector((state) =>
    state?.allapi?.deshboard ? state?.allapi?.deshboard : {}
  );

  console.log(deshboard);
  console.log(deshboard?.fcUser ? deshboard?.fcUser : 0);
  // const totalUsers = useSelector(state => state.dashboard.totalUsers || "");
  // const totalFaqs = useSelector(state => state.dashboard.totalFaqs || "");
  // const TotalJobsAdd = useSelector(state => state.dashboard.TotalJobs || "");
  // const totalJobApp = useSelector(state => state.jobAppliction.totalJobApp || 0);
  // console.log(totalJobApp);

  const data = [
    ["Rank", "Count"],
    ["fc", deshboard?.fcUser ? deshboard?.fcUser : 0],
    ["fr", deshboard?.frUser ? deshboard?.frUser : 0],
    ["br", deshboard?.brUser ? deshboard?.brUser : 0],
    ["ct", deshboard?.ctUser ? deshboard?.ctUser : 0],
    ["mq", deshboard?.mqUser ? deshboard?.mqUser : 0],
    ["duqe", deshboard?.duqeUser ? deshboard?.duqeUser : 0],
  ];

  const selechart = [
    ["Label", "Value"],
    ["Sale", deshboard?.companyBusiness ? deshboard?.companyBusiness : 0],
  ];

  const options = {
    title: "My Daily Activities",
    pieHole: 0.4,
    is3D: false,
  };
  const options2 = {
    width: 400,
    height: 120,
    redFrom: 90,
    redTo: 100,
    yellowFrom: 75,
    yellowTo: 90,
    minorTicks: 5,
  };
  return (
    <Row gutter={[0, 20]}>
      <Col span={24}>
        <div
          className="flex1 p-10 dashboardBoxShad cursor-pointer"
          // onClick={() => navigate("/users")}
        >
          <div className="p-10">
            <Chart
              chartType="PieChart"
              width="100%"
              height="264px"
              data={data}
              options={options}
            />
          </div>
        </div>
      </Col>
      <Col span={24}>
        <div
          className="flex1 p-10 dashboardBoxShad cursor-pointer"
          // onClick={() => navigate("/users")}
        >
          <div className="p-10">
            <Chart
              chartType="Gauge"
              width="100%"
              height="264px"
              data={selechart}
              options={options2}
            />
          </div>
        </div>
      </Col>
      {/* <Col span={24}>
        <div
          className="flex1 p-10 dashboardBox cursor-pointer"
       
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingRisk fw-600">Total Contact</div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className='priceText color-risk'>{deshboard?.total_contact || 0}</div>
          </div>
        </div>
      </Col>
      <Col span={24}>
        <div
          className="flex1 p-10 dashboardBox cursor-pointer"
          onClick={() => navigate("/category")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600">
              Total Category
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className='priceText'>{deshboard?.quiz_category || 0}</div>
          </div>
        </div>
      </Col>
      <Col span={24}>
        <div
          className="flex1 p-10 dashboardBox cursor-pointer"
          onClick={() => navigate("/quiz")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingRisk fw-600">
              Total Quiz
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className='priceText color-risk'>{deshboard?.total_quiz || 0}</div>
          </div>
        </div>
      </Col>
      <Col span={24}>
        <div
          className="flex1 p-10 dashboardBox cursor-pointer"
          onClick={() => navigate("/question")}
        >
          <div className="flex justify-content-between align-item-center">
            <div className="relative boxHeadingTotal fw-600">
              Total Question
            </div>
          </div>
          <div className="flex justify-content-end align-item-center">
            <div className='priceText'>{deshboard?.total_question || 0}</div>
          </div>
        </div>
      </Col> */}
    </Row>
  );
};

export default Boxes;

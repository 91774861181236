import React, { useEffect, useState, useRef } from "react";
import { Col, Input, Row, Table, Tooltip } from "antd";
import HeadingBack from "../component/HeadingBack";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import SvgIcon from "../const/SvgIcon";
import { allapiAction } from "../../Redux/common/action";
import moment from "moment";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Button, Modal, Space } from "antd";
import { URL } from "../../Redux/common/url";
import ReactToPrint from "react-to-print";
import { toast } from "react-toastify";
import Select from "react-select";
function Pageconect() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const pagev = searchParams.get("page");
  const searchData = searchParams.get("search");
  const containerRef = useRef();
  const { confirm } = Modal;

  const page = pagev == null ? 1 : pagev;
  const search = searchData == null ? "" : searchData;

  const get_ticket_list = useSelector((state) =>
    state?.allapi?.footer_page_list ? state?.allapi?.footer_page_list : {}
  );
  const order_details = useSelector((state) =>
    state?.allapi?.order_details ? state?.allapi?.order_details : {}
  );
  const get_invoice_details = useSelector((state) =>
    state?.allapi?.get_invoice_details ? state?.allapi?.get_invoice_details : {}
  );
  const get_message_list = useSelector((state) =>
    state?.allapi?.get_message_list ? state?.allapi?.get_message_list : []
  );
  const footer_page_list_select = useSelector((state) =>
    state?.allapi?.footer_page_list_select
      ? state?.allapi?.footer_page_list_select
      : []
  );
  const footer_page_type = useSelector((state) =>
    state?.allapi?.footer_page_type ? state?.allapi?.footer_page_type : []
  );

  const [pages, setPage] = useState(1);

  const listdata = get_ticket_list ? get_ticket_list : [];

  useEffect(() => {
    if (search) {
      dispatch(allapiAction.searchTicket({ page: page, key: search }));
    } else {
      dispatch(allapiAction.footerPagepageListet(page));
      dispatch(allapiAction.getpageListfooter(page));
    }
    // dispatch(allapiAction.getInvoceDetails());
    return () => {};
  }, [location]);

  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOption1, setSelectedOption1] = useState(null);
  const [subList, setsubList] = React.useState([]);
  const [subList1, setsubList1] = React.useState([]);
  console.log(subList);
  console.log(subList1);
  useEffect(() => {
    if (footer_page_type?.length > 0) {
      const dropList = footer_page_type?.map((data, i) => {
        data.value = data?._id;
        data.label = data?.title;

        return data;
      });

      setsubList(dropList);
    } else {
    }
  }, [footer_page_type]);
  useEffect(() => {
    const dropList = footer_page_list_select?.map((data, i) => {
      data.value = data?._id;
      data.label = data?.title;

      return data;
    });

    setsubList1(dropList);
  }, [footer_page_list_select]);
  useEffect(() => {
    if (get_message_list?.length > 0) {
      // containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [get_message_list]);

  const showDeleteConfirm = (e) => {
    confirm({
      title: "Are you sure delete ?",
      icon: <ExclamationCircleFilled />,
      content: "Some descriptions",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        hendleDelete(e);
        console.log("OK");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const hendleDelete = (e) => {
    console.log(e);
    // deleteProduct
    // setShowDelete(false);
    dispatch(allapiAction.deleteProduct({ productId: e, pageNo: page }));
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {};
  };
  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpend, setIsModalOpend] = useState(false);
  const [isModalOpenE, setIsModalOpenE] = useState(false);
  const [userId, setuserId] = useState("");
  const [userIdE, setuserIdE] = useState({});

  const hendleTochangeEdit = (e) => {
    const { name, value } = e?.target;

    setuserIdE({ ...userIdE, [name]: value });
  };

  const [statusData, setstatusData] = useState({});
  const [userData, setuserData] = useState({});
  const [statusDataBox, setstatusDataBox] = useState(false);
  const [statusDataBoxView, setstatusDataBoxView] = useState(false);
  const [statusDataBoxReturn, setstatusDataBoxReturn] = useState(false);
  const [productID, setproductID] = useState("");
  const [statusUpTrStatus1, setstatusUpTrStatus1] = useState("");
  const [statusUpTrStatus, setstatusUpTrStatus] = useState("");
  const [statusUpTrId, setstatusUpTrId] = useState("");

  console.log(statusUpTrStatus1);
  const hendleToViewM = (e) => {
    setstatusDataBox(true);
    setuserData(e);
    setproductID(e);
    dispatch(
      allapiAction.getmessageSupportList({
        ticket_id: e?._id,
        user_id: e?.user_id?._id,
      })
    );
    // containerRef.current.scrollTop = containerRef.current.scrollHeight;
  };

  console.log(userData?.user_id);

  const [enterMessage, setenterMessage] = useState("");

  const onEnterPress = (e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      createMessage();
    }
    containerRef.current.scrollTop = containerRef.current.scrollHeight;
  };

  const createMessage = () => {
    // createMessage

    console.log("testSubmit");

    try {
      // const {data: auth} = await login(values.role_name, values.role_description)
      // saveAuth(auth)
      // const {data: user} = await getUserByToken(auth.api_token)
      // dispatch(adminAction.createRoles(values))
      const data = new FormData();

      // data.append("title", imageEditfor);

      // images

      //   subject: '',
      //   message: '',

      if (!enterMessage) {
        toast.error("please enter message");
      } else {
        // setLoading(true)

        // data.append('sellerId', '20')
        // data.append('image', images[0]?.file)
        // imagesss?.forEach((file: any) => {
        //   console.log(file?.file)
        //   data.append('images', file?.file)
        // })
        // console.log(data)

        dispatch(
          allapiAction.createMessage({
            ticket_id: userData?._id,
            message: enterMessage,
            // productCount: roledata?.productCount,
          })
        );
        containerRef.current.scrollTop = containerRef.current.scrollHeight;
        // setShow(false)
        setenterMessage("");
        // setLoading(false)
      }
      // setCurrentUser(user)
    } catch (error) {
      // console.error(error)
      // saveAuth(undefined)
      // setStatus('The login details are incorrect')
      // setSubmitting(false)
      // setLoading(false)
    }
  };
  const hendleToViewProduct = (e) => {
    setstatusDataBoxView(true);
    setuserData(e);
    setproductID(e);
    dispatch(allapiAction.getuserOrderDetails({ _id: e?._id }));
  };
  const hendleToViewReturn = (e) => {
    setstatusDataBoxReturn(true);
    // setuserData(e);
    setstatusUpTrId(e);
    // dispatch(allapiAction.getuserOrderDetails({ _id: e?._id }));
  };
  const hendleSubmitReturn = (e) => {
    setstatusDataBoxReturn(false);
    dispatch(
      allapiAction.cancelOrderByAdmin({
        _id: statusUpTrId?._id,
        user_id: statusUpTrId?.user_id?._id,
        description: statusUpTrStatus,
        page: page,
      })
    );
    setstatusUpTrStatus("");
    // dispatch(allapiAction.getuserOrderDetails({ _id: e?._id }));
  };

  const shippingCharge = order_details?.sub_total > 10000 ? 699 : 199;
  console.log(order_details?.orderproductsList);

  const totalSubtotalPricegstdd = order_details?.orderproductsList?.reduce(
    (total, obj) => {
      return total + (obj.sub_total * obj?.gst) / 100;
    },
    0
  );
  // return total +(obj.subtotalprice * obj?.gst) / 100 ;
  // console.log(shippingCharge)
  // console.log(totalSubtotalPricegstdd)

  const totalSubtotalPricegst = totalSubtotalPricegstdd;

  const totalAmount =
    order_details?.sub_total + shippingCharge + totalSubtotalPricegstdd;
  const hendleTochangedata = (e) => {
    const { name, value } = e.target;

    setstatusData({ ...statusData, [name]: value });
  };

  const hendleToSubmetUpdate = () => {
    dispatch(
      allapiAction.addFoundByAdmin({
        id: userData?._id,
        amount: statusData?.amount,

        pageNumber: page,
      })
    );

    setstatusDataBox(false);
  };

  const showModal = (e) => {
    console.log(e);
    setuserId(e);
    setIsModalOpen(true);
  };
  const showModald = (e) => {
    console.log(e);
    setuserId(e);
    setIsModalOpend(true);
  };
  const showModalE = (e) => {
    console.log(e);
    setuserIdE(e);
    setIsModalOpenE(true);
  };

  const handleOk = () => {
    dispatch(
      allapiAction.createFooterPageList({
        pageName: selectedOption1?.title,
        page_id: selectedOption1?._id,
        page_sulg: selectedOption1?.page_sulg,
        pageType_id: selectedOption?._id,
        pageType: selectedOption?.title,
        // active: 0,
        // pageNumber: page,
      })
    );
    setstatusUpTrStatus1("");
    setIsModalOpen(false);
    return () => {};
  };
  const handleOkE = () => {
    dispatch(
      allapiAction.footerPageTypesUpdate({
        // _id: userId,
        title: userIdE?.title,
        PageType_id: userIdE?._id,
        // active: 0,
        // pageNumber: page,
      })
    );
    setstatusUpTrStatus1("");
    setIsModalOpenE(false);
    return () => {};
  };
  const handleOkd = () => {
    dispatch(
      allapiAction.footerPageListsDelete({
        // _id: userId,
        PageType_id: userId?._id,
        // active: 0,
        // pageNumber: page,
      })
    );
    // setstatusUpTrStatus1("");
    setIsModalOpend(false);
    return () => {};
  };

  const hendleToChangeStatus = (id, status) => {
    dispatch(
      allapiAction.user_inactive({
        _id: id,
        status: status,
        // active: 0,
        pageNumber: page,
      })
    );
    // setuserId("");
    // setIsModalOpen(false);
    return () => {};
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleCanceld = () => {
    setIsModalOpend(false);
  };
  const handleCancelE = () => {
    setIsModalOpenE(false);
  };

  const hendleToStatus = (id, e) => {
    // statusCategory

    dispatch(allapiAction.statusProduct({ productId: id, status: e }));
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {};
  };

  const columns = [
    {
      title: "S.No.",
      dataIndex: "sno",
      key: "sno",
      className: "table_first_col",
      render: (value, item, index) => (page - 1) * 10 + index + 1,
    },
    {
      title: "Page Type",
      dataIndex: "pageType",
      key: "pageType",
      className: "table_action_col",
    },
    {
      title: "Page Name",
      dataIndex: "pageName",
      key: "pageName",
      className: "table_action_col",
    },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   key: "status",
    //   className: "table_action_col",
    // },
    // {
    //   title: "Image",
    //   dataIndex: "image",
    //   key: "image",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         <div style={{ width: "100px" }}>
    //           <img
    //             style={{ width: "100%" }}
    //             src={URL?.API_BASE_URL + item?.featureImage}
    //             alt=""
    //           />
    //         </div>
    //       </>
    //     );
    // //   },
    // },

    // {
    //   title: "Buyer",
    //   dataIndex: "referId",
    //   key: "referId",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         {/* <Tooltip title="Contact"> */}
    //         <span
    //           className="cursor-pointer coustomNevc"
    //           // onClick={() => navigate(`/user-details/${item?.referId}`)}
    //         >
    //           {item?.user_id?.fullName}

    //           {/* <p> {item?.user_id?.referId}</p> */}
    //         </span>
    //       </>
    //     );
    //   },
    // },

    // {
    //   title: "Sponsor Code",
    //   dataIndex: "referByuserId",
    //   key: "referByuserId",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         {/* <Tooltip title="Contact"> */}
    //         <span
    //           className="cursor-pointer coustomNevc"
    //           // onClick={() => navigate(`/user-details/${item?.referId}`)}
    //         >
    //           {item?.user_id?.referByuserId?.fullName}

    //           {/* <p> {item?.user_id?.referByuserId?.referId}</p> */}
    //         </span>
    //       </>
    //     );
    //   },
    // },
    // {
    //   title: "Name",
    //   dataIndex: "title",
    //   key: "title",
    // },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   key: "status",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         <Tooltip title={item?.status}>
    //           <button
    //             className={
    //               item?.status == "Active"
    //                 ? "success-button ml-3"
    //                 : "delete-button ml-3"
    //             }
    //             onClick={() =>
    //               hendleToStatus(
    //                 item?._id,
    //                 item?.status == "Active" ? "Inactive" : "Active"
    //               )
    //             }
    //           >
    //             {item?.status}
    //           </button>
    //         </Tooltip>
    //       </>
    //     );
    //   },
    // },
    // {
    //   title: "Upline Id",
    //   dataIndex: "referBy",
    //   key: "referBy",
    // },
    // {
    //   title: "Number",
    //   dataIndex: "phone",
    //   key: "phone",
    // },
    // {
    //   title: "Password",
    //   dataIndex: "passwordPlan",
    //   key: "passwordPlan",
    // },
    // {
    //   title: "Wallet",
    //   dataIndex: "balance",
    //   key: "balance",
    // },
    // {
    //   title: "Wallet",
    //   dataIndex: "Wallet",
    //   key: "Wallet",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>

    //         <span
    //           className="cursor-pointer"
    //           // onClick={() => navigate(`/users/contact/${item?.id}`)}
    //         >
    //           {item?.walletId?.closeBal}
    //         </span>
    //       </>
    //     );
    //   },
    // },
    // {
    //   title: "Reffer Code",
    //   dataIndex: "referCode",
    //   key: "referCode",
    // },
    // {
    //   title: "Date",
    //   dataIndex: "createdAt",
    //   key: "createdAt",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         {/* <Tooltip title="Contact"> */}
    //         <span
    //           className="cursor-pointer"
    //           // onClick={() => navigate(`/users/contact/${item?.id}`)}
    //         >
    //           {moment(item?.createdAt).format(" DD/MM/YYYY ")}
    //         </span>
    //       </>
    //     );
    //   },
    // },
    //     {
    //       title: "Status",
    //       dataIndex: "status",
    //       key: "status",
    //       className: "table_action_col",
    //       render: (value, item, index) => {

    //         const userRank =
    //         item?.status == '0'
    //           ? 'free'
    //           : item?.status == '1'
    //           ? 'FRANCHISE'
    //           : item?.status == '2'
    //           ? 'BARON'
    //           : item?.status == '3'
    //           ? 'COUNT'
    //           : item?.status == '4'
    //           ? 'MARQUISE'
    //           : item?.status == '4'
    //           ? 'DUKE'
    //           : 'ARCH DUKE'
    //         return (
    //           <>

    // <span
    //               className="cursor-pointer"

    //             >
    //              {userRank}
    //             </span>

    //           </>
    //         );
    //       },
    //     },
    // {
    //   title: "Amount",
    //   dataIndex: "sub_total",
    //   key: "sub_total",
    // },
    // {
    //   title: "Payment Mode",
    //   dataIndex: "payment_mode_name",
    //   key: "payment_mode_name",
    // },
    // {
    //   title: "Delivery status",
    //   dataIndex: "delivery_status",
    //   key: "delivery_status",
    // },
    // {
    //   title: "Order retune",
    //   dataIndex: "orderretune",
    //   key: "orderretune",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         <span
    //           className="cursor-pointer"
    //           // onClick={() => navigate(`/users/contact/${item?.id}`)}
    //         >
    //           {item?.orderretune == "true" ? "Yes" : "No"}
    //         </span>
    //       </>
    //     );
    //   },
    // },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            {/* <Tooltip title="Contact">
              <span
                className="cursor-pointer"
                onClick={() => navigate(`/users/contact/${item?.id}`)}
              >
                <SvgIcon.contacts className="scale-icon edit-icon" />
              </span>
            </Tooltip> */}
            {/* <Tooltip title="delete">
              <button
                className="filter-button ml-3"
                onClick={() => showModalE(item)}
              >
                Edit
              </button>
            </Tooltip> */}
            <Tooltip title="delete">
              <button
                className="filter-button ml-3"
                onClick={() => showModald(item)}
              >
                Delete
              </button>
            </Tooltip>
            {/* <Tooltip title="Conversation">
              <button
                className="filter-button ml-3  mt-5"
                onClick={() => hendleToViewM(item)}
              >
                Conversation
              </button>
            </Tooltip> */}
            {/* <Tooltip title="View">
              <button
                className="filter-button ml-3  mt-5"
                onClick={() => hendleToViewProduct(item)}
              >
                View
              </button>
            </Tooltip> */}
          </>
        );
      },
    },
  ];

  const [searchs, setsearch] = useState("");
  const handleChange = (e) => {
    setsearch(e);
    setPage(1);
    navigate("/ticket?page=" + 1 + "&search=" + e);
    // if (e) {
    //   dispatch(allapiAction.getuserListSearch({ page: 1, search: e }));
    //   return () => {};
    // } else {
    //   dispatch(allapiAction.getuserList(1));

    //   return () => {};
    // }
    // getuserListSearch
    // &user_id=${data?.user_id}&search=${data?.user_id}
  };

  const hendlePahegi = (e) => {
    setPage(e);

    console.log(location);

    navigate("/ticket?page=" + e + "&&search=" + search);

    // if (search) {
    //   dispatch(allapiAction.getuserListSearch({ page: e, search: search }));
    //   return () => {};
    // } else {
    //   dispatch(allapiAction.getuserList(e));

    //   return () => {};
    // }
  };

  return (
    <div>
      <Modal
        title="Add"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div>
          <Row>
            <Col span={24}>Select Page type</Col>
            <Col span={24}>
              {/* <Input
                name="titlw"
                placeholder="Enter a page type"
                value={statusUpTrStatus1}
                onChange={(e) => {
                  setstatusUpTrStatus1(e?.target?.value);
                }}
              /> */}
              <Select
                defaultValue={selectedOption}
                onChange={setSelectedOption}
                options={subList}
              />
            </Col>
            <Col span={24}>Select Page </Col>
            <Col span={24}>
              {/* <Input
                name="titlw"
                placeholder="Enter a page type"
                value={statusUpTrStatus1}
                onChange={(e) => {
                  setstatusUpTrStatus1(e?.target?.value);
                }}
              /> */}
              <Select
                defaultValue={selectedOption1}
                onChange={setSelectedOption1}
                options={subList1}
              />
            </Col>
          </Row>
        </div>
      </Modal>
      {/* edit */}
      <Modal
        title="Edit"
        open={isModalOpenE}
        onOk={handleOkE}
        onCancel={handleCancelE}
      >
        <div>
          <Row>
            <Col span={24}>Title</Col>
            <Col span={24}>
              <Input
                name="title"
                placeholder="Enter a page type"
                value={userIdE?.title}
                onChange={(e) => {
                  hendleTochangeEdit(e);
                }}
              />
            </Col>
          </Row>
        </div>
      </Modal>
      {/* delete */}
      <Modal
        title="Delete"
        open={isModalOpend}
        onOk={handleOkd}
        onCancel={handleCanceld}
      >
        <p>Are you sure delete</p>
      </Modal>

      <Modal
        title="Conversation"
        centered
        open={statusDataBox}
        // onOk={() => hendleToSubmetUpdate(false)}
        onCancel={() => setstatusDataBox(false)}
        width={800}
      >
        <div
          className="card w-100 rounded-0 border-0"
          id="kt_drawer_chat_messenger"
        >
          {/*-begin::Card header */}
          <div
            className="card-header pe-5"
            id="kt_drawer_chat_messenger_header"
          >
            {/*-begin::Title */}
            <div className="card-title">
              {/*-begin::User */}
              <div className="d-flex justify-content-center flex-column me-3">
                {/* <a
                          href='#'
                          className='fs-4 fw-bold text-gray-900 text-hover-primary me-1 mb-2 lh-1'
                        >
                          Brian Cox
                        </a> */}
                {/*-begin::Info */}
                {/* <div className='mb-0 lh-1'>
                          <span className='badge badge-success badge-circle w-10px h-10px me-1'></span>
                          <span className='fs-7 fw-semibold text-muted'>Active</span>
                        </div> */}
                {/*-end::Info */}
              </div>
              {/*-end::User */}
            </div>
            {/*-end::Title */}
            {/*-begin::Card toolbar */}

            {/*-end::Card toolbar */}
          </div>
          {/*-end::Card header */}
          {/*-begin::Card body */}
          <div
            className="card-body chatBoxHeight"
            id="kt_drawer_chat_messenger_body"
          >
            {/*-begin::Messages */}
            <div
              className="scroll-y me-n5 pe-5 chatBoxHeight"
              data-kt-element="messages"
              data-kt-scroll="true"
              data-kt-scroll-activate="true"
              data-kt-scroll-height="auto"
              data-kt-scroll-dependencies="#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer"
              data-kt-scroll-wrappers="#kt_drawer_chat_messenger_body"
              data-kt-scroll-offset="0px"
              style={{ height: "300px" }}
              ref={containerRef}
            >
              {get_message_list &&
                get_message_list?.map((data, i) => {
                  const userId = userData?.user_id?._id;

                  const state =
                    userId == data?.user_id?._id ? "info" : "primary";

                  return userId !== data?.user_id?._id ? (
                    <div className="d-flex justify-content-end mb-10">
                      <div className="d-flex flex-column align-items-end">
                        <div className="d-flex align-items-center mb-2">
                          <div className="me-3">
                            {/* <span className='text-muted fs-7 mb-1'>5 mins</span> */}
                            <a
                              href="#"
                              className="fs-5 fw-bold text-gray-900 text-hover-primary ms-1"
                            >
                              You
                            </a>
                          </div>

                          <div className="symbol symbol-35px symbol-circle">
                            {/* <img alt='Pic' src='assets/media/avatars/300-1.jpg' /> */}
                          </div>
                        </div>

                        <div
                          className="p-5 rounded bg-light-primary text-dark fw-semibold mw-lg-400px text-end"
                          data-kt-element="message-text"
                        >
                          {data?.message}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="d-flex justify-content-start mb-10">
                      <div className="d-flex flex-column align-items-start">
                        <div className="d-flex align-items-center mb-2">
                          <div className="symbol symbol-35px symbol-circle">
                            {/* <img alt='Pic' src='assets/media/avatars/300-25.jpg' /> */}
                          </div>

                          <div className="ms-3">
                            <a
                              href="#"
                              className="fs-5 fw-bold text-gray-900 text-hover-primary me-1"
                            >
                              {data?.user_id?.fullName}
                            </a>
                            {/* <span className='text-muted fs-7 mb-1'>2 mins</span> */}
                          </div>
                        </div>

                        <div
                          className="p-5 rounded bg-light-info text-dark fw-semibold mw-lg-400px text-start"
                          data-kt-element="message-text"
                        >
                          {data?.message}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
            {/*-end::Messages */}
          </div>
          {/*-end::Card body */}
          {/*-begin::Card footer */}
          <div
            className="card-footer pt-24"
            id="kt_drawer_chat_messenger_footer"
          >
            {/*-begin::Input */}
            <textarea
              value={enterMessage}
              className="form-control form-control-flush mb-3"
              rows={1}
              data-kt-element="input"
              placeholder="Type a message"
              onKeyDown={onEnterPress}
              onChange={(e) => {
                setenterMessage(e.target.value);
              }}
            ></textarea>
            {/*-end::Input */}
            {/*-begin:Toolbar */}
            <div className="d-flex flex-stack">
              {/*-begin::Actions */}
              <div className="d-flex align-items-center me-2">
                {/* <button
                          className='btn btn-sm btn-icon btn-active-light-primary me-1'
                          type='button'
                          data-bs-toggle='tooltip'
                          aria-label='Coming soon'
                          data-bs-original-title='Coming soon'
                          data-kt-initialized='1'
                        >
                          <i className='bi bi-paperclip fs-3'></i>
                        </button>
                        <button
                          className='btn btn-sm btn-icon btn-active-light-primary me-1'
                          type='button'
                          data-bs-toggle='tooltip'
                          aria-label='Coming soon'
                          data-bs-original-title='Coming soon'
                          data-kt-initialized='1'
                        >
                          <i className='bi bi-upload fs-3'></i>
                        </button> */}
              </div>
              {/*-end::Actions */}
              {/*-begin::Send */}
              <button
                className="btn btn-primary"
                type="button"
                onClick={() => {
                  createMessage();
                }}
                data-kt-element="send"
              >
                Send
              </button>
              {/*-end::Send */}
            </div>
            {/*-end::Toolbar */}
          </div>
          {/*-end::Card footer */}
        </div>
      </Modal>
      <Modal
        title="Order Details"
        centered
        open={statusDataBoxView}
        onOk={() => setstatusDataBoxView(false)}
        onCancel={() => setstatusDataBoxView(false)}
        width={1200}
      >
        <div className="">
          <div>
            {/* <h6>Name</h6>
                    <p>{roleID?.name}</p> */}
            {/* <div className='productDetailsImg'>
                      <img src={URL.API_BASE_URL + roleID?.featureImage} alt='' />
                    </div> */}
            <div className="row">
              <div className="col-4">
                <h6> id : -</h6>
              </div>
              <div className="col-8">
                <p>{order_details?._id}</p>
              </div>

              <div className="col-4">
                <h6>payment mode name : -</h6>
              </div>

              <div className="col-8">
                <p>{order_details?.payment_mode_name}</p>
              </div>

              <div className="col-4">
                <h6>sub total : -</h6>
              </div>

              <div className="col-8">
                <p>{order_details?.sub_total}</p>
              </div>
            </div>
            <hr />
            <h3>user Details</h3>
            <div className="row">
              <div className="col-4">
                <h6> user id : -</h6>
              </div>
              <div className="col-8">
                <p>{order_details?.user_id?._id}</p>
              </div>

              <div className="col-4">
                <h6>user reffer id : -</h6>
              </div>

              <div className="col-8">
                <p>{order_details?.user_id?.referId}</p>
              </div>

              <div className="col-4">
                <h6>full name : -</h6>
              </div>

              <div className="col-8">
                <p>{order_details?.user_id?.fullName}</p>
              </div>
              <div className="col-4">
                <h6>Email : -</h6>
              </div>

              <div className="col-8">
                <p>{order_details?.user_id?.email}</p>
              </div>
            </div>
            <hr />
            <h3>shipping address</h3>
            <div className="row">
              <div className="col-4">
                <h6> name : -</h6>
              </div>
              <div className="col-8">
                <p>{order_details?.address_id?.name}</p>
              </div>

              <div className="col-4">
                <h6>email : -</h6>
              </div>

              <div className="col-8">
                <p>{order_details?.address_id?.email}</p>
              </div>

              <div className="col-4">
                <h6>phone : -</h6>
              </div>

              <div className="col-8">
                <p>{order_details?.address_id?.phone}</p>
              </div>
              <div className="col-4">
                <h6>address : -</h6>
              </div>

              <div className="col-8">
                <p>{order_details?.address_id?.address}</p>
              </div>
              <div className="col-4">
                <h6>city : -</h6>
              </div>
              <div className="col-8">
                <p>{order_details?.address_id?.city}</p>
              </div>
              <div className="col-4">
                <h6>state : -</h6>
              </div>
              <div className="col-8">
                <p>{order_details?.address_id?.state}</p>
              </div>
              <div className="col-4">
                <h6>pincode : -</h6>
              </div>

              <div className="col-8">
                <p>{order_details?.address_id?.pincode}</p>
              </div>
            </div>

            <hr />
            <h3>Order Product</h3>
            {order_details?.orderproductsList &&
              order_details?.orderproductsList?.map((data, i) => {
                return (
                  <div>
                    <div className="row">
                      <div className="col-6">
                        <h3>sub order id</h3>
                      </div>
                      <div className="col-6">
                        <p>{data?.sub_order_id}</p>
                      </div>
                      <div className="col-6">
                        <h3>name</h3>
                      </div>
                      <div className="col-6">
                        <p>{data?.product_names}</p>
                      </div>
                      <div className="col-6">
                        <h3>Color</h3>
                      </div>
                      <div className="col-6">
                        <p>{data?.colorId?.color}</p>
                      </div>
                      <div className="col-6">
                        <h3>size</h3>
                      </div>
                      <div className="col-6">
                        <p>{data?.sizeId?.size}</p>
                      </div>

                      <div className="col-6">
                        <h3>product price</h3>
                      </div>
                      <div className="col-6">
                        <p>{data?.product_price}</p>
                      </div>
                      <div className="col-6">
                        <h3>item Count</h3>
                      </div>
                      <div className="col-6">
                        <p>{data?.itme_count}</p>
                      </div>
                      <div className="col-6">
                        <h3>sub total</h3>
                      </div>
                      <div className="col-6">
                        <p>{data?.sub_total}</p>
                      </div>
                      <div className="col-6">
                        <h3>feture image</h3>
                      </div>
                      <div className="col-6">
                        <div style={{ width: "100px" }}>
                          <img
                            src={URL.API_BASE_URL + data?.colorId?.featureImage}
                            alt=""
                            style={{ width: "100%" }}
                          />
                        </div>
                        {/* <p>  {data?.sub_total}</p> */}
                      </div>
                    </div>

                    <hr />
                  </div>
                );
              })}

            {/* <h6>product Image</h6> */}
            {/* <div className='row'>
                      {roleID?.images &&
                        roleID?.images?.map((data: any, i: any) => {
                          console.log(data)

                          return (
                            <div className='col-3 proMultiImg'>
                              <img src={URL.API_BASE_URL + data?.img} alt='' />
                            </div>
                          )
                        })}
                    </div> */}
          </div>
        </div>
      </Modal>
      <Modal
        title="Return order"
        centered
        open={statusDataBoxReturn}
        onOk={() => hendleSubmitReturn(false)}
        onCancel={() => setstatusDataBoxReturn(false)}
        width={500}
      >
        <div>
          <Row>
            <Col span={24}>Description</Col>
            <Col span={24}>
              <Input
                name="description"
                placeholder="enter description..."
                value={statusUpTrStatus}
                onChange={(e) => {
                  setstatusUpTrStatus(e?.target?.value);
                }}
              />
            </Col>
          </Row>
        </div>
      </Modal>

      <div className="flex">
        <div>
          {/* <HeadingBack
            title={"Ticket"}
            subHeading={`Total ${get_ticket_list?.totalDocs || 0} Ticket`}
          /> */}

          <h3>Footer Link</h3>
        </div>
        <Col md={5} sm={5} xs={12} className="pl-5  ml-5">
          {/* <Input
            type="text"
            autoComplete="new-off"
            className="form-control"
            onChange={(e) => handleChange(e.target.value)}
            placeholder="Search..."
            // value={values?.TestCode}
          /> */}
          {/* <AsyncPaginate
            // key={managerKey}
            value={seleceteManger}
            loadOptions={loadOptionsgetManager}
            onChange={setseleceteManger}
            additional={{
              page: 1,
            }}
          /> */}
        </Col>

        <div className="flex1 flex justify-content-end align-items-center">
          <Tooltip title="Add">
            <span className="jop_btn" onClick={() => setIsModalOpen(true)}>
              Add
              <SvgIcon.Add width={40} height={40} className="cursor-pointer" />
            </span>
          </Tooltip>
        </div>
      </div>

      <div className="tableResponsive mt-10">
        <Table
          className={"table antd_table"}
          dataSource={listdata}
          columns={columns}
          pagination={{
            current: parseInt(page),
            pageSize: 10,
            onChange(current, pageSize) {
              hendlePahegi(current);
            },
            hideOnSinglePage: true,
            total: get_ticket_list?.totalDocs ? get_ticket_list?.totalDocs : 0,
          }}
        />
      </div>
    </div>
  );
}

export default Pageconect;

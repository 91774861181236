import React, { useEffect, useState } from "react";
import { Col, Input, Row, Table, Tooltip } from "antd";
import HeadingBack from "../component/HeadingBack";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import SvgIcon from "../const/SvgIcon";
import { allapiAction } from "../../Redux/common/action";
import moment from "moment";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Button, Modal, Space } from "antd";
import { URL } from "../../Redux/common/url";

function Product() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const pagev = searchParams.get("page");
  const searchData = searchParams.get("search");

  const { confirm } = Modal;

  const page = pagev == null ? 1 : pagev;
  const search = searchData == null ? "" : searchData;

  const get_product_list = useSelector((state) =>
    state?.allapi?.get_product_list ? state?.allapi?.get_product_list : {}
  );

  console.log("pg render");
  const [pages, setPage] = useState(1);

  const listdata = get_product_list?.docs ? get_product_list?.docs : [];

  useEffect(() => {
    if (search) {
      dispatch(allapiAction.searchProduct({ page: page, key: search }));
    } else {
      dispatch(allapiAction.getProductList(page));
    }

    return () => {};
  }, [location]);

  const showDeleteConfirm = (e) => {
    confirm({
      title: "Are you sure delete ?",
      icon: <ExclamationCircleFilled />,
      content: "Some descriptions",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        hendleDelete(e);
        console.log("OK");
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const hendleDelete = (e) => {
    console.log(e);
    // deleteProduct
    // setShowDelete(false);
    dispatch(allapiAction.deleteProduct({ productId: e, pageNo: page }));
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {};
  };
  const dataSource = [
    {
      key: "1",
      name: "Mike",
      age: 32,
      address: "10 Downing Street",
    },
    {
      key: "2",
      name: "John",
      age: 42,
      address: "10 Downing Street",
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userId, setuserId] = useState("");

  const [statusData, setstatusData] = useState({});
  const [userData, setuserData] = useState({});
  const [statusDataBox, setstatusDataBox] = useState(false);

  const hendleToViewM = (e) => {
    setstatusDataBox(true);
    setuserData(e);
  };

  const hendleTochangedata = (e) => {
    const { name, value } = e.target;

    setstatusData({ ...statusData, [name]: value });
  };

  const hendleToSubmetUpdate = () => {
    dispatch(
      allapiAction.addFoundByAdmin({
        id: userData?._id,
        amount: statusData?.amount,

        pageNumber: page,
      })
    );

    setstatusDataBox(false);
  };

  const showModal = (e) => {
    console.log(e);
    setuserId(e);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    dispatch(
      allapiAction.user_inactive({
        _id: userId,
        status: userId,
        // active: 0,
        pageNumber: page,
      })
    );
    setuserId("");
    setIsModalOpen(false);
    return () => {};
  };

  const hendleToChangeStatus = (id, status) => {
    dispatch(
      allapiAction.user_inactive({
        _id: id,
        status: status,
        // active: 0,
        pageNumber: page,
      })
    );
    // setuserId("");
    // setIsModalOpen(false);
    return () => {};
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const hendleToStatus = (id, e, type) => {
    // statusCategory

    dispatch(allapiAction.statusProduct({ productId: id, status: e, type }));
    // dispatch(adminAction.getRoleList('dhsg'))
    return () => {};
  };

  const columns = [
    {
      title: "S.No.",
      dataIndex: "sno",
      key: "sno",
      className: "table_first_col",
      render: (value, item, index) => (page - 1) * 10 + index + 1,
    },
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            <div style={{ width: "50px" }}>
              <img
                style={{ width: "100%" }}
                src={URL?.API_BASE_URL + item?.featureImage}
                alt=""
              />
            </div>
          </>
        );
      },
    },
    // {
    //   title: "User Id",
    //   dataIndex: "referId",
    //   key: "referId",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         {/* <Tooltip title="Contact"> */}
    //         <span
    //           className="cursor-pointer coustomNevc"
    //           // onClick={() => navigate(`/user-details/${item?.referId}`)}
    //         >
    //           {item?.referId}
    //         </span>
    //       </>
    //     );
    //   },
    // },

    {
      title: "Name",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "New launch",
      dataIndex: "newlaunch",
      key: "newlaunch",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            <Tooltip title={item?.newlaunch}>
              <button
                className={
                  item?.newlaunch == "Active"
                    ? "success-button ml-3"
                    : "delete-button2 ml-3"
                }
                onClick={() =>
                  hendleToStatus(
                    item?._id,
                    item?.newlaunch == "Active" ? "Inactive" : "Active",
                    "newlaunch"
                  )
                }
              >
                {item?.newlaunch}
              </button>
            </Tooltip>
          </>
        );
      },
    },
    {
      title: "Last chance",
      dataIndex: "lastchance",
      key: "lastchance",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            <Tooltip title={item?.lastchance}>
              <button
                className={
                  item?.lastchance == "Active"
                    ? "success-button ml-3"
                    : "delete-button2 ml-3"
                }
                onClick={() =>
                  hendleToStatus(
                    item?._id,
                    item?.lastchance == "Active" ? "Inactive" : "Active",
                    "lastchance"
                  )
                }
              >
                {item?.lastchance}
              </button>
            </Tooltip>
          </>
        );
      },
    },
    {
      title: "featured",
      dataIndex: "featured",
      key: "featured",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            <Tooltip title={item?.featured}>
              <button
                className={
                  item?.featured == "Active"
                    ? "success-button ml-3"
                    : "delete-button2 ml-3"
                }
                onClick={() =>
                  hendleToStatus(
                    item?._id,
                    item?.featured == "Active" ? "Inactive" : "Active",
                    "featured"
                  )
                }
              >
                {item?.featured}
              </button>
            </Tooltip>
          </>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      className: "table_action_col",
      render: (value, item, index) => {
        return (
          <>
            <Tooltip title={item?.status}>
              <button
                className={
                  item?.status == "Active"
                    ? "success-button ml-3"
                    : "delete-button2 ml-3"
                }
                onClick={() =>
                  hendleToStatus(
                    item?._id,
                    item?.status == "Active" ? "Inactive" : "Active",
                    "status"
                  )
                }
              >
                {item?.status}
              </button>
            </Tooltip>
          </>
        );
      },
    },
    // {
    //   title: "Upline Id",
    //   dataIndex: "referBy",
    //   key: "referBy",
    // },
    // {
    //   title: "Number",
    //   dataIndex: "phone",
    //   key: "phone",
    // },
    // {
    //   title: "Password",
    //   dataIndex: "passwordPlan",
    //   key: "passwordPlan",
    // },
    // {
    //   title: "Wallet",
    //   dataIndex: "balance",
    //   key: "balance",
    // },
    // {
    //   title: "Wallet",
    //   dataIndex: "Wallet",
    //   key: "Wallet",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>

    //         <span
    //           className="cursor-pointer"
    //           // onClick={() => navigate(`/users/contact/${item?.id}`)}
    //         >
    //           {item?.walletId?.closeBal}
    //         </span>
    //       </>
    //     );
    //   },
    // },
    // {
    //   title: "Reffer Code",
    //   dataIndex: "referCode",
    //   key: "referCode",
    // },
    // {
    //   title: "Join Date",
    //   dataIndex: "Join Date",
    //   key: "Join Date",
    //   className: "table_action_col",
    //   render: (value, item, index) => {
    //     return (
    //       <>
    //         {/* <Tooltip title="Contact"> */}
    //         <span
    //           className="cursor-pointer"
    //           // onClick={() => navigate(`/users/contact/${item?.id}`)}
    //         >
    //           {moment(item?.createdAt).format(" DD/MM/YYYY ")}
    //         </span>
    //       </>
    //     );
    //   },
    // },
    //     {
    //       title: "Status",
    //       dataIndex: "status",
    //       key: "status",
    //       className: "table_action_col",
    //       render: (value, item, index) => {

    //         const userRank =
    //         item?.status == '0'
    //           ? 'free'
    //           : item?.status == '1'
    //           ? 'FRANCHISE'
    //           : item?.status == '2'
    //           ? 'BARON'
    //           : item?.status == '3'
    //           ? 'COUNT'
    //           : item?.status == '4'
    //           ? 'MARQUISE'
    //           : item?.status == '4'
    //           ? 'DUKE'
    //           : 'ARCH DUKE'
    //         return (
    //           <>

    // <span
    //               className="cursor-pointer"

    //             >
    //              {userRank}
    //             </span>

    //           </>
    //         );
    //       },
    //     },
    // {
    //   title: "Student Mobile",
    //   dataIndex: "student_mobile",
    //   key: "student_mobile",
    // },
    // {
    //   title: "Parent Name",
    //   dataIndex: "parent_name",
    //   key: "parent_name",
    // },
    // {
    //   title: "Parent Mobile",
    //   dataIndex: "parent_mobile",
    //   key: "parent_mobile",
    // },
    // {
    //   title: "Address",
    //   dataIndex: "address",
    //   key: "address",
    // },
    {
      title: "Actions",
      dataIndex: "action",
      key: "action",
      className: "table_first_colmm",
      render: (value, item, index) => {
        return (
          <>
            <Tooltip title="Edit">
              <span
                className="cursor-pointer  delete-button6 mr-3"
                onClick={() => navigate(`/update-product/${item?.pro_sulg}`)}
              >
                <SvgIcon.Edit className="scale-icon1" /> Edit
              </span>
            </Tooltip>

            {/* <Tooltip title="Update">
              <span
                className="cursor-pointer"
                onClick={() => navigate(`/update-product/${item?.pro_sulg}`)}
              >
                <SvgIcon.Pencil className="scale-icon edit-icon" />
              </span>
            </Tooltip> */}
            <Tooltip title="Delete">
              <span
                className="cursor-pointer delete-button5  "
                onClick={() => {
                  showDeleteConfirm(item?._id);
                }}
              >
                <SvgIcon.deletebtn className="scale-icon1 " fill="#ffffff" />{" "}
                Delete
              </span>
            </Tooltip>
            {/* <Tooltip title="Delete">
              <span
                className="cursor-pointer"
                onClick={() => {
                  showDeleteConfirm(item?._id);
                }}
              >
                <SvgIcon.deletebtn className="scale-icon edit-icon" />
              </span>
            </Tooltip> */}
          </>
        );
      },
    },
  ];

  const [searchs, setsearch] = useState("");
  const handleChange = (e) => {
    setsearch(e);
    setPage(1);
    navigate("/product?page=" + 1 + "&search=" + e);
    // if (e) {
    //   dispatch(allapiAction.getuserListSearch({ page: 1, search: e }));
    //   return () => {};
    // } else {
    //   dispatch(allapiAction.getuserList(1));

    //   return () => {};
    // }
    // getuserListSearch
    // &user_id=${data?.user_id}&search=${data?.user_id}
  };

  const hendlePahegi = (e) => {
    setPage(e);

    console.log(location);

    navigate("/product?page=" + e + "&&search=" + search);

    // if (search) {
    //   dispatch(allapiAction.getuserListSearch({ page: e, search: search }));
    //   return () => {};
    // } else {
    //   dispatch(allapiAction.getuserList(e));

    //   return () => {};
    // }
  };

  return (
    <div>
      <Modal
        title="delete User"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Are you sure delete</p>
      </Modal>

      <Modal
        title="Add Amount"
        centered
        open={statusDataBox}
        onOk={() => hendleToSubmetUpdate(false)}
        onCancel={() => setstatusDataBox(false)}
        width={500}
      >
        <div>
          <Row>
            <Col span={24}>Amount</Col>
            <Col span={24}>
              <Input
                name="amount"
                placeholder="enter amount"
                value={statusData?.amount}
                onChange={(e) => {
                  hendleTochangedata(e);
                }}
              />
            </Col>
          </Row>
        </div>
      </Modal>

      <div className="flex">
        <div>
          <HeadingBack
            title={"Product"}
            subHeading={`Total ${get_product_list?.totalDocs || 0} Product`}
          />
        </div>
        <Col md={5} sm={5} xs={12} className="pl-5  ml-5">
          <Input
            type="text"
            autoComplete="new-off"
            className="form-control"
            onChange={(e) => handleChange(e.target.value)}
            placeholder="Search..."
            // value={values?.TestCode}
          />
          {/* <AsyncPaginate
            // key={managerKey}
            value={seleceteManger}
            loadOptions={loadOptionsgetManager}
            onChange={setseleceteManger}
            additional={{
              page: 1,
            }}
          /> */}
        </Col>

        <div className="flex1 flex justify-content-end align-items-center">
          <Tooltip title="Add">
            <span className="jop_btn" onClick={() => navigate("/add-product")}>
              Add
              <SvgIcon.Add width={40} height={40} className="cursor-pointer" />
            </span>
          </Tooltip>
        </div>
      </div>

      <div className="tableResponsive mt-10">
        <Table
          className={"table antd_table"}
          dataSource={listdata}
          columns={columns}
          pagination={{
            current: parseInt(page),
            pageSize: 10,
            onChange(current, pageSize) {
              hendlePahegi(current);
            },
            hideOnSinglePage: true,
            total: get_product_list?.totalDocs
              ? get_product_list?.totalDocs
              : 0,
          }}
        />
      </div>
    </div>
  );
}

export default Product;

import axios from "axios";
import { toast } from "react-toastify";
import { URL } from "../common/url";
import api from "../common/api";
import {
  LOADING,
  CREATE_CATEGORIES,
  GET_CATEGORIES,
  GET_SUB_SUB_CATEGORIES,
  GET_SELLER_PRODUCT_LIST,
  GET_SUB_SUB_CATE_LIST,
  OTP_BOX,
  GET_BUSS_PRO,
  GET_PACKAGE_LIST,
  GET_SELLER_TANSACTION,
  GET_PROFILE,
  GET_SELLER_TICKET_LIST,
  GET_MESSAGE_LISTS,
  GET_INVOICE,
  GET_PRODUCT_DETAILS,
  GET_SELLER_PRODUCT_LIST_PUBLICE,
  GET_TAGS_PRODUCT_LIST_PUBLICE,
  GET_SELLER_PRODUCT_LIST_PUBLICE_BY_CATEGORY,
  GET_SELLER_PROFILE,
  GET_SUB_SUB_CATE_LIST_FIL,
  GET_PAGES,
  GET_EMAIL_QU,
  GET_WHATSAPP_QU,
  PAGE_DETAILS,
  SEARCH_TAGS,
  SEARCH_PRODUCT_PUB,
  GET_FOOTER_DATA_ALL,
  GET_HOME_ALL_PRO,
  TOP_VIEW_ALL_TYPE,
  SELLER_LIST,
  DESHBOARD_STATE,
  SLIDER_LIST,
  BLOG_LIST,
  BLOG_DETAILS,
  GET_EXPIRE_DATE,
  HOME_POPS,
  FAQS_LIST,
  USERS_LIST,
  USERS_CONTACT_LIST,
  CATEGORY_LIST,
  QUIZ_LIST,
  QUESTION_LIST,
  DESHBOARD,
  BATTEL_LIST_ADMIN,
  WINNING_REQ,
  WITHDRAWAL_LIST,
  KYS_LIST,
  GET_TRANSACTION_LISTS,
  USER_DETAILS,
  BATTEL_DETAILS,
  GET_SETTING,
  GET_SUB_CATEGORY,
  GET_MAIN_ALL_CATEGORY,
  GET_SUB_CATEGORY_ALL_LISTS,
  GET_PRODUCT_LISTS,
  ORDER_LIST,
  GET_INVOICE_DETAILS,
  ORDER_DETAILS,
  MQ_CHALENGE,
  DUQE_CHALENGE,
  MONTH_REPORT,
  GET_SUB_SUB_CATEGORY_LISTS,
  MONTH_REPORT_2,
  BRAND_LIST,
  BRAND_LIST_ALL,
  BLOG,
  GET_SUBSCRIPTION,
  FOOTER_PAGE_TYPE,
  FOOTER_PAGE_LIST,
  FOOTER_PAGE_LIST_SELECT,
  TOP_THREE,
  GPG_LIST,
  GET_PRODUCT_LISTS_STOCK,
  LEADER_BOARD_C_M,
} from "../common/constant";
// const Token = require("../../Utils/Auth/Token");

const loading = (data) => {
  return { type: LOADING, payload: data };
};
const createcategoriesAction = (data) => {
  return { type: CREATE_CATEGORIES, payload: data };
};
const getcategoriesAction = (data) => {
  return { type: GET_CATEGORIES, payload: data };
};
const getsubsusbcategoriesAction = (data) => {
  return { type: GET_SUB_SUB_CATEGORIES, payload: data };
};
const getsubsusbcategoriesfillAction = (data) => {
  return { type: GET_SUB_SUB_CATE_LIST_FIL, payload: data };
};
const getsellerProductListAction = (data) => {
  return { type: GET_SELLER_PRODUCT_LIST, payload: data };
};
const getsellerProductListPublicAction = (data) => {
  return { type: GET_SELLER_PRODUCT_LIST_PUBLICE, payload: data };
};
const gettagsProductListPublicAction = (data) => {
  return { type: GET_TAGS_PRODUCT_LIST_PUBLICE, payload: data };
};
const getsellerProductListPublicbycategoryAction = (data) => {
  return { type: GET_SELLER_PRODUCT_LIST_PUBLICE_BY_CATEGORY, payload: data };
};
const getsubsusbcateListAction = (data) => {
  return { type: GET_SUB_SUB_CATE_LIST, payload: data };
};
const otpBoxAction = (data) => {
  return { type: OTP_BOX, payload: data };
};
// const otpBoxForgotAction = (data) => {
//   return { type: OTP_BOX_FORGOT, payload: data };
// };
const getBussnissProfileAction = (data) => {
  return { type: GET_BUSS_PRO, payload: data };
};
const getpackageListAction = (data) => {
  return { type: GET_PACKAGE_LIST, payload: data };
};

const gettansactionListAction = (data) => {
  return { type: GET_SELLER_TANSACTION, payload: data };
};
const getprofileAction = (data) => {
  return { type: GET_PROFILE, payload: data };
};
const getmessageListAction = (data) => {
  return { type: GET_MESSAGE_LISTS, payload: data };
};
const getinvoiceAction = (data) => {
  return { type: GET_INVOICE, payload: data };
};
const getproductDetailsAction = (data) => {
  return { type: GET_PRODUCT_DETAILS, payload: data };
};
const getSellerDetailsAction = (data) => {
  return { type: GET_SELLER_PROFILE, payload: data };
};
const getPagesAction = (data) => {
  return { type: GET_PAGES, payload: data };
};
const getemailQuAction = (data) => {
  return { type: GET_EMAIL_QU, payload: data };
};
const getWhatsappQuAction = (data) => {
  return { type: GET_WHATSAPP_QU, payload: data };
};
const getpageDetailsAction = (data) => {
  return { type: PAGE_DETAILS, payload: data };
};
const getsearchtagssAction = (data) => {
  return { type: SEARCH_TAGS, payload: data };
};
const getsearchProductPubAction = (data) => {
  return { type: SEARCH_PRODUCT_PUB, payload: data };
};
const getfooterAllDataPubAction = (data) => {
  return { type: GET_FOOTER_DATA_ALL, payload: data };
};
const gethomeAllProAction = (data) => {
  return { type: GET_HOME_ALL_PRO, payload: data };
};
const topViewAllTypeAction = (data) => {
  return { type: TOP_VIEW_ALL_TYPE, payload: data };
};
const getSellerListPublicAction = (data) => {
  return { type: SELLER_LIST, payload: data };
};
const sellerDashboardStateAction = (data) => {
  return { type: DESHBOARD_STATE, payload: data };
};
const sliderListAction = (data) => {
  return { type: SLIDER_LIST, payload: data };
};
const blogListAction = (data) => {
  return { type: BLOG_LIST, payload: data };
};
const blogDetailsAction = (data) => {
  return { type: BLOG_DETAILS, payload: data };
};
const getExpireDateAction = (data) => {
  return { type: GET_EXPIRE_DATE, payload: data };
};
const homepopAction = (data) => {
  return { type: HOME_POPS, payload: data };
};

// new
const userListAction = (data) => {
  return { type: USERS_LIST, payload: data };
};
const userContactListAction = (data) => {
  return { type: USERS_CONTACT_LIST, payload: data };
};

const faqsLsitAction = (data) => {
  return { type: FAQS_LIST, payload: data };
};
const categoryLsitAction = (data) => {
  return { type: CATEGORY_LIST, payload: data };
};
const questionLsitAction = (data) => {
  return { type: QUESTION_LIST, payload: data };
};
const deshboardAction = (data) => {
  return { type: DESHBOARD, payload: data };
};
const quizLsitAction = (data) => {
  return { type: QUIZ_LIST, payload: data };
};
const battelListAdminAction = (data) => {
  return { type: BATTEL_LIST_ADMIN, payload: data };
};
const winningReqAction = (data) => {
  return { type: WINNING_REQ, payload: data };
};
const withdrawalListAction = (data) => {
  return { type: WITHDRAWAL_LIST, payload: data };
};
const getkycLsitAction = (data) => {
  return { type: KYS_LIST, payload: data };
};
const getstransationListAction = (data) => {
  return { type: GET_TRANSACTION_LISTS, payload: data };
};
const userDetailsAction = (data) => {
  return { type: USER_DETAILS, payload: data };
};
const battelDetailsAction = (data) => {
  return { type: BATTEL_DETAILS, payload: data };
};
const getsettingListAction = (data) => {
  return { type: GET_SETTING, payload: data };
};
const getsubCategoryListAction = (data) => {
  return { type: GET_SUB_CATEGORY, payload: data };
};
const getcateAllListAction = (data) => {
  return { type: GET_MAIN_ALL_CATEGORY, payload: data };
};

const getssubcateAllListAction = (data) => {
  return { type: GET_SUB_CATEGORY_ALL_LISTS, payload: data };
};
const getproductListAction = (data) => {
  return { type: GET_PRODUCT_LISTS, payload: data };
};
const getproductListstockAction = (data) => {
  return { type: GET_PRODUCT_LISTS_STOCK, payload: data };
};

const getinvoiceDetailsAction = (data) => {
  return { type: GET_INVOICE_DETAILS, payload: data };
};
const orderListAction = (data) => {
  return { type: ORDER_LIST, payload: data };
};
const gpgListAction = (data) => {
  return { type: GPG_LIST, payload: data };
};

const orderDetailsAction = (data) => {
  return { type: ORDER_DETAILS, payload: data };
};

const mqChalengeAction = (data) => {
  return { type: MQ_CHALENGE, payload: data };
};

const duqeChalengeAction = (data) => {
  return { type: DUQE_CHALENGE, payload: data };
};

const monthreportAction = (data) => {
  return { type: MONTH_REPORT, payload: data };
};

const getticketListAction = (data) => {
  return { type: GET_SELLER_TICKET_LIST, payload: data };
};

const getsubsubcategoryListAction = (data) => {
  return { type: GET_SUB_SUB_CATEGORY_LISTS, payload: data };
};
const getmonthreports2ListAction = (data) => {
  return { type: MONTH_REPORT_2, payload: data };
};
const getfaqListAction = (data) => {
  return { type: FAQS_LIST, payload: data };
};
const getbrandListAction = (data) => {
  return { type: BRAND_LIST, payload: data };
};
const getbrandallListAction = (data) => {
  return { type: BRAND_LIST_ALL, payload: data };
};
const blogAction = (data) => {
  return { type: BLOG, payload: data };
};
const getsubscrptionAction = (data) => {
  return { type: GET_SUBSCRIPTION, payload: data };
};
const footerPageTypeAction = (data) => {
  return { type: FOOTER_PAGE_TYPE, payload: data };
};
const FooterPageListAction = (data) => {
  return { type: FOOTER_PAGE_LIST, payload: data };
};

const FooterPageListSelectAction = (data) => {
  return { type: FOOTER_PAGE_LIST_SELECT, payload: data };
};

const gettopthreeAction = (data) => {
  return { type: TOP_THREE, payload: data };
};
const getleaderBoardCtMqAction = (data) => {
  return { type: LEADER_BOARD_C_M, payload: data };
};

export const getPagesm = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getPagesm}/${data}`, config);

      dispatch(getPagesAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const deletePage = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.deletePage}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        toast.success(response?.data?.message);

        dispatch(getPagesAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const updatePageAdmin = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.updatePageAdmin}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        toast.success(response?.data?.message);

        dispatch(getPagesAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const createAbout = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.createAbout}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        toast.success(response?.data?.message);

        dispatch(getPagesAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const footerPageListsDelete = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.footerPageListsDelete}`,
        data,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        toast.success(response?.data?.message);

        dispatch(FooterPageListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getpageListfooter = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getpageListfooter}`, config);

      dispatch(FooterPageListSelectAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const createFooterPageList = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.createFooterPageList}`,
        data,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        toast.success(response?.data?.message);

        dispatch(FooterPageListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const footerPagepageListet = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.footerPagepageListet}`, config);

      dispatch(FooterPageListAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const footerPageTypesDelete = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.footerPageTypesDelete}`,
        data,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(footerPageTypeAction(response?.data?.data));
      } else {
        // dispatch(getticketListAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const footerPageTypesUpdate = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.footerPageTypesUpdate}`,
        data,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(footerPageTypeAction(response?.data?.data));
      } else {
        // dispatch(getticketListAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const createFooterPageType = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.createFooterPageType}`,
        data,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(footerPageTypeAction(response?.data?.data));
      } else {
        // dispatch(getticketListAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
// export const footerPageTypes = (data, history) => {
//   return async (dispatch) => {
//     try {
//       const token = await localStorage.getItem("admin_token");

//       const config = {
//         headers: {
//           Authorization: "Bearer " + token,
//         },
//       };
//       // dispatch(loading(true));
//       const response = await api.get(
//         `${URL.footerPageTypes}`,
//         // data,
//         config
//       );
//       // dispatch(getsellerProductListAction(response?.data?.data));
//       if (response?.data?.success) {
//         // toast.success(response?.data?.message);

//         dispatch(footerPageTypeAction(response?.data?.data));
//       } else {
//         // dispatch(getticketListAction(response?.data?.data));
//         toast.error(response?.data?.message);
//       }

//       dispatch(loading(false));
//     } catch (error) {
//       dispatch(loading(false));
//       // toast.error(error?.response?.data?.message);
//     }
//   };
// };
export const createBlogs = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.createBlogs}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        toast.success(response?.data?.message);

        dispatch(blogAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const footerPageTypes = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.footerPageTypes}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(footerPageTypeAction(response?.data?.data));
      } else {
        // dispatch(getticketListAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const updateRegisterLogo = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.updateRegisterLogo}`,
        data,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getinvoiceDetailsAction(response?.data?.data));
      } else {
        // dispatch(getticketListAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const updateInvoiceProfile = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.updateInvoiceProfile}`,
        data,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getinvoiceDetailsAction(response?.data?.data));
      } else {
        // dispatch(getticketListAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const updateLoginLogo = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.updateLoginLogo}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getinvoiceDetailsAction(response?.data?.data));
      } else {
        // dispatch(getticketListAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const updateInvoiceLogo = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.updateInvoiceLogo}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getinvoiceDetailsAction(response?.data?.data));
      } else {
        // dispatch(getticketListAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const updateHomeLogo = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.updateHomeLogo}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getinvoiceDetailsAction(response?.data?.data));
      } else {
        // dispatch(getticketListAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const getSubscription = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getSubscription}/${data}`, config);

      dispatch(getsubscrptionAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getBlogList = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getBlogList}/${data}`, config);

      dispatch(blogAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const updateBlogs = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      // const response = await api.get(
      //   `${URL.updateBlogs}?page=${data?.page}&category_id=${data?.category_id}&name=${data?.name}`,
      //   config
      // );
      const response = await api.post(`${URL.updateBlogs}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        toast.success(response?.data?.message);

        dispatch(blogAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const deleteBlog = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      // const response = await api.get(
      //   `${URL.delete_category}?page=${data?.page}&category_id=${data?.category_id}`,
      //   config
      // );
      const response = await api.post(`${URL.deleteBlog}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        toast.success(response?.data?.message);

        dispatch(blogAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const updateSlider = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      // const response = await api.get(
      //   `${URL.updateSlider}?page=${data?.page}&category_id=${data?.category_id}&name=${data?.name}`,
      //   config
      // );
      const response = await api.post(`${URL.updateSlider}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        toast.success(response?.data?.message);

        dispatch(sliderListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const deleteSlider = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      // const response = await api.get(
      //   `${URL.delete_category}?page=${data?.page}&category_id=${data?.category_id}`,
      //   config
      // );
      const response = await api.post(`${URL.deleteSlider}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        toast.success(response?.data?.message);

        dispatch(sliderListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const createSlider = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.createSlider}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        toast.success(response?.data?.message);

        dispatch(sliderListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getsliderList = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getsliderList}/${data}`, config);

      dispatch(sliderListAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const getallbrandlist = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      // const response = await api.get(
      //   `${URL.updateBrands}?page=${data?.page}&category_id=${data?.category_id}&name=${data?.name}`,
      //   config
      // );
      const response = await api.get(`${URL.getbrandforpro}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getbrandallListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
      return response?.data;
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
      return error?.response?.data;
    }
  };
};

export const updateBrands = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      // const response = await api.get(
      //   `${URL.updateBrands}?page=${data?.page}&category_id=${data?.category_id}&name=${data?.name}`,
      //   config
      // );
      const response = await api.post(`${URL.updateBrands}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        toast.success(response?.data?.message);

        dispatch(getbrandListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const statusBrands = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.statusBrands}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        toast.success(response?.data?.message);

        dispatch(getbrandListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const deletebrands = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      // const response = await api.get(
      //   `${URL.delete_category}?page=${data?.page}&category_id=${data?.category_id}`,
      //   config
      // );
      const response = await api.post(`${URL.deletebrands}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        toast.success(response?.data?.message);

        dispatch(getbrandListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getBrandList = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getBrandList}/${data}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getbrandListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const createBrand = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.createBrand}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        toast.success(response?.data?.message);

        dispatch(getbrandListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const faqslistadmin = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.faqslistadmin}/${data}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getfaqListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const updateFaq = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.updateFaq}`, data, config);

      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getfaqListAction(response?.data?.data));
        // dispatch(getsellerProductListAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const deleteFaqs = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.deleteFaqs}`, data, config);

      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getfaqListAction(response?.data?.data));
        // dispatch(getsellerProductListAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const deleteproductSize = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.deleteproductSize}`, data, config);

      if (response?.data?.success) {
        // toast.success(response?.data?.message);
        // dispatch(getfaqListAction(response?.data?.data));
        // dispatch(getsellerProductListAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const deleteproductcolor = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.deleteproductcolor}`,
        data,
        config
      );

      if (response?.data?.success) {
        // toast.success(response?.data?.message);
        // dispatch(getfaqListAction(response?.data?.data));
        // dispatch(getsellerProductListAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const createfaqs = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.createfaqs}`, data, config);

      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getfaqListAction(response?.data?.data));
        // dispatch(getsellerProductListAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const commissiondistribution = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      // dispatch(getmonthreports2ListAction({}));
      const response = await api.post(
        `${URL.commissiondistribution}`,
        data,
        config
      );

      if (response?.data?.success) {
        // toast.success(response?.data?.message);
        // dispatch(getmonthreports2ListAction(response?.data?.data));
        // dispatch(getsellerProductListAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
      return response?.data;
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
      return error?.response?.data;
    }
  };
};
export const teamCommissionByDateBypagi = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      // dispatch(getmonthreports2ListAction({}));
      const response = await api.post(
        `${URL.teamCommissionByDateBypagi}`,
        data,
        config
      );

      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getmonthreports2ListAction(response?.data?.data));
        // dispatch(getsellerProductListAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
      return response?.data;
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
      return error?.response?.data;
    }
  };
};

export const getsubsubcate = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getsubsubcate}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getsubsubcategoryListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
      return response?.data;
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
      return error?.response?.data;
    }
  };
};
export const getsubcateAllList = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getsubcateAllList}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getssubcateAllListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
      return response?.data;
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
      return error?.response?.data;
    }
  };
};

export const getmessageSupportList = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.getmessageSupportList}`,
        data,
        config
      );

      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getmessageListAction(response?.data?.data));
        // dispatch(getsellerProductListAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const createMessage = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.createMessage}`, data, config);

      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getmessageListAction(response?.data?.data));
        // dispatch(getsellerProductListAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

// export const createMessage = (data, history) => {
//   return async (dispatch) => {
//     try {
//       const token = await localStorage.getItem("admin_token");

//       const config = {
//         headers: {
//           Authorization: "Bearer " + token,
//         },
//       };
//       // dispatch(loading(true));
//       const response = await api.post(`${URL.createMessage}`, data, config);

//       if (response?.data?.success) {
//         // toast.success(response?.data?.message);

//         dispatch(getmessageListAction(response?.data?.data));
//         // dispatch(getsellerProductListAction(response?.data?.data));
//       } else {
//         toast.error(response?.data?.message);
//       }

//       dispatch(loading(false));
//     } catch (error) {
//       dispatch(loading(false));
//       toast.error(error?.response?.data?.message);
//     }
//   };
// };

export const getTicketList = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getTicketList}/${data}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getticketListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const searchTicket = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.searchTicket}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getticketListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const teamCommissionByDate = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.teamCommissionByDate}`,
        data,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        toast.success(response?.data?.message);

        dispatch(monthreportAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const getduqeChalenge = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getduqeChalenge}/${data}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(duqeChalengeAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getduqeChalengesearch = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getduqeChalenge}/${data?.page}?key=${data?.key}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(duqeChalengeAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const DuqechalengeUprove = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.DuqechalengeUprove}`,
        data,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        toast.success(response?.data?.message);

        dispatch(duqeChalengeAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getmqChalenge = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getmqChalenge}/${data}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(mqChalengeAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getmqChalengesearch = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getmqChalenge}/${data?.page}?key=${data?.key}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(mqChalengeAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const MqchalengeUprove = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.MqchalengeUprove}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        toast.success(response?.data?.message);

        dispatch(mqChalengeAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const getuserreturnOrderAdmin = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.getuserreturnOrderAdmin}/${data}`,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(orderListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const getSearchReturnOrderAdmin = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.getSearchReturnOrderAdmin}`,
        data,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(orderListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getuserOrderDetails = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.getuserOrderDetails}`,
        data,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(orderDetailsAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const deletTransactionList = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.deletTransactionList}`,
        data,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        toast.success(response?.data?.message);

        // dispatch(orderListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));

      return response?.data;
    } catch (error) {
      dispatch(loading(false));

      return error?.response?.data;
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const gpgByAdmin = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.gpgByAdmin}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        toast.success(response?.data?.message);

        // dispatch(orderListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));

      return response?.data;
    } catch (error) {
      dispatch(loading(false));

      toast.error(error?.response?.data?.message);
      return error?.response?.data;
    }
  };
};
export const passwordChangeAdmin = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.passwordChangeAdmin}`,
        data,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        toast.success(response?.data?.message);

        // dispatch(orderListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));

      return response?.data;
    } catch (error) {
      dispatch(loading(false));

      return error?.response?.data;
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const selfpasswordChangeadmin = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.selfpasswordChangeadmin}`,
        data,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        toast.success(response?.data?.message);

        // dispatch(orderListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));

      return response?.data;
    } catch (error) {
      dispatch(loading(false));

      toast.error(error?.response?.data?.message);
      return error?.response?.data;
    }
  };
};
export const editusernmaeByadmin = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.editusernmaeByadmin}`,
        data,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        toast.success(response?.data?.message);

        // dispatch(orderListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));

      return response?.data;
    } catch (error) {
      dispatch(loading(false));

      toast.error(error?.response?.data?.message);
      return error?.response?.data;
    }
  };
};
export const saledeliverystatusupdatemulti = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.saledeliverystatusupdatemulti}`,
        data,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        toast.success(response?.data?.message);

        dispatch(orderListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const saledeliverystatusupdate = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.saledeliverystatusupdate}`,
        data,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        toast.success(response?.data?.message);

        dispatch(orderListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const cancelOrderByAdmin = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.cancelOrderByAdmin}`,
        data,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        toast.success(response?.data?.message);

        dispatch(orderListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const categoryWiseSale = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.categoryWiseSale}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(topViewAllTypeAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getSearchOrderAdmin = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.getSearchOrderAdmin}`,
        data,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(orderListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getctGpg = (data, history) => {
  return async (dispatch) => {
    try {
      dispatch(gpgListAction({}));
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.getctGpg}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(gpgListAction(response?.data?.data));
      } else {
        dispatch(gpgListAction(response?.data?.data));
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const getInvoceDetails = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getInvoceDetails}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getinvoiceDetailsAction(response?.data?.data));
      } else {
        // dispatch(getticketListAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getuserOrderAdmin = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.getuserOrderAdmin}/${data}`,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(orderListAction(response?.data?.data));
      } else {
        // dispatch(getticketListAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getProductListoutofstoke = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.getProductListoutofstoke}/${data}`,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getproductListstockAction(response?.data?.data));
      } else {
        // dispatch(getticketListAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getProductList = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getProductList}/${data}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getproductListAction(response?.data?.data));
      } else {
        // dispatch(getticketListAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const searchProduct = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.searchProduct}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getproductListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const statusProduct = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.statusProduct}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getproductListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const deleteProduct = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.deleteProduct}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getproductListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const userLogin = (data, navigate) => {
  return async (dispatch) => {
    try {
      const response = await api.post(`${URL.login_admin}`, data);
      if (response?.status == 200) {
        // toast.success(response?.data?.message);
        console.log(response?.data);
        if (response?.data?.success == true) {
          // dispatch(otpBoxAction(true));
          localStorage.setItem("admin_token", response?.data?.api_token);
          navigate("/");
          toast.success(response?.data?.message);
        } else {
          toast.error(response?.data?.message);
        }
        // otpBoxAction
        // Token.setToken(response?.data?.api_token);
        // setTimeout(navigate("/vendor"), 1000);
        dispatch(loading(false));
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const getcateAllList = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getcateAllList}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getcateAllListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
      return response?.data;
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
      return error?.response?.data;
    }
  };
};

export const getsubsubCategoryList = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.getsubsubCategoryList}/${data}`,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getsubsusbcategoriesfillAction(response?.data?.data));
      } else {
        // dispatch(getticketListAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const subsubcreateCategory = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.subsubcreateCategory}`,
        data,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        toast.success(response?.data?.message);

        dispatch(getsubsusbcategoriesfillAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const updateperentsubsubCate = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.updateperentsubsubCate}`,
        data,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        toast.success(response?.data?.message);

        dispatch(getsubsusbcategoriesfillAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const deletesubsubCategory = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.deletesubsubCategory}`,
        data,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        toast.success(response?.data?.message);

        dispatch(getsubsusbcategoriesfillAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const statusSubSubCategory = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.statusSubSubCategory}`,
        data,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        toast.success(response?.data?.message);

        dispatch(getsubsusbcategoriesfillAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const getsubCategoryList = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.getsubCategoryList}/${data}`,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getsubCategoryListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const subcreateCategory = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.subcreateCategory}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        toast.success(response?.data?.message);

        dispatch(getsubCategoryListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const deletesubCategory = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.deletesubCategory}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        toast.success(response?.data?.message);

        dispatch(getsubCategoryListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const updateperentsubCate = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.updateperentsubCate}`,
        data,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        toast.success(response?.data?.message);

        dispatch(getsubCategoryListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const statusSubCategory = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.statusSubCategory}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        toast.success(response?.data?.message);

        dispatch(getsubCategoryListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const allcategory = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.all_category_main}/${data}`,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(categoryLsitAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const allcategorySearch = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.all_category_main}?page=${data?.page}&search=${data?.search}`,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.status) {
        // toast.success(response?.data?.message);

        dispatch(categoryLsitAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const delete_category = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      // const response = await api.get(
      //   `${URL.delete_category}?page=${data?.page}&category_id=${data?.category_id}`,
      //   config
      // );
      const response = await api.post(`${URL.delete_category}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        toast.success(response?.data?.message);

        dispatch(categoryLsitAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const update_category = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      // const response = await api.get(
      //   `${URL.update_category}?page=${data?.page}&category_id=${data?.category_id}&name=${data?.name}`,
      //   config
      // );
      const response = await api.post(`${URL.update_category}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        toast.success(response?.data?.message);

        dispatch(categoryLsitAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const create_category = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.create_category}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        toast.success(response?.data?.message);

        dispatch(categoryLsitAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const statusCategory = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.statusCategory}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        toast.success(response?.data?.message);

        dispatch(categoryLsitAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const LeaderBoard = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.LeaderBoard}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(gettopthreeAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const LeaderBoardbyAdmin = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.LeaderBoardbyAdmin}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getleaderBoardCtMqAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const dashboard = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.dashboard}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(deshboardAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const createSetting = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));

      const response = await api.post(`${URL.createSetting}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        toast.success(response?.data?.message);
        // const responses = await api.get(
        //   `${URL.users}?page=${data?.page}`,
        //   config
        // );
        dispatch(getsettingListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getsetting = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getsetting}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getsettingListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getBettalListAdmin = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      // console.log(config);
      const response = await api.get(
        `${URL.getBettalListAdmin}/${data}`,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(winningReqAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getBettalList = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      // console.log(config);
      const response = await api.get(`${URL.getBettalList}/${data}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(battelListAdminAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const getwithdrwarlAdminList = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));

      const response = await api.post(
        `${URL.getwithdrwarlAdminList}`,
        data,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);
        // const responses = await api.get(
        //   `${URL.users}?page=${data?.page}`,
        //   config
        // );
        dispatch(withdrawalListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const completeWithdrawalReq = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));

      const response = await api.post(
        `${URL.completeWithdrawalReq}`,
        data,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);
        // const responses = await api.get(
        //   `${URL.users}?page=${data?.page}`,
        //   config
        // );
        dispatch(withdrawalListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const deleteByAdminBettal = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));

      const response = await api.post(
        `${URL.deleteByAdminBettal}`,
        data,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        toast.success(response?.data?.message);
        // const responses = await api.get(
        //   `${URL.users}?page=${data?.page}`,
        //   config
        // );
        dispatch(battelListAdminAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const getkycpending = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      // console.log(config);
      const response = await api.get(`${URL.getkycpending}/${data}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getkycLsitAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getTransactionList = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      // console.log(config);
      const response = await api.get(
        `${URL.getTransactionList}/${data}`,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getstransationListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const searchtransaction = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));

      const response = await api.post(`${URL.searchtransaction}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);
        // const responses = await api.get(
        //   `${URL.users}?page=${data?.page}`,
        //   config
        // );
        dispatch(getstransationListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getbDetails = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));

      const response = await api.post(`${URL.getbDetails}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);
        // const responses = await api.get(
        //   `${URL.users}?page=${data?.page}`,
        //   config
        // );
        dispatch(battelDetailsAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getprofile = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getprofile}`, config);
      dispatch(getprofileAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getProfileDetails = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));

      const response = await api.post(`${URL.getProfileDetails}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);
        // const responses = await api.get(
        //   `${URL.users}?page=${data?.page}`,
        //   config
        // );
        dispatch(userDetailsAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const verifykeyByAdmin = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));

      const response = await api.post(`${URL.verifykeyByAdmin}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);
        // const responses = await api.get(
        //   `${URL.users}?page=${data?.page}`,
        //   config
        // );
        dispatch(userDetailsAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const getuserList = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      // console.log(config);
      const response = await api.get(`${URL.users}/${data}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(userListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const edituserProfileByadmin = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));

      // edituserProfileByadmin
      const response = await api.post(
        `${URL.edituserProfileByadmin}`,
        data,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        toast.success(response?.data?.message);
        // const responses = await api.get(
        //   `${URL.users}?page=${data?.page}`,
        //   config
        // );
        // dispatch(userListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
      return response?.data;
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
      return error?.response?.data;
    }
  };
};
export const edituserKycByadmin = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));

      // edituserKycByadmin
      const response = await api.post(
        `${URL.edituserKycByadmin}`,
        data,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        toast.success(response?.data?.message);
        // const responses = await api.get(
        //   `${URL.users}?page=${data?.page}`,
        //   config
        // );
        // dispatch(userListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const deleteUserByadmin = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));

      // deleteUserByadmin
      const response = await api.post(`${URL.deleteUserByadmin}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        toast.success(response?.data?.message);
        // const responses = await api.get(
        //   `${URL.users}?page=${data?.page}`,
        //   config
        // );
        dispatch(userListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getUserListActiveSuspend = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));

      // getUserListActiveSuspend
      const response = await api.post(`${URL.getUserListActiveSuspend}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);
        // const responses = await api.get(
        //   `${URL.users}?page=${data?.page}`,
        //   config
        // );
        dispatch(userListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const user_inactive = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));

      // user_inactive
      const response = await api.post(`${URL.user_inactive}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);
        // const responses = await api.get(
        //   `${URL.users}?page=${data?.page}`,
        //   config
        // );
        dispatch(userListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const addlicence = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.addlicence}`, data, config);
      if (response?.data?.success) {
        toast.success(response?.data?.message);
        // dispatch(getsellerProductListAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }
      // dispatch(getSellerDetailsAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";

      // toast.success();
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const addlicensebyadminOldUser = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.addlicensebyadminOldUser}`,
        data,
        config
      );
      if (response?.data?.success) {
        toast.success(response?.data?.message);
        // dispatch(getsellerProductListAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }
      // dispatch(getSellerDetailsAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";

      // toast.success();
      dispatch(loading(false));
      return response?.data;
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
      return error?.response?.data;
    }
  };
};
export const KycstatusUpdateByAdmin = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));

      // KycstatusUpdateByAdmin
      const response = await api.post(
        `${URL.KycstatusUpdateByAdmin}`,
        data,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);
        // const responses = await api.get(
        //   `${URL.users}?page=${data?.page}`,
        //   config
        // );
        dispatch(userListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const updaterankbyadmin = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));

      // updaterankbyadmin
      const response = await api.post(`${URL.updaterankbyadmin}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);
        // const responses = await api.get(
        //   `${URL.users}?page=${data?.page}`,
        //   config
        // );
        // dispatch(userListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
      return response?.data;
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
      return error?.response?.data;
    }
  };
};
export const reducePointFoundByAdmin = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));

      // reducePointFoundByAdmin
      const response = await api.post(
        `${URL.reducePointFoundByAdmin}`,
        data,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));

      console.log(response?.data);
      if (response?.data?.success) {
        toast.success(response?.data?.message);
        // const responses = await api.get(
        //   `${URL.users}?page=${data?.page}`,
        //   config
        // );
        // dispatch(userListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
      return response?.data;
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
      return error?.response?.data;
    }
  };
};
export const addFoundByAdmin = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));

      // addFoundByAdmin
      const response = await api.post(`${URL.addFoundByAdmin}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);
        // const responses = await api.get(
        //   `${URL.users}?page=${data?.page}`,
        //   config
        // );
        // dispatch(userListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));

      return response?.data;
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
      return error?.response?.data;
    }
  };
};
export const getUserListseacrch = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));

      const response = await api.post(
        `${URL.getUserListseacrch}`,
        data,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);
        // const responses = await api.get(
        //   `${URL.users}?page=${data?.page}`,
        //   config
        // );
        dispatch(userListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getSellerListStatus = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));

      const response = await api.post(
        `${URL.getSellerListStatus}`,
        data,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);
        // const responses = await api.get(
        //   `${URL.users}?page=${data?.page}`,
        //   config
        // );
        dispatch(userListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const searchUserListStatus = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));

      const response = await api.post(
        `${URL.searchUserListStatus}`,
        data,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);
        // const responses = await api.get(
        //   `${URL.users}?page=${data?.page}`,
        //   config
        // );
        dispatch(userListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getuserListSearch = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.users}?page=${data?.page}&search=${data?.search}`,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.status) {
        // toast.success(response?.data?.message);

        dispatch(userListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const create_quiz = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.create_quiz}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.status) {
        toast.success(response?.data?.message);

        dispatch(quizLsitAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const update_quiz = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.update_quiz}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.status) {
        toast.success(response?.data?.message);

        dispatch(quizLsitAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const delete_quiz = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.delete_quiz}?page=${data?.page}&category_id=${data?.category_id}&type=${data?.type}&quiz_id=${data?.quiz_id}`,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.status) {
        toast.success(response?.data?.message);

        dispatch(quizLsitAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const quiz_list = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.quiz_list}?page=${data?.page}&category_id=${data?.category_id}&type=${data?.type}`,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.status) {
        // toast.success(response?.data?.message);

        dispatch(quizLsitAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const quiz_listSearch = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.quiz_list}?page=${data?.page}&category_id=${data?.category_id}&type=${data?.type}&search=${data?.search}`,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.status) {
        // toast.success(response?.data?.message);

        dispatch(quizLsitAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const questionsLlist = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.questions_list}?page=${data?.page}&quiz_id=${data?.quiz_id}&type=${data?.type}`,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.status) {
        // toast.success(response?.data?.message);

        dispatch(questionLsitAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const questionsLlistSearch = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.questions_list}?page=${data?.page}&quiz_id=${data?.quiz_id}&type=${data?.type}&search=${data?.search}`,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.status) {
        // toast.success(response?.data?.message);

        dispatch(questionLsitAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const delete_question = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.delete_question}?page=${data?.page}&quiz_id=${data?.quiz_id}&type=${data?.type}&question_id=${data?.question_id}`,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.status) {
        toast.success(response?.data?.message);

        dispatch(questionLsitAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const update_question = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.update_question}`, data, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.status) {
        toast.success(response?.data?.message);

        dispatch(questionLsitAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const create_quiz_question = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.create_quiz_question}`,
        data,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.status) {
        toast.success(response?.data?.message);

        dispatch(questionLsitAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const usercontact = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.user_contact}?page=${data?.page}&user_id=${data?.user_id}`,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.status) {
        // toast.success(response?.data?.message);

        dispatch(userContactListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        dispatch(userContactListAction({}));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const usercontactser = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("admin_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.user_contact}?page=${data?.page}&user_id=${data?.user_id}&search=${data?.search}`,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.status) {
        // toast.success(response?.data?.message);

        dispatch(userContactListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        dispatch(userContactListAction({}));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

// old

export const getlistfaqs = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getlistfaqs}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(faqsLsitAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const gethomePop = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.gethomePop}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(homepopAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getBlogListPub = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getBlogListPub}/${data}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(blogListAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const getExpireDate = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getExpireDate}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getExpireDateAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const sellerDashboardState = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.sellerDashboardState}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(sellerDashboardStateAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const getSellerListPublic = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.getSellerListPublic}`,
        data,
        config
      );
      dispatch(getSellerListPublicAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const topViewnewSeller = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.topViewnewSeller}`, data, config);
      dispatch(topViewAllTypeAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const getBlogDetails = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.getBlogDetails}`, data, config);
      dispatch(blogDetailsAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getHomePro = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.getHomePro}`, data, config);
      dispatch(gethomeAllProAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const footerPagepageListetPu = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.footerPagepageListetPu}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getfooterAllDataPubAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const passwordChange = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.passwordChange}`, data, config);

      if (response?.data?.success) {
        toast.success(response?.data?.message);
        // dispatch(otpBoxAction(true));

        // dispatch(getsearchProductPubAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const sendOtpSeller = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.sendOtpSeller}`, data, config);

      if (response?.data?.success) {
        // toast.success(response?.data?.message);
        dispatch(otpBoxAction(true));

        // dispatch(getsearchProductPubAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const searchProductpub = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.searchProductpub}`, data, config);

      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getsearchProductPubAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const createSellerProducsdft = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.createSellerProduct}`,
        data,
        config
      );

      if (response?.data?.success) {
        toast.success(response?.data?.message);

        dispatch(getsellerProductListAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const searchtags = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.searchtags}`, data, config);
      dispatch(getsearchtagssAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const pageDetails = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.pageDetails}/${data}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getpageDetailsAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const getPages = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getPages}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getPagesAction(response?.data?.data));
      } else {
        dispatch(getPagesAction(response?.data?.data));
        // toast.error(response?.data?.message);
      }
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const getEmailenquirySeller = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.getEmailenquirySeller}/${data}`,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getemailQuAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getsellerWhatsappinquire = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.getsellerWhatsappinquire}/${data}`,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getWhatsappQuAction(response?.data?.data));
      } else {
        // dispatch(getemailQuAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const productDetails = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.productDetails}/${data}`, config);
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getproductDetailsAction(response?.data?.data));
        // return response;
      } else {
        dispatch(getsellerProductListAction(response?.data?.data));
        toast.error(response?.data?.message);
      }
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
      return response?.data;
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
      return error?.response?.data;
    }
  };
};

export const emailenquirysubmit = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.emailenquirysubmit}`,
        data,
        config
      );
      // dispatch(createcategoriesAction(response?.data?.data));
      toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const whatsappinquiresubmit = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.whatsappinquiresubmit}`,
        data,
        config
      );
      // dispatch(createcategoriesAction(response?.data?.data));
      toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const createcategories = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.createcategories}`, data, config);
      dispatch(createcategoriesAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const productclick = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.productclick}`, data, config);
      if (response?.data?.success) {
        // toast.success(response?.data?.message);
        // dispatch(getsellerProductListAction(response?.data?.data));
      } else {
        // toast.error(response?.data?.message);
      }
      // dispatch(getSellerDetailsAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";

      toast.success();
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const createSubscription = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.createSubscription}`,
        data,
        config
      );
      if (response?.data?.success) {
        toast.success(response?.data?.message);

        // dispatch(getsellerProductListAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }
      // dispatch(getSellerDetailsAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";

      toast.success();
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getsellerprofile = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.getsellerprofile}`, data, config);
      dispatch(getSellerDetailsAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
      return response?.data;
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
      return error?.response?.data;
    }
  };
};
export const gettagsProductListPublice = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.gettagsProductListPublice}`,
        data,
        config
      );
      dispatch(gettagsProductListPublicAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getsellerProductListPublice = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.getsellerProductListPublice}`,
        data,
        config
      );
      dispatch(getsellerProductListPublicAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getsellerProductListPublicefilCate = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.getsellerProductListPublicefilCate}`,
        data,
        config
      );
      dispatch(getsellerProductListPublicAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const getsellerProductListPublicebymaincategory2 = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.getsellerProductListPublicebymaincategory2}`,
        data,
        config
      );
      dispatch(
        getsellerProductListPublicbycategoryAction(response?.data?.data)
      );
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getsellerProductListPublicebycategory = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.getsellerProductListPublicebycategory}`,
        data,
        config
      );
      dispatch(
        getsellerProductListPublicbycategoryAction(response?.data?.data)
      );
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const createSellerProduct = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.createSellerProduct}`,
        data,
        config
      );

      if (response?.data?.success) {
        toast.success(response?.data?.message);

        dispatch(getsellerProductListAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const copyProduct = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(`${URL.copyProduct}`, data, config);

      if (response?.data?.success) {
        toast.success(response?.data?.message);

        dispatch(getsellerProductListAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const updatesellerProduct = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.updatesellerProduct}`,
        data,
        config
      );

      if (response?.data?.success) {
        toast.success(response?.data?.message);

        dispatch(getsellerProductListAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const searchSellerProduct = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.searchSellerProduct}`,
        data,
        config
      );

      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getsellerProductListAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }
      // dispatch(getsellerProductListAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const deleteSellerProduct = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.deleteSellerProduct}`,
        data,
        config
      );
      dispatch(getsellerProductListAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getsellerProductList = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.getsellerProductList}/${data}`,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getsellerProductListAction(response?.data?.data));
      } else {
        dispatch(getsellerProductListAction(response?.data?.data));
        toast.error(response?.data?.message);
      }
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const getCategory = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getCategory}`, data, config);

      dispatch(getcategoriesAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getsubsubcateList = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getsubsubcate}`, data, config);

      dispatch(getsubsusbcateListAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getsubsubcateHome = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getsubsubcateHome}`, data, config);

      dispatch(getsubsusbcategoriesAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

// export const signinSeller = (data, navigate) => {
//   return async (dispatch) => {
//     try {
//       const response = await api.post(`${URL.signinSeller}`, data);
//       if (response?.status == 200) {
//         toast.success(response?.data?.message);

//         Token.setToken(response?.data?.api_token);

//         const lsValue = JSON.stringify(response?.data?.data);
//         Token.setUserDetail(lsValue);
//         setTimeout(navigate("/vendor"), 3000);
//         dispatch(loading(false));
//       } else {
//         toast.error(response?.data?.message);
//       }
//     } catch (error) {
//       dispatch(loading(false));
//       toast.error(error?.response?.data?.message);
//     }
//   };
// };
// export const signinSellereSecurite = (data, navigate) => {
//   return async (dispatch) => {
//     try {
//       const response = await api.post(`${URL.signinSellereSecurite}`, data);
//       if (response?.status == 200) {
//         // toast.success(response?.data?.message);

//         Token.setToken(response?.data?.api_token);
//         const lsValue = JSON.stringify(response?.data?.data);
//         Token.setUserDetail(lsValue);
//         setTimeout(navigate("/vendor"), 1000);
//         dispatch(loading(false));
//       } else {
//         toast.error(response?.data?.message);
//       }
//     } catch (error) {
//       dispatch(loading(false));
//       toast.error(error?.response?.data?.message);
//     }
//   };
// };

export const otpVerify = (data, navigate) => {
  return async (dispatch) => {
    try {
      const response = await api.post(`${URL.otpVerify}`, data);
      if (response?.status == 200) {
        // toast.success(response?.data?.message);

        if (response?.data?.success == true) {
          dispatch(otpBoxAction(false));
          toast.success(response?.data?.message);
        } else {
          toast.error(response?.data?.message);
        }
        // otpBoxAction
        // Token.setToken(response?.data?.api_token);
        setTimeout(navigate("/login"), 1000);
        dispatch(loading(false));
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};
export const otpVerifyseller = (data, navigate) => {
  return async (dispatch) => {
    try {
      const response = await api.post(`${URL.otpVerifyseller}`, data);
      if (response?.status == 200) {
        // toast.success(response?.data?.message);

        if (response?.data?.success == true) {
          dispatch(otpBoxAction(false));
          toast.success(response?.data?.message);
        } else {
          toast.error(response?.data?.message);
        }
        // otpBoxAction
        // Token.setToken(response?.data?.api_token);
        setTimeout(navigate("/login"), 1000);
        dispatch(loading(false));
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const createBussnissPro = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      const response = await api.post(`${URL.createBussnissPro}`, data, config);
      dispatch(getBussnissProfileAction(response?.data?.data));

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const updateBussLogo = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      const response = await api.post(`${URL.updateBussLogo}`, data, config);
      dispatch(getBussnissProfileAction(response?.data?.data));

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const updateBussProfile = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      const response = await api.post(`${URL.updateBussProfile}`, data, config);
      dispatch(getBussnissProfileAction(response?.data?.data));

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const updateWhatsaapNumber = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      const response = await api.post(
        `${URL.updateWhatsaapNumber}`,
        data,
        config
      );
      // dispatch(getBussnissProfileAction(response?.data?.data));

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const verifyWhatsapp = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      const response = await api.post(`${URL.verifyWhatsapp}`, data, config);
      dispatch(getBussnissProfileAction(response?.data?.data));

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};
export const getBussProfile = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      const response = await api.get(`${URL.getBussProfile}`, config);
      dispatch(getBussnissProfileAction(response?.data?.data));

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const getPackageList = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getPackageList}/${data}`, config);
      dispatch(getpackageListAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const createPurchase = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      const response = await api.post(`${URL.createPurchase}`, data, config);
      // dispatch(getBussnissProfileAction(response?.data?.data));

      if (response?.data?.success) {
        toast.success(response?.data?.message);
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const getsellerTransaction = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.getsellerTransaction}/${data}`,
        config
      );
      dispatch(gettansactionListAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const getInvoice = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(`${URL.getInvoice}`, config);
      dispatch(getinvoiceAction(response?.data?.data));
      // toast.success(response?.data?.message);
      // document.querySelector("#createcategories").style.display = "none";
      // document.querySelector("#categories").style.display = "block";
      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

// createSellerTickets

export const createSellerTickets = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.createSellerTickets}`,
        data,
        config
      );

      if (response?.data?.success) {
        toast.success(response?.data?.message);

        dispatch(getticketListAction(response?.data?.data));
        // dispatch(getsellerProductListAction(response?.data?.data));
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

export const getSellerTicketList = (data, history) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.get(
        `${URL.getSellerTicketList}/${data}`,
        config
      );
      // dispatch(getsellerProductListAction(response?.data?.data));
      if (response?.data?.success) {
        // toast.success(response?.data?.message);

        dispatch(getticketListAction(response?.data?.data));
      } else {
        dispatch(getticketListAction(response?.data?.data));
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      // toast.error(error?.response?.data?.message);
    }
  };
};

export const deleteacountByseller = (data, navigate) => {
  return async (dispatch) => {
    try {
      const token = await localStorage.getItem("access_token");

      const config = {
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      // dispatch(loading(true));
      const response = await api.post(
        `${URL.deleteacountByseller}`,
        data,
        config
      );

      if (response?.data?.success) {
        localStorage.clear();
        setTimeout(navigate("/"), 1000);
      } else {
        toast.error(response?.data?.message);
      }

      dispatch(loading(false));
    } catch (error) {
      dispatch(loading(false));
      toast.error(error?.response?.data?.message);
    }
  };
};

import LogoHeader from "./../assets/images/icons/logo1.png";
import LogoShort from "./../assets/images/icons/logo2.png";
import Documenticon from "./../assets/images/icons/folder.png";
import rilLogo from "./../assets/images/icons/rillogo.webp";

const IMAGES = {
  LogoHeader: LogoHeader,
  LogoShort: LogoShort,
  Documenticon: Documenticon,
  RilLogo: rilLogo,
};
export default IMAGES;

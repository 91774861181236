import React from "react";
import { useNavigate } from "react-router-dom";
import IMAGES from "../../const/Image";

const Logo = ({ collapsed }) => {
  const navigate = useNavigate();
  return (
    <div className={collapsed ? "logo shortlogo" : " shortlogo2"}>
      {collapsed ? (
        <img
          src={IMAGES.LogoShort}
          className="cursor-pointer"
          alt=""
          onClick={() => navigate("/")}
        />
      ) : (
        <div className="row">
          <div className="col-4">
            <img
              src={IMAGES.LogoShort}
              className="cursor-pointer"
              alt=""
              onClick={() => navigate("/")}
            />
          </div>
          <div className="col-8">
            <h6>Ril India</h6>
          </div>
        </div>
      )}
    </div>
  );
};

export default Logo;

export const URL = {
  // dev

  // API_BASE_URL: "http://localhost:5200",
  API_BASE_URL: "https://api.rilindia.biz",  

  // colne
  // API_BASE_URL: "https://cloneapi.rilindia.biz",
  // API_BASE_URL: "http://34.229.29.185:3001",

  // apipublic

  passwordChangeAdmin: "/adminApi/passwordChangeAdmin",
  selfpasswordChangeadmin: "/adminApi/selfpasswordChangeadmin",
  editusernmaeByadmin: "/adminApi/editusernmaeByadmin",
  createAbout: "/adminApi/createAbout",
  getPagesm: "/adminApi/getpageList",
  updatePageAdmin: "/adminApi/updatePage",
  deletePage: "/adminApi/deletePage",
  LeaderBoard: "/adminApi/LeaderBoard",
  LeaderBoardbyAdmin: "/adminApi/LeaderBoardbyAdmin",
  deletTransactionList: "/adminApi/deletTransactionList",
  addlicensebyadminOldUser: "/adminApi/addlicensebyadminOldUser",

  createFooterPageType: "/adminApi/createFooterPageType",
  footerPageTypesDelete: "/adminApi/footerPageTypesDelete",
  footerPageTypesUpdate: "/adminApi/footerPageTypesUpdate",
  createFooterPageList: "/adminApi/createFooterPageList",

  footerPageTypes: "/adminApi/footerPageTypes",
  getpageListfooter: "/adminApi/getpageListfooter",
  footerPagepageListet: "/adminApi/footerPagepageListet",
  footerPageListsDelete: "/adminApi/footerPageListsDelete",

  login_admin: "/publicApi/signinAdmin",
  commissiondistribution: "/publicApi/commissiondistribution",

  create_category: "/adminApi/createCategory",
  createBrand: "/adminApi/createBrand",
  update_category: "/adminApi/updateperentCate",
  updateBrands: "/adminApi/updateBrands",
  delete_category: "/adminApi/deleteCategory",
  deletebrands: "/adminApi/deletebrands",
  getcateAllList: "/adminApi/getcateAllList",
  statusCategory: "/adminApi/statusCategory",
  statusBrands: "/adminApi/statusBrands",
  getbrandforpro: "/adminApi/getbrandforpro",
  getSubscription: "/adminApi/getSubscription",
  // slider
  createSlider: "/adminApi/createSlider",
  getsliderList: "/adminApi/getsliderList33",
  updateSlider: "/adminApi/updateSlider",
  deleteSlider: "/adminApi/deleteSlider",
  gpgByAdmin: "/adminApi/gpgByAdmin",

  subcreateCategory: "/adminApi/subcreateCategory",
  getsubCategoryList: "/adminApi/getsubCategoryList",
  getsubsubCategoryList: "/adminApi/getsubsubCategoryList",
  getsubcateAllList: "/adminApi/getsubcateAllList",
  deletesubCategory: "/adminApi/deletesubCategory",

  updateperentsubCate: "/adminApi/updateperentsubCate",
  updateperentsubsubCate: "/adminApi/updateperentsubsubCate",

  subsubcreateCategory: "/adminApi/subsubcreateCategory",
  deletesubsubCategory: "/adminApi/deletesubsubCategory",

  statusSubCategory: "/adminApi/statusSubCategory",
  statusSubSubCategory: "/adminApi/statusSubSubCategory",

  users: "/adminApi/getSellerList",
  user_inactive: "/adminApi/getUserListActive",
  getUserListActiveSuspend: "/adminApi/getUserListActiveSuspend",
  addFoundByAdmin: "/adminApi/addPoint",
  reducePointFoundByAdmin: "/adminApi/reducePoint",
  updaterankbyadmin: "/adminApi/updaterankbyadmin",
  KycstatusUpdateByAdmin: "/adminApi/KycstatusUpdateByAdmin",
  getUserListseacrch: "/adminApi/searchUserList",
  searchUserListStatus: "/adminApi/searchUserListStatus",
  getSellerListStatus: "/adminApi/getSellerListStatus",
  getBettalList: "/adminApi/getBettalList",
  deleteByAdminBettal: "/adminApi/deleteByAdminBettal",
  getBettalListAdmin: "/adminApi/getBettalListAdmin",
  getwithdrwarlAdminList: "/adminApi/getwithdrwarlAdminList",
  completeWithdrawalReq: "/adminApi/completeWithdrawalReq",
  getkycpending: "/adminApi/getkycpending",
  getTransactionList: "/adminApi/getTransactionList",
  searchtransaction: "/adminApi/searchtransaction",
  getProfileDetails: "/adminApi/getProfileDetails",
  getprofile: "/adminApi/getprofile",
  getbDetails: "/adminApi/getbDetails",
  dashboard: "/adminApi/deshBoradData",
  getsetting: "/adminApi/getsetting",
  createSetting: "/adminApi/createSetting",
  deleteUserByadmin: "/adminApi/deleteUserByadmin",
  edituserProfileByadmin: "/adminApi/edituserProfileByadmin",
  edituserKycByadmin: "/adminApi/edituserKycByadmin",
  getctGpg: "/adminApi/getctGpg",
  createfaqs: "/adminApi/createfaqs",
  faqslistadmin: "/adminApi/faqslistadmin",
  updateFaq: "/adminApi/updateFaq",
  deleteFaqs: "/adminApi/deleteFaqs",
  deleteproductcolor: "/adminApi/deleteproductcolor",
  deleteproductSize: "/adminApi/deleteproductSize",
  saledeliverystatusupdate: "/adminApi/saledeliverystatusupdate",
  saledeliverystatusupdatemulti: "/adminApi/saledeliverystatusupdatemulti",

  // addPoint: "/adminApi/addPoint",
  getsubsubCategoryList: "/adminApi/getsubsubCategoryList",

  verifykeyByAdmin: "/adminApi/verifykeyByAdmin",
  categoryWiseSale: "/adminApi/categoryWiseSale",

  user_contact: "/api/user_contact",
  all_category: "/api/all_category",
  all_category_main: "/adminApi/getCategoryList",
  getBrandList: "/adminApi/getBrandList",

  // blog
  createBlogs: "/adminApi/createBlogs",
  getBlogList: "/adminApi/getBlogList",
  updateBlogs: "/adminApi/updateBlogs",
  deleteBlog: "/adminApi/deleteBlog",

  // product
  getProductList: "/adminApi/getProductList",
  getProductListoutofstoke: "/adminApi/getProductListoutofstoke",
  getuserOrderDetailsPrint: "/adminApi/getuserOrderDetailsPrint",
  getuserOrderDetailsPrinttrue: "/adminApi/getuserOrderDetailsPrinttrue",
  searchProduct: "/adminApi/searchProduct",
  statusProduct: "/adminApi/statusProduct",
  deleteProduct: "/adminApi/deleteProduct",
  productDetails: "/adminApi/productDetails",

  createProduct2: "/adminApi/createProduct2",
  createProductvariations: "/adminApi/createProductvariations",
  updateProductvariations: "/adminApi/updateProductvariations",
  updateProduct: "/adminApi/updateProduct",
  // order
  getuserOrderAdmin: "/adminApi/getuserOrderAdmin",
  updateInvoiceProfile: "/adminApi/updateInvoiceProfile",
  updateHomeLogo: "/adminApi/updateHomeLogo",
  updateInvoiceLogo: "/adminApi/updateInvoiceLogo",
  updateLoginLogo: "/adminApi/updateLoginLogo",
  updateRegisterLogo: "/adminApi/updateRegisterLogo",
  getInvoceDetails: "/adminApi/getInvoceDetails",
  getuserOrderDetails: "/adminApi/getuserOrderDetails",
  cancelOrderByAdmin: "/adminApi/cancelOrderByAdmin",
  getSearchOrderAdmin: "/adminApi/getSearchOrderAdmin",

  getuserreturnOrderAdmin: "/adminApi/getuserreturnOrderAdmin",
  getSearchReturnOrderAdmin: "/adminApi/getSearchReturnOrderAdmin",
  getsubsubcate: "/adminApi/getsubsubcate",

  getmqChalenge: "/adminApi/getmqChalenge",
  getduqeChalenge: "/adminApi/getduqeChalenge",
  MqchalengeUprove: "/adminApi/MqchalengeUprove",
  DuqechalengeUprove: "/adminApi/DuqechalengeUprove",
  ctLeaderBoad: "/adminApi/ctLeaderBoad",

  teamCommissionByDate: "/adminApi/teamCommissionByDate",

  teamCommissionByDateBypagi: "/adminApi/teamCommissionByDateBypagi",
  getTicketList: "/adminApi/getTicketList",
  searchTicket: "/adminApi/searchTicket",
  getmessageSupportList: "/adminApi/getmessageSupportList",
  createMessage: "/adminApi/createMessage",
  addlicence: "/adminApi/addlicence",
  // product Add

  //

  quiz_list: "/api/quiz_list",
  create_quiz: "/api/create_quiz",
  delete_quiz: "/api/delete_quiz",
  update_quiz: "/api/update_quiz",
  questions_list: "/api/questions_list",
  delete_question: "/api/delete_question",
  update_question: "/api/update_question",
  create_quiz_question: "/api/create_quiz_question",

  user_allcontact: "/api/user_allcontact",

  quiz_active: "/api/quiz_active",

  createcategories: "/apiVendor/createcategories",
  getCategory: "/publicApi/getCategory",

  getsubsubcateHome: "/publicApi/getsubsubcateHome",
  signupSeller: "/publicApi/signupSeller",
  otpVerify: "/publicApi/otpVerify",
  productDetails: "/publicApi/productDetails",
  getsellerProductListPublice: "/publicApi/getsellerProductListPublice",
  gettagsProductListPublice: "/publicApi/gettagsProductListPublice",
  getsellerProductListPublicefilCate:
    "/publicApi/getsellerProductListPublicefilCate",
  getsellerProductListPublicebycategory:
    "/publicApi/getsellerProductListPublicebycategory",
  getsellerprofile: "/publicApi/getsellerprofile",
  whatsappinquiresubmit: "/publicApi/whatsappinquiresubmit",

  createSubscription: "/publicApi/createSubscription",
  getPages: "/publicApi/getPagesPu",
  emailenquirysubmit: "/publicApi/emailenquirysubmit",
  pageDetails: "/publicApi/pageDetails",
  searchProductpub: "/publicApi/searchProductpub",
  sendOtpSeller: "/publicApi/sendOtpSeller",
  otpVerifyseller: "/publicApi/otpVerifyseller",
  signinSellereSecurite: "/publicApi/signinSellereSecurite",
  footerPagepageListetPu: "/publicApi/footerPagepageListetPu",
  productclick: "/publicApi/productclick",
  getHomePro: "/publicApi/getHomePro",
  getSellerListPublic: "/publicApi/getSellerListPublic",
  getsellerProductListPublicebymaincategory2:
    "/publicApi/getsellerProductListPublicebymaincategory2",

  // apiAdmin
  signinSeller: "/publicApi/signinSeller",
  getsellerProductList: "/sellerApi/getsellerProductList",
  createSellerProduct: "/sellerApi/createSellerProduct",
  searchSellerProduct: "/sellerApi/searchSellerProduct",
  deleteSellerProduct: "/sellerApi/deleteSellerProduct",
  createBussnissPro: "/sellerApi/createBussnissPro",
  getBussProfile: "/sellerApi/getBussProfile",
  updateWhatsaapNumber: "/sellerApi/updateWhatsaapNumber",
  verifyWhatsapp: "/sellerApi/verifyWhatsapp",
  updateBussLogo: "/sellerApi/updateBussLogo",
  updateBussProfile: "/sellerApi/updateBussProfile",
  getPackageList: "/sellerApi/getPackageList",
  createPurchase: "/sellerApi/createPurchase",
  getsellerTransaction: "/sellerApi/getsellerTransaction",
  // getprofile: "/sellerApi/getprofile",
  updatesellerProduct: "/sellerApi/updatesellerProduct",
  getSellerTicketList: "/sellerApi/getSellerTicketList",
  createSellerTickets: "/sellerApi/createSellerTickets",
  // getmessageSupportList: "/sellerApi/getmessageSupportList",

  deleteacountByseller: "/sellerApi/deleteacountByseller",
  copyProduct: "/sellerApi/copyProduct",
  getInvoice: "/sellerApi/getInvoice",
  getEmailenquirySeller: "/sellerApi/getEmailenquirySeller",
  getsellerWhatsappinquire: "/sellerApi/getsellerWhatsappinquire",
  searchtags: "/sellerApi/searchtags",
  passwordChange: "/sellerApi/passwordChange",
  topViewnewSeller: "/sellerApi/topViewnewSeller",
  sellerDashboardState: "/sellerApi/sellerDashboardState",
  getExpireDate: "/sellerApi/getExpireDate",
  getsliderList2: "/publicApi/getsliderList2",
  getBlogListPub: "/publicApi/getBlogListPub",
  getBlogDetails: "/publicApi/getBlogDetails",
  gethomePop: "/publicApi/gethomePop",
  getlistfaqs: "/publicApi/getlistfaqs",
  getInvoceDetails: "/publicApi/getInvoceDetails",
};

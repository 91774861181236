import React from "react";

const SidebarFooter = () => {
  return (
    <div className="siderbar_footer">
      {/* © {new Date().getFullYear()}{" "}
      <a href="https://devhubtech.com" target="_blank" rel="noreferrer">
        devhubtech.com
      </a>
      <br />
      Designed & Developed by
      <br />
      <a href="https://abym.in" target="_blank" rel="noreferrer">
        Dev hob tech.
      </a> */}
    </div>
  );
};

export default SidebarFooter;

import { useLocation, useParams } from 'react-router-dom';
import { OPEN_KEY_DATA } from '../const/ConstData';

export const useBasePath = () => {
    const location = useLocation();
    const params = useParams();
    let data = Object.values(params).reduce(
        (path, param) => path.replace('/' + param, ''),
        location.pathname,
    );
    let select = data?.slice(1)?.replaceAll("/", "-");
    let open = OPEN_KEY_DATA.find(ele => ele.path?.includes(select));
    return { openKey: open?.openKey, selectKey: open?.selected }
};